import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Col, Row, Button } from 'react-bootstrap'
import ErrorMessage from 'components/reusable/ErrorMessage'
import useToastContext from 'hooks/useToastContext'
import pathObj from 'utils/apiPath'
import { apiPost } from 'utils/apiFetch'
import OtpInput from 'react18-input-otp'
import { useTranslation } from 'react-i18next'
import Footer from 'components/Footer'

function TransferMoneyOtp () {
  const { t } = useTranslation()
  const location = useLocation()
  const notification = useToastContext()
  const navigate = useNavigate()
  const [seconds, setSeconds] = useState(59)
  const [minutes, setMinutes] = useState(0)
  const [transferInfo] = useState(location?.state)

  let timerRef
  useEffect(() => {
    timerRef = setInterval(() => {
      if (minutes >= 0) {
        if (seconds == 0) {
          if (minutes > 0) {
            setSeconds(60)
            setMinutes(prev => prev - 1)
          } else {
            setSeconds(0)
          }
        } else {
          setSeconds(prev => prev - 1)
        }
      } else {
        clearInterval(timerRef)
        // setTimerActive(false)
      }
    }, 1000)
    return () => clearInterval(timerRef)
  })

  const [errors] = useState({})
  const [otp, setOtp] = useState(null)

  const [isEmailFocused, setIsEmailFocused] = useState(false)
  useEffect(() => {
    if (otp?.length === 4 && isEmailFocused === false) {
      const emailOtpElement = document.getElementById('emailOtpInput-0')
      emailOtpElement?.focus()
      setIsEmailFocused(true)
    }
  }, [otp])

  const validation = () => {
    if (otp) {
      return true
    } else {
      notification.error('Please, enter phone otp')
      return false
    }
  }

  const handleSubmit = async () => {
    const isValid = validation()
    if (!isValid) {
      return
    }
    if (minutes === 0 && seconds === 0) {
      notification.error('Otp has expired.')
      return
    }
    if (!otp) {
      notification.error('Please, enter phone otp')
      return
    } else {
    }

    let res
    try {
      if (transferInfo.transferType === 'mobile') {
        res = await apiPost(pathObj.sendMoneyToMobile, {
          ...transferInfo,
          otp
        })
        if (res.data.success) {
          notification.success(res.data.message)
          navigate('/transfer-money', { replace: true })
        } else {
          notification.error(res?.data?.message)
        }
      } else if (transferInfo.transferType === 'bank') {
        res = await apiPost(pathObj.sendMoneyToBank, {
          ...transferInfo,
          otp
        })
        if (res.data.success) {
          notification.success(res.data.message)
          navigate('/transfer-money', { replace: true })
        } else {
          notification.error(res?.data?.message)
          navigate('/transfer-money', { replace: true })
        }
      } else if (transferInfo.transferType === 'cashOut') {
        res = await apiPost(pathObj.cashOut, {
          ...transferInfo,
          otp
        })
        if (res.data.success) {
          notification.success(res.data.message)
          navigate('/transfer-money', { replace: true })
        } else {
          notification.error(res?.data?.message)
        }
      }
    } catch (err) {
      console.log('err:', err)
      notification.error(res?.data?.message)
    }
  }

  const handleResendOtp = async () => {
    try {
      if (minutes == 0 && seconds == 0) {
        const path = pathObj.sendOTPTransferMoney
        const res = await apiPost(path, { ...transferInfo })
        if (res.data.success === true) {
          notification.success(res.data.message)
          setSeconds(59)
          setMinutes(0)
        } else {
          notification.error(res.data.message)
        }
      } else {
        notification.error(t('WAIT_FOR_OTP'))
      }
    } catch (err) {
      console.log('err:', err)
    }
  }

  return (
    <>
      <div className='main_wrap'>
        <div className='m-0 py-sm-4 py-2'></div>
        <div className='bg-white forgot_panel p-0 overflow-hidden register main_panel'>
          <Row className='g-0'>
            <Col lg={6}>
              <form style={{ width: '405px' }}>
                <Row>
                  <Col sm={12}>
                    <h4 className='mb-3 mb-sm-5 text-center'>{t('OTP')}</h4>
                  </Col>

                  <Col sm={12} className='mb-4'>
                    <OtpInput
                      inputStyle={{
                        border: 'solid 1px #dfdfdf',
                        width: '5rem',
                        height: '2.4rem',
                        marginRight: '.5rem',
                        outline: 'none',
                        borderRadius: '8px'
                      }}
                      shouldAutoFocus
                      isInputNum={true}
                      value={otp}
                      containerStyle='text-center mt-2'
                      onChange={value => setOtp(value)}
                      numInputs={4}
                      separator={<span> </span>}
                    />
                    <div className='d-flex justify-content-between mt-4'>
                      <div>
                        {t('OTP_EXPIRING')} {minutes}:{seconds}
                      </div>
                      <div
                        style={{
                          textAlign: 'right',
                          color: 'blue',
                          cursor: 'pointer'
                        }}
                        onClick={handleResendOtp}
                      >
                        {t('RESEND_OTP')}
                      </div>
                    </div>
                    <ErrorMessage message={errors?.otp?.message} />
                  </Col>
                  <Col sm={12}>
                    <Button
                      className='btn btn_theme w-100 py-3 mb-3'
                      onClick={handleSubmit}
                    >
                      {t('VERIFY_OTP')}
                    </Button>
                  </Col>
                </Row>
              </form>
            </Col>
            <Col
              lg={6}
              className='login_right d-none text-center bg-light p-4 d-lg-flex align-items-center justify-content-center'
            >
              <img src='/images/register_img.png' alt='' />
            </Col>
          </Row>
        </div>

        <Footer />
      </div>
    </>
  )
}

export default TransferMoneyOtp
