import React from 'react'
import {
  Breadcrumb,
  Button,
  FloatingLabel,
  Form,
  Row,
  Col,
  Tabs,
  Tab
} from 'react-bootstrap'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function Dth () {
  const { t } = useTranslation()
  return (
    <>
      <Header />

      <div className='main_wrap'>
        <Breadcrumb className='m-0 py-3'>
          <Breadcrumb.Item href='#'>Home</Breadcrumb.Item>
          <Breadcrumb.Item active>DTH</Breadcrumb.Item>
        </Breadcrumb>

        <div className='bg-light-gray main_panel overflow-hidden p-0'>
          <Row>
            <Col md={4}>
              <div className='left_form_wrap'>
                <div className='page_heading mb-4'>
                  <h2 className='mb-0'>DTH</h2>
                </div>
                <Form className=''>
                  <Form.Group className='mb-3'>
                    <FloatingLabel
                      controlId='floatingSelectGrid'
                      label='Operator'
                    >
                      <Form.Select aria-label='Floating label select example'>
                        <option>DTH Operator </option>
                        <option value='1'>One</option>
                        <option value='2'>Two</option>
                        <option value='3'>Three</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      <Form.Control
                        id='floatingInputCustom1'
                        type='text'
                        placeholder='name@example.com'
                      />
                      <label htmlFor='floatingInputCustom'>
                        Registered Mobile No./ Subscriber ID
                      </label>
                    </Form.Floating>
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      <Form.Control
                        id='floatingInputCustom1'
                        type='text'
                        placeholder='name@example.com'
                      />
                      <label htmlFor='floatingInputCustom'>Amount</label>
                    </Form.Floating>
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      <Form.Control
                        id='floatingInputCustom1'
                        type='text'
                        placeholder='name@example.com'
                      />
                      <label htmlFor='floatingInputCustom'>
                        Have a promocode?
                      </label>
                    </Form.Floating>
                  </Form.Group>

                  <Button className='btn btn_theme py-3 w-100'>
                    Proceed to recharge{' '}
                  </Button>
                </Form>
              </div>
            </Col>

            <Col md={8}>
              <div className='right_inner_wrap'>
                <div className='plans_data tabs_theme'>
                  <Tabs
                    defaultActiveKey='browse_plan'
                    id='uncontrolled-tab-example'
                    className='mb-3'
                  >
                    <Tab eventKey='browse_plan' title='Browse Plan'>
                      <div className='theme_tabs_content'>
                        <div className='promo_value_list d-flex align-items-center mb-0'>
                          <figure className='mb-0'>
                            <img src='images/promologo.png' alt='' />
                          </figure>
                          <figcaption className='d-flex align-items-center'>
                            <div className='mx-2'>845784578</div>
                            <div className='mx-2'>ABC Company</div>
                            <Link to='/bookgas' className='btn btn_theme'>
                              {t('O_LEK')}:299
                            </Link>
                          </figcaption>
                        </div>
                        <div className='promo_value_list d-flex align-items-center mb-0'>
                          <figure className='mb-0'>
                            <img src='images/promologo.png' alt='' />
                          </figure>
                          <figcaption className='d-flex align-items-center'>
                            <div className='mx-2'>845784578</div>
                            <div className='mx-2'>ABC Company</div>
                            <Link to='/bookgas' className='btn btn_theme'>
                              {t('O_LEK')}:299
                            </Link>
                          </figcaption>
                        </div>

                        <div className='promo_value_list d-flex align-items-center mb-0'>
                          <figure className='mb-0'>
                            <img src='images/promologo.png' alt='' />
                          </figure>
                          <figcaption className='d-flex align-items-center'>
                            <div className='mx-2'>845784578</div>
                            <div className='mx-2'>ABC Company</div>
                            <Link to='/bookgas' className='btn btn_theme'>
                              {t('O_LEK')}:299
                            </Link>
                          </figcaption>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey='promocode' title='Promo Code'>
                      <div className='theme_tabs_content'>
                        <div className='promo_value_list d-flex align-items-center mb-0'>
                          <figcaption className='d-flex align-items-center ps-0'>
                            <div className=''>
                              <strong>DTH300 </strong>
                              <p className='mb-0'>
                                Win ₹8 to 300 cashback on Recharging your DTH
                                account for 200 or more
                              </p>
                            </div>
                            <Link to='/bookgas' className='btn btn_theme'>
                              {t('O_LEK')}:299
                            </Link>
                          </figcaption>
                        </div>
                        <div className='promo_value_list d-flex align-items-center mb-0'>
                          <figcaption className='d-flex align-items-center ps-0'>
                            <div className=''>
                              <strong>DTH300 </strong>
                              <p className='mb-0'>
                                Win ₹8 to 300 cashback on Recharging your DTH
                                account for 200 or more
                              </p>
                            </div>
                            <Link to='/bookgas' className='btn btn_theme'>
                              {t('O_LEK')}:299
                            </Link>
                          </figcaption>
                        </div>

                        <div className='promo_value_list d-flex align-items-center mb-0'>
                          <figcaption className='d-flex align-items-center ps-0'>
                            <div className=''>
                              <strong>DTH300 </strong>
                              <p className='mb-0'>
                                Win ₹8 to 300 cashback on Recharging your DTH
                                account for 200 or more
                              </p>
                            </div>
                            <Link to='/bookgas' className='btn btn_theme'>
                              {t('O_LEK')}:299
                            </Link>
                          </figcaption>
                        </div>
                        <div className='promo_value_list d-flex align-items-center mb-0'>
                          <figcaption className='d-flex align-items-center ps-0'>
                            <div className=''>
                              <strong>DTH300 </strong>
                              <p className='mb-0'>
                                Win ₹8 to 300 cashback on Recharging your DTH
                                account for 200 or more
                              </p>
                            </div>
                            <Link to='/bookgas' className='btn btn_theme'>
                              {t('O_LEK')}:299
                            </Link>
                          </figcaption>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <Footer />
      </div>
    </>
  )
}

export default Dth
