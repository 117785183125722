import React from 'react'

const Loader = () => {
  return (
    <div
      id='loader'
      className='d-flex justify-content-center align-items-center h-100 position-fixed w-100 z-45 d-none text-black'
      style={{ zIndex: '100px' }}
    >
      <div class='spinner-border text-primary' role='status'>
        <span class='visually-hidden'>Loading...</span>
      </div>
    </div>
  )
}

export default Loader
