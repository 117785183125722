import React, { useEffect, useState } from 'react'
import { Accordion, Form, Breadcrumb, Button } from 'react-bootstrap'
import Footer from '../components/Footer'
import pathObj from 'utils/apiPath'
import { apiGet, apiPost } from 'utils/apiFetch'
import helpers from 'utils/helpers'
import useToastContext from 'hooks/useToastContext'
import { useForm } from 'react-hook-form'
import { preventMax } from 'utils/reusableMethods'
import Cards from './add-money/Cards'
import ErrorMessage from 'components/reusable/ErrorMessage'
import { useTranslation } from 'react-i18next'
import { integerInput } from 'utils/InputControl'

function AddMoney () {
  const { t } = useTranslation()
  const [balance, setBalance] = useState(0)
  const notification = useToastContext()
  const [isValid, setIsValid] = useState(false)
  const [promoCodes, setPromoCodes] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [couponError, setCouponError] = useState('')

  const {
    register,
    setValue,
    reset,
    formState: { errors },
    handleSubmit
  } = useForm()
  const onSubmit = data => {
    addMoney(data)
  }

  const handleSubmitPromo = async () => {
    const checkValid = await promoCode(promoCodes)
    if (checkValid) {
      setCouponError('Applied')
      return
    } else {
      setCouponError('')
    }
  }

  const changePromoCode = e => {
    setKeyword(e.target.value)
    setPromoCodes(e.target.value)
    if (e.target.value === '') {
      setIsValid(false)
    }
  }

  const addMoney = async data => {
    setPromoCodes(false)
    try {
      const path = pathObj.addMoney

      const payload = {
        ...data,
        promoCode: promoCodes !== false ? promoCodes : null
      }
      const result = await apiPost(path, payload)

      if (result?.data?.success === true) {
        getBalance()

        notification.success(result?.data?.message)
        reset()
      } else {
        notification.error(result?.data?.message)
      }
    } catch (error) {
      console.log('addMoney', error)
    }
  }

  const promoCode = async data => {
    try {
      const path = pathObj.promoCode

      const payload = {
        promoCode: data
      }
      const result = await apiPost(path, payload)

      if (result?.data?.success === true) {
        getBalance()
        reset()
        return true
      } else {
        notification.error(result?.data?.message)
        return false
      }
    } catch (error) {
      console.log('promoCode', error)
    }
  }

  const getBalance = async () => {
    try {
      const path = pathObj.getBalance
      const result = await apiGet(path)
      setBalance(result.data.results?.wallet_amount || 0)
    } catch (error) {
      console.log('getBalance==>', error)
    }
  }
  useEffect(() => {
    getBalance()
  }, [])

  const preventSpace = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
    if (['-', '+', 'e'].includes(e.key)) {
      e.preventDefault()
    }
  }

  return (
    <>
      <div className='main_wrap'>
        <Breadcrumb className='m-0 py-3'>
          <Breadcrumb.Item href='/dashboard'>{t('O_HOME')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t('O_ADD_MONEY')}</Breadcrumb.Item>
        </Breadcrumb>

        <div className='bg-white main_panel overflow-hidden home_main py-4 px-0 wallet'>
          <div className='page_heading pb-4 px-4'>
            <h2 className='mb-0'>{t('ADD_MONEY_WALLET')}</h2>
          </div>
          <div className='bg-light py-3 px-4 d-lg-flex'>
            <div className='avail pb-4'>
              <h5 className='text-white mb-3'>
                {t('O_AVL_BLN')}
                <span className='d-block'>
                  {t('O_LEK')} {helpers.currencyFormat(balance)}
                </span>
              </h5>
              <Button
                type='btn'
                className='id_btn text-white bg-dark fw-bold px-4'
              >
                ID - XYV458722221
              </Button>
            </div>

            <Form
              className='wallet_amount  mt-3 mt-lg-0 ms-lg-4 ms-lg-5'
              onSubmit={handleSubmit(onSubmit)}
            >
              <Form.Group>
                <h6 htmlFor='floatingInputCustom' className='mb-2 fw-normal'>
                  {t('O_AMOUNT')}
                </h6>
                <div
                  className='d-sm-flex align-items-center flex-wrap mb-2'
                  style={{ marginRight: '10px' }}
                >
                  <Form.Floating>
                    <Form.Control
                      type='number'
                      placeholder='name@example.com'
                      name='amount'
                      onKeyPress={e => integerInput(e)}
                      onInput={e => preventMax(e, 5)}
                      {...register('amount', {
                        required: t('AMOUNT_EMPTY'),
                        min: {
                          value: 5,
                          message: t('MINIMUM_5_O')
                        }
                      })}
                      aria-invalid={errors.amount ? 'true' : 'false'}
                    />
                    <label htmlFor='amount'>
                      {t('O_AMOUNT')}
                      <span className='text-danger'>*</span>
                    </label>
                    <ErrorMessage message={errors?.amount?.message} />
                  </Form.Floating>
                </div>
              </Form.Group>
              <div className='d-flex align-items-center flex-wrap wallet_amount mb-4 mt-3'>
                <span className='custom_radio'>
                  {' '}
                  <input
                    type='radio'
                    name='amount'
                    value='50'
                    onClick={() => setValue('amount', 50)}
                  />
                  <span className='radio_indicator'>&nbsp;</span>{' '}
                  <span className='radio_text'>{t('O_LEK')} 50</span>
                </span>
                <span className='custom_radio'>
                  {' '}
                  <input
                    type='radio'
                    name='amount'
                    value='100'
                    onClick={() => setValue('amount', 100)}
                  />
                  <span className='radio_indicator'>&nbsp;</span>{' '}
                  <span className='radio_text'>{t('O_LEK')} 100</span>
                </span>
                <span className='custom_radio'>
                  {' '}
                  <input
                    type='radio'
                    name='amount'
                    value='150'
                    onClick={() => setValue('amount', 150)}
                  />
                  <span className='radio_indicator'>&nbsp;</span>{' '}
                  <span className='radio_text'>{t('O_LEK')} 150</span>
                </span>
                <span className='custom_radio'>
                  {' '}
                  <input
                    type='radio'
                    name='amount'
                    value='200'
                    onClick={() => setValue('amount', 200)}
                  />
                  <span className='radio_indicator'>&nbsp;</span>{' '}
                  <span className='radio_text'>{t('O_LEK')} 200</span>
                </span>
              </div>
              <Button
                type='submit'
                className=' px-5 p-3 fs-6 copy_btn link'
                value='Gift Now'
                disabled={isValid === 'invalid'}
              >
                {t('O_PAY_NOW')}
              </Button>
            </Form>

            <Form className='wallet_amount  mt-3 mt-lg-0 ms-lg-4 ms-lg-5 valid-msg'>
              <Form.Group>
                <h6 htmlFor='floatingInputCustom' className='mb-2 fw-normal'>
                  {t('ADD_MONEY_COUPON_CODE')}
                </h6>
                <div className='d-sm-flex align-items-center  mb-2'>
                  <Form.Floating>
                    <Form.Control
                      type='text'
                      placeholder='name@example.com'
                      name='promoCode'
                      maxLength={15}
                      onInput={preventSpace}
                      onChange={changePromoCode}
                      aria-invalid={errors.promoCode ? 'true' : 'false'}
                    />
                    <label htmlFor='promoCode'>
                      {t('ADD_MONEY_COUPON_CODE')}
                    </label>
                    <ErrorMessage message={errors?.promoCode?.message} />
                  </Form.Floating>
                  <Button
                    disabled={keyword ? false : true}
                    className='px-2 p-3 ms-3 fs-6 copy_btn link'
                    onClick={handleSubmitPromo}
                  >
                    {t('ADD_MONEY_APPLY_COUPON')}
                  </Button>
                </div>
                <ErrorMessage message={couponError} />
              </Form.Group>
            </Form>
          </div>
          <div className='py-3 px-4 add_card'>
            <div className='page_heading mb-3'>
              <h2 className='mb-0'>{t('ADD_MONEY_SELECT_PAYMENT')} </h2>
            </div>
            <Accordion defaultActiveKey='0' className='border-0'>
              <Accordion.Item
                eventKey='0'
                className='border-start-0 border-end-0'
              >
                <Accordion.Header className='border-0 bg-transparent'>
                  {t('ADD_MONEY_DEBIT_CARD')}
                </Accordion.Header>
                <Accordion.Body className='px-0'>
                  <Cards />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item
                eventKey='1'
                className='border-start-0 border-end-0'
              >
                <Accordion.Header className='border-0 bg-transparent'>
                  {t('ADD_MONEY_NET_BANKING')}
                </Accordion.Header>
                <Accordion.Body className='px-0'>
                  <div className='card_list d-flex flex-wrap align-items-center mb-4'>
                    <span class='custom_radio'>
                      &nbsp; <input type='radio' name='radio' />
                      <span class='radio_indicator'>&nbsp;</span>{' '}
                      <span className='radio_text'>
                        <img
                          src='./images/mastercard.png'
                          className='me-3'
                          alt=''
                        />{' '}
                        {t('ADD_MONEY_AXIS_BANK')}
                      </span>
                    </span>

                    <span class='custom_radio ms-sm-3 ms-0 mt-sm-0 mt-3'>
                      &nbsp; <input type='radio' name='radio' />
                      <span class='radio_indicator'>&nbsp;</span>{' '}
                      <span className='radio_text'>
                        <img src='./images/visa.png' className='me-3' alt='' />{' '}
                        {t('ADD_MONEY_ICICI_BANK')}
                      </span>
                    </span>
                  </div>
                  <Button className='btn btn_theme'>
                    {t('ADD_MONEY_DONE')}{' '}
                  </Button>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>

        <Footer />
      </div>
    </>
  )
}

export default AddMoney
