import React, { useState, useContext, useEffect } from 'react'
import { Modal, Col, Row, Form, Button } from 'react-bootstrap'
import Footer from '../components/Footer'
import pathObj from '../utils/apiPath'
import { apiPut, apiPost } from '../utils/apiFetch'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useForm } from 'react-hook-form'
import { validationRules } from 'utils/constants'
import ErrorMessage from 'components/reusable/ErrorMessage'
import AuthContext from 'context/AuthContext'
import { useTranslation } from 'react-i18next'
import { apiGet } from 'utils/apiFetch'
import { isEmpty } from 'lodash'
import { Link } from 'react-router-dom'

function Settings () {
  const { t } = useTranslation()
  const [switchStatus, setSwitchStatus] = useState({ status: true })
  const [show, setShow] = useState(false)
  const [icon2, setIcon2] = useState(true)
  const [icon1, setIcon1] = useState(true)
  const [icon, setIcon] = useState(true)
  const [codeResult, setCodeResult] = useState(false)
  const [resultMessage, setResultMessage] = useState('Data sending')

  let { logoutUser } = useContext(AuthContext)

  const handleClose = () => {
    setResultMessage('Data sending')
    setShow(false)
    if (codeResult) {
      logoutUser()
    }
  }

  const {
    register,
    reset,
    watch,
    trigger,
    formState: { errors },
    handleSubmit
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })

  const onSubmit = data => resetPassword(data)
  const url = codeResult ? './images/check.png' : './images/wrong.png'

  const resetPassword = async data => {
    try {
      setShow(true)
      const path = pathObj.changePassword
      const payload = {
        ...data
      }
      const result = await apiPost(path, payload)
      setCodeResult(result.data.success)
      setResultMessage(result.data.message)
      reset()
    } catch (error) {
      console.log('error', error)
    }
  }

  const switchChange = async event => {
    setSwitchStatus({ [event.target.name]: event.target.checked })
    try {
      const path = pathObj.notificationToggle
      const payload = {
        [event.target.name]: event.target.checked
      }
      const result = await apiPut(path, payload)
      console.log('result', result)
    } catch (error) {
      console.log('error', error)
    }
  }

  const notificationApi = async () => {
    const res = await apiGet(pathObj.getProfile)
    setSwitchStatus({ status: res?.data?.results?.notifications })
  }

  useEffect(() => {
    notificationApi()
  }, [])

  const preventSpace = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }

  const newPassword = watch('newPassword')
  useEffect(() => {
    if (!isEmpty(newPassword)) {
      trigger('confPassword')
    }
  }, [newPassword, trigger])

  return (
    <>
      <div className='main_wrap settings px-0'>
        <div className='m-0 py-4 sidebar_pageHeading fullPage_space'>
          <h5 className='mb-0 text-white'>
            <img src='./images/sidebar_icon10.svg' alt='' className='me-2' />{' '}
            {t('NAV_SETTINGS')}{' '}
          </h5>
        </div>
        <div className='bg-light main_panel rounded-0 fullPage_space py-4 vh-100'>
          <div className='main_panel rounded-0 overflow-hidden bg-white pb-3 change-password-panel'>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <h5 className='d-flex align-items-center mb-4 pb-3 flex-reverse'>
                {t('SETTINGS_NOTIFICATION')}
                <Form.Check
                  name='status'
                  type='switch'
                  id='custom-switch'
                  className='ms-3'
                  onChange={switchChange}
                  checked={switchStatus?.status}
                />
              </h5>
              <h5 className='mb-3'>{t('SETTINGS_CHANGE_PASSWORD')}</h5>

              <Row>
                <Col lg={4} md={8}>
                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3 position-relative'>
                      <Form.Control
                        id='oldPassword'
                        type={icon2 ? 'password' : 'text'}
                        name='oldPassword'
                        maxLength={40}
                        onInput={preventSpace}
                        placeholder='name@example.com'
                        {...register('oldPassword', {
                          required: t('OLD_PASSWORD_EMPTY')
                        })}
                        aria-invalid={errors.oldPassword ? 'true' : 'false'}
                      />
                      {icon2 ? (
                        <span
                          className='password_view cursor-pointer absolute'
                          onClick={() => setIcon2(!icon2)}
                        >
                          <AiFillEyeInvisible />
                        </span>
                      ) : (
                        <span
                          className='password_view cursor-pointer absolute'
                          onClick={() => setIcon2(!icon2)}
                        >
                          <AiFillEye />
                        </span>
                      )}

                      <label htmlFor='floatingpwd1'>
                        {t('SETTINGS_CURRENT_PASSWORD')}
                        <span className='text-danger'>*</span>
                      </label>
                    </Form.Floating>
                    <ErrorMessage message={errors?.oldPassword?.message} />
                  </Form.Group>
                </Col>
                <Col lg={4} md={8}>
                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3 position-relative'>
                      <Form.Control
                        id='newPassword'
                        type={icon ? 'password' : 'text'}
                        name='newPassword'
                        maxLength={40}
                        onInput={preventSpace}
                        placeholder='name@example.com'
                        {...register('newPassword', {
                          required: t('NEW_PASSWORD_EMPTY'),
                          pattern: {
                            value: validationRules.password,
                            message: validationRules.newPasswordMessage
                          }
                        })}
                        aria-invalid={errors.newPassword ? 'true' : 'false'}
                      />
                      {icon ? (
                        <span
                          className='password_view cursor-pointer absolute'
                          onClick={() => setIcon(!icon)}
                        >
                          <AiFillEyeInvisible />
                        </span>
                      ) : (
                        <span
                          className='password_view cursor-pointer absolute'
                          onClick={() => setIcon(!icon)}
                        >
                          <AiFillEye />
                        </span>
                      )}

                      <label htmlFor='floatingpwd2'>
                        {t('O_NEW_PASSWORD')}
                        <span className='text-danger'>*</span>
                      </label>
                    </Form.Floating>
                    <ErrorMessage message={errors?.newPassword?.message} />
                  </Form.Group>
                </Col>
                <Col lg={4} md={8}>
                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3 position-relative'>
                      <Form.Control
                        id='confPassword'
                        type={icon1 ? 'password' : 'text'}
                        name='confPassword'
                        maxLength={40}
                        onInput={preventSpace}
                        placeholder='name@example.com'
                        {...register('confPassword', {
                          required: t('CONFIRM_PASSWORD_EMPTY'),
                          pattern: {
                            value: validationRules.password,
                            message: validationRules.confirmPasswordMessage
                          },
                          validate: val => {
                            if (watch('newPassword') !== val) {
                              return 'New password and confirm password does not match.'
                            }
                          }
                        })}
                        aria-invalid={errors.confPassword ? 'true' : 'false'}
                      />
                      {icon1 ? (
                        <span
                          className='password_view cursor-pointer absolute'
                          onClick={() => setIcon1(!icon1)}
                        >
                          <AiFillEyeInvisible />
                        </span>
                      ) : (
                        <span
                          className='password_view cursor-pointer absolute'
                          onClick={() => setIcon1(!icon1)}
                        >
                          <AiFillEye />
                        </span>
                      )}

                      <label htmlFor='floatingpwd3'>
                        {t('SETTINGS_CONFIRM_NEW_PASSWORD')}
                        <span className='text-danger'>*</span>
                      </label>
                    </Form.Floating>
                    <ErrorMessage message={errors?.confPassword?.message} />
                  </Form.Group>
                </Col>

                <Col sm={12}>
                  <Button
                    className={
                      show ? 'btn btn_theme disabled' : 'btn btn_theme '
                    }
                    type='submit'
                  >
                    {t('SETTINGS_SAVE_CHANGES')}
                  </Button>
                </Col>
              </Row>
            </Form>

            <Col sm={12} className='mt-5'>
              <Link to='/deactivateAccount'>
                <Button className="btn btn-danger bg-danger">
                  {t('DEACTIVATE_ACCOUNT')}
                </Button>
              </Link>
              
            </Col>
          </div>
        </div>
        <div className='fullPage_space'>
          <Footer />
        </div>
      </div>
      {resultMessage === 'Data sending' ? null : (
        <Modal
          show={show}
          onHide={handleClose}
          centered
          className='verify_modal track_modal'
        >
          <Modal.Header closeButton>
            <Modal.Title> </Modal.Title>
          </Modal.Header>
          <Modal.Body className='text-center p-5'>
            <img src={url} alt='' className='mb-4 opacity-1' />
            <p className='text-center'>{resultMessage || null}</p>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default Settings
