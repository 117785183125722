import React, { useState, useEffect } from 'react'
import { Col, Row, Button, Form, Modal } from 'react-bootstrap'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import pathObj from '../utils/apiPath'
import { apiGet, apiPost } from '../utils/apiFetch'
import { useTranslation } from 'react-i18next'

function AddGiftCard () {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const [inputs, setInputs] = useState({})
  const [balance, setBalance] = useState(0)
  const [codeResult, setCodeResult] = useState()
  const [errorMessage, setErrorMessage] = useState({})

  const url = codeResult?.success ? './images/check.png' : './images/wrong.png'

  const handleClose = () => setShow(false)
  const handleShow = async e => {
    if (Object.keys(inputs).length > 0) {
      try {
        const path = pathObj.redeemGiftCard
        const payload = {
          code: inputs.code
        }
        const result = await apiPost(path, payload)
        setCodeResult(result.data)
        setShow(true)
        setInputs({})
      } catch (error) {
        console.log('error', error)
      }
    } else {
      setErrorMessage({ code: t('PLEASE_ENTER_GIFT_CARD_CODE') })
    }
  }

  const amount = async () => {
    const path = pathObj.getBalance
    const result = await apiGet(path)
    setBalance(result?.data?.results?.wallet_amount || 0)
  }
  useEffect(() => {
    amount()
  }, [])

  const inputChange = event => {
    const name = event.target.name
    const value = event.target.value
    setInputs(values => ({ ...values, [name]: value }))
    setErrorMessage({})
  }
  const preventSpace = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }

  return (
    <>
      {/* <Header /> */}

      <div className='main_wrap px-0'>
        <div className='m-0 py-4 sidebar_pageHeading fullPage_space'>
          <h5 className='mb-0 text-white'>
            <img src='./images/sidebar_icon3.svg' alt='' className='me-2' />
            {t('NAV_GIFT_CARD')}
          </h5>
        </div>
        <div className='bg-light main_panel rounded-0 p-3 p-md-4 sendgift'>
          <div className='bg-white p-3 p-md-4 tabs_theme'>
            <div className='card_balance d-flex flex-wrap align-items-center px-3'>
              <h5 className='text-white mt-2'>
                {t('GIFT_CREDIT')}:
                <span className=''>
                  {' '}
                  {t('O_LEK')} {balance}
                </span>
              </h5>
              <Button
                type='btn'
                className='id_btn text-white bg-dark fw-bold px-3 ms-md-auto ms-0 my-md-0 my-2 border-0'
              >
                ID - XYV458722221
              </Button>
            </div>
            <div
              id='uncontrolled-tab-example'
              className='mb-3 border-top mt-2 pt-4'
            >
              <div>
                <div className='d-sm-flex  align-items-center'>
                  <h4 className='mb-sm-0 mb-3'>{t('GIFT_CARD_ANY')}</h4>

                  <Link
                    to='/AddGiftCard'
                    className='btn btn_theme ms-auto'
                    title={t('GIFT_CARD_ANY')}
                  >
                    {t('GIFT_CARD_ANY')}
                  </Link>
                  <Link
                    to='/sendGiftCard'
                    className='btn btn_theme ms-sm-3 ms-1'
                    title={t('SEND_GIFT_CARD')}
                  >
                    {t('SEND_GIFT_CARD')}
                  </Link>
                </div>
                <Row>
                  <Col lg={6} md={8}>
                    <Form.Group className='my-3 d-sm-flex align-items-center '>
                      <Form.Floating className='w-lg-50 w-100'>
                        <Form.Control
                          id='floatingInputCustom'
                          type='text'
                          placeholder='name@example.com'
                          name='code'
                          maxLength={20}
                          onInput={preventSpace}
                          value={inputs.code || ''}
                          onChange={inputChange}
                        />
                        <label htmlFor='floatingInputCustom1'>
                          {t('ENTER_GIFT_CARD')}
                          <span className='text-danger'>*</span>
                        </label>
                        {errorMessage?.code?.length > 1 ? (
                          <span className='text-danger'>
                            {errorMessage?.code}
                          </span>
                        ) : null}
                      </Form.Floating>
                      <Link
                        to=''
                        className='w-lg-50 w-100 btn btn_theme d-flex justify-content-center align-items-center py-3 ms-sm-3 ms-0 mt-sm-0 mt-3'
                        onClick={() => handleShow()}
                        title={t('ADD_GIFT_BALANCE')}
                      >
                        {t('ADD_GIFT_BALANCE')}
                      </Link>{' '}
                    </Form.Group>
                  </Col>
                </Row>
                <Form />
              </div>
            </div>
          </div>
        </div>
        <div className='fullPage_space'>
          <Footer />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className='verify_modal track_modal'
      >
        <Modal.Header closeButton title={t('O_CLOSE')}>
          <Modal.Title> </Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center p-5'>
          <img src={url} alt='' className='mb-4 opacity-1' />
          <p className='text-center'>
            {codeResult?.success
              ? 'Gift card has been successfully added to your balance.'
              : codeResult?.message}
          </p>

          <Link
            to='/dashboard'
            className='btn btn_theme mb-2'
            onClick={() => handleShow()}
          >
            {t('GO_TO_DASHBOARD')}
          </Link>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddGiftCard
