import React, { useContext } from 'react'
import { Button, Form, Row, Col, Card } from 'react-bootstrap'
import Footer from '../components/Footer'
import { useForm } from 'react-hook-form'
import AuthContext from 'context/AuthContext'
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router";

function DeleteAccount () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { handleSubmit } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })
  let { deleteAccount } = useContext(AuthContext)
  const handleDeleteAccountRequest = () => {
    deleteAccount()
  }

  return (
    <>
      <div className='main_wrap px-0'>
        <div className='m-0 py-4 sidebar_pageHeading fullPage_space bg-danger'>
          <h5 className='mb-0 text-white'>
            <img src='./images/profile_page.png' alt='' className='me-2' />
            {t('DEACTIVATE_ACCOUNT')}
          </h5>
        </div>
        <div className='bg-light main_panel rounded-0 p-3 p-md-4 profile'>
          <div className='bg-white p-3 p-md-4'>
            <Form onSubmit={handleSubmit(handleDeleteAccountRequest)} style={{maxWidth: "50rem", margin: "0 auto"}}>
              <Row>
                <Card className='mb-5'>
                  <Row className='p-2' style={{borderBottom: "1px solid var(--bs-card-border-color)"}}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                          <h6 className='m-0'>{t('DEACTIVATE_ACCOUNT')}</h6>
                          <span className='text-secondary'><b>
                            {t('DEACTIVATE_ACCOUNT_NOTE')}  
                          </b></span>
                          <span className='text-secondary'>
                            {t('DEACTIVATE_ACCOUNT_NOTE2')}
                          </span>
                        </div>
                        <div className=''>
                          <input class="form-check-input col-2" type="radio" name="deleteRadio" checked id="deactivateRadio"></input>
                        </div>
                    </div>
                  </Row>
                </Card>
              </Row>

              <Row>
                <Col sm={6}>
                  <Button
                    className='py-2 btn btn-secondary mb-3 w-100'
                    type='button'
                    onClick={() => navigate("/settings")}
                  >
                    {t('CANCEL')}
                  </Button>
                </Col>
                <Col sm={6}>
                  <Button
                    className='py-2 btn btn-danger bg-danger float-end mb-3 w-100'
                    type='submit'
                  >
                    {t('MAKE_REQUEST')}
                  </Button>
                </Col>

              </Row>
            </Form>
          </div>
        </div>
        <div className='fullPage_space'>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default DeleteAccount
