import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import Footer from '../components/Footer'
import { apiGet } from 'utils/apiFetch'
import pathObj from 'utils/apiPath'
import helpers from 'utils/helpers'
import { RiDashboardLine } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import AuthContext from 'context/AuthContext'

function Dashboard () {
  const { t } = useTranslation()
  const { language, logoutUser, setLastActivity, lastActivity } =
    useContext(AuthContext)

  const [balance, setBalance] = useState(0)
  const getBalance = async () => {
    try {
      const path = pathObj.getBalance
      const result = await apiGet(path)
      setBalance(result.data.results?.wallet_amount || 0)
    } catch (error) {
      console.log('getBalance==>', error)
    }
  }
  useEffect(() => {
    getBalance()
  }, [])

  useEffect(() => {
    const activityListener = () => {
      setLastActivity(new Date())
    }
    window.addEventListener('mousemove', activityListener)

    return () => {
      window.removeEventListener('mousemove', activityListener)
    }
  }, [])

  useEffect(() => {
    const inactivityTimeout = setTimeout(() => {
      const currentTime = new Date()
      const inactivityDuration = currentTime - lastActivity
      if (inactivityDuration >= 360000) {
        logoutUser()
      }
    }, 60000)
    return () => {
      clearTimeout(inactivityTimeout)
    }
  }, [lastActivity])

  return (
    <>
      <div className='main_wrap'>
        <div className='m-0 py-4 sidebar_pageHeading'>
          <h5 className='mb-0 text-white'>
            <RiDashboardLine style={{ marginRight: '5px' }} />
            {t('NAV_DASHBOARD')}
          </h5>
        </div>
        <div className='bg-white main_panel p-3 overflow-hidden home_main'>
          <Row className='h-100'>
            <Col lg={8} md={12} xl={9} className='py-lg-4 py-2'>
              <h5 className='mb-3 mb-sm-4 link'>
                {t('DASHBOARD_MONEY_TRANSFER')}
              </h5>
              <ul className='flex-wrap d-flex p-0 mx-0 mb-xl-4 mb-lg-3 mb-sm-3 mb-0 list-unstyled'>
                <li>
                  <Link to='/add-money' className='text-center'>
                    <figure>
                      {' '}
                      <img src='./images/add_money.png' alt='' />
                    </figure>
                    <span className='d-block mt-2 text-dark'>
                      {t('O_ADD_MONEY')}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to='/transfer-money'
                    className='text-center'
                    state={{ activeKey: '0' }}
                  >
                    <figure>
                      <img src='./images/transfer_money.png' alt='' />
                    </figure>
                    <span className='d-block mt-2 text-dark'>
                      {t('DASHBOARD_TRANSFER_MONEY')}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to='/transfer-money'
                    className='text-center'
                    state={{ activeKey: '2' }}
                  >
                    <figure>
                      {' '}
                      <img src='./images/withdraw_money.png' alt='' />
                    </figure>
                    <span className='d-block mt-2 text-dark'>
                      {t('DASHBOARD_WITHDRAW_MONEY')}
                    </span>
                  </Link>
                </li>
              </ul>

              <div className='d-flex align-items-center flex-wrap'>
                <div className='mb-4 me-lg-0 me-md-4 me-0 p-2 m-2'>
                  <h5 className='text-dark mb-3'>{t('DASHBOARD_GET_LOAN')}</h5>
                  <Link to='/cardDetails'>
                    <img
                      style={{ width: '412px', height: '188px', borderRadius: "1rem" }}
                      src={
                        language == 1
                          ? './images/loan1.png'
                          : './images/web_banner1Ar.png'
                      }
                      alt=''
                    />
                  </Link>
                </div>
                <div className='mb-4 me-lg-0 me-md-4 me-0 p-2 m-2'>
                  <h5 className='text-dark mb-3'>{t('DASHBOARD_GET_DEALS')}</h5>
                  <Link to='/nearby-merchants'>
                    <img
                      style={{ width: '412px', height: '188px', borderRadius: "1rem" }}
                      src={
                        language == 1
                          ? './images/deal-banner.png'
                          : './images/deal-banner-ar.png'
                      }
                      alt=''
                    />
                  </Link>
                </div>
              </div>
            </Col>
            <Col
              xl={3}
              lg={4}
              md={12}
              className='login_right bg-light p-md-4 p-1 text-md-start text-center h-100'
            >
              <div className='avail mb-4'>
                <h5 className='text-white mb-3'>
                  {t('O_AVL_BLN')}
                  <span className='d-block'>
                    {t('O_LEK')} {helpers.currencyFormat(balance)}
                  </span>
                </h5>
              </div>
            </Col>
          </Row>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Dashboard
