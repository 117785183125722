const pathObj = {
  loginUser: '/v1/user/login',
  addMoney: '/v1/user/wallet/add-money',
  getBalance: '/v1/user/wallet/balance',
  changePassword: 'v1/user/change-password',
  notificationToggle: 'v1/user/notification-toggle',
  sendMoneyToMobile: '/v1/user/wallet/transfer/mobile',
  thirdPartyInvoicePayment: '/v1/user/wallet/third-party-invoice-payment',
  thirdPartySendOTPForInvoicePayment:
    '/v1/user/wallet/third-party-invoice-payment-send-otp',
  sendMoneyToBank: '/v1/user/wallet/transfer/bank-account',
  getProfile: '/v1/user/my-profile',
  updateProfile: '/v1/user/update-profile',
  deleteAccountRequest: '/v1/user/request-delete',
  myLoyaltyPoints: 'v1/user/my-loyalty-points',
  giftCard: 'v1/user/gift-card',
  redeemGiftCard: 'v1/user/redeem-gift-card',
  staticContent: 'v1/user/static-content/list',
  staticContentSlug: 'v1/user/static-content?slug',
  contactUs: 'v1/user/contact-us',
  signupUser: '/v1/user/signup',
  securityPin: '/v1/user/set-security-pin',
  promoCodes: '/v1/user/promo-codes',
  sentGiftCards: '/v1/user/sent-gift-cards',
  walletStatement: '/v1/user/wallet/statement',
  walletAddMoney: '/v1/user/wallet/add-money',
  forgotPassword: '/v1/user/forgot-password',
  forgotPasswordOtp: '/v1/user/reset-password',
  getFaqs: '/v1/user/static-content/faqs',
  verifyOtp: '/v1/user/verify-email-mobile',
  resendOtp: '/v1/user/resend-otp',
  customerQrCode: '/v1/user/customer-qr-code',
  transaction: '/v1/user/transaction',
  pendingInvoices: '/v1/user/wallet/pending-ealbania-transactions',
  nearbyMerchants: '/v1/user/nearby-merchants',
  getCategorySubCategoryList: '/v1/merchant/list',
  getNotifications: '/v1/user/notifications',
  postDeals: '/v1/user/offers/deals-purchase',
  loyaltyConvert: '/v1/user/loyalty-convert',
  promoCode: '/v1/user/wallet/check-promo-code',
  cashOutPreview: '/v1/merchant/wallet/cash-out-preview',
  cashOut: '/v1/user/wallet/transfer/cash-out',
  getInvoice: '/v1/user/wallet/invoice',
  verifyBankAccount: '/v1/user/loans/verify-bank-account',
  checkMaxLoanAmount: '/v1/user/loans/check-max-loan-amount',
  previewLoan: '/v1/user/loans/preview',
  submitLoanRequirement: '/v1/user/loans/submit-loan-requirement',
  loanStatus: '/v1/user/loans',
  bankList: '/v1/user/wallet/bank-accounts',
  bankCommission: '/v1/user/wallet/transfer-commissions',
  transferToBank: '/v1/user/wallet/transfer-to-bank',
  loanPreview: '/v1/user/loans/preview-installment',
  updateSecondaryEmail: '/v1/user/update-secondary-email',
  verifySecondaryEmail: '/v1/user/verify-otp-secondary-email',
  deleteSecondaryEmail: '/v1/user/delete-secondary-email',
  changePrimaryEmail: '/v1/user/change-primary-email',
  updateSecondaryMobile: '/v1/user/update-secondary-mobile',
  verifySecondaryMobile: '/v1/user/verify-otp-secondary-mobile',
  deleteSecondaryMobile: '/v1/user/delete-secondary-mobile',
  changePrimaryMobile: '/v1/user/change-primary-number',
  sendOTPTransferMoney: '/v1/user/wallet/transfer/otp',
  ssoLogin: '/v1/user/sso-verify-token',
  loanPay: '/v1/user/loans/pay-installment',
  verifyLoginOtp: '/v1/user/verify-otp'
}

export default pathObj
