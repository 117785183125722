import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb, Button, Form, Row, Col, Modal } from 'react-bootstrap'
import Footer from '../components/Footer'
import pathObj from 'utils/apiPath'
import { apiPost } from 'utils/apiFetch'
import { useForm } from 'react-hook-form'
import ErrorMessage from 'components/reusable/ErrorMessage'
import { integerInput } from 'utils/InputControl'
import useToastContext from 'hooks/useToastContext'
import { preventMax } from 'utils/reusableMethods'
import { useTranslation } from 'react-i18next'

function CardDetails () {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const [verificationId, setVerificationId] = useState(null)
  const notification = useToastContext()
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const onSubmit = async data => {
    try {
      let bankData = {
        accountHolderName: data.name,
        bankAccountNumber: data.accountNumber,
        ifscCode: data.ifsc,
        branchName: data.branchName,
        email: data.email
      }
      const result = await apiPost(pathObj.verifyBankAccount, bankData)
      if (result?.data?.success === true) {
        console.log(result?.data)
        setVerificationId(result?.data?.results?._id)
        handleShow()
        reset()
      } else {
        notification.error(result?.data?.message)
      }
    } catch (error) {}
  }

  const preventSpace = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }

  const preventMaxValue = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }
  const codeValue = watch('email') ? watch('email') : ''

  return (
    <>
      {/* <Header /> */}

      <div className='main_wrap'>
        <Breadcrumb className='m-0 py-3'>
          <Breadcrumb.Item href='#'>{t('LOGIN_HOME')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t('MICRO_CREDIT_REQUEST')}</Breadcrumb.Item>
        </Breadcrumb>

        <div className='bg-white main_panel'>
          <div className='page_heading mb-4'>
            <h2 className='mb-0'>{t('MICRO_CREDIT_REQUEST_CARD_DETAILS')}</h2>
          </div>
          <Form
            className='personal_info_form'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col sm={6}>
                <Form.Group className='mb-3'>
                  <Form.Floating className='mb-3'>
                    <Form.Control
                      type='text'
                      placeholder='Account holder name'
                      maxLength={15}
                      onInput={preventSpace}
                      {...register('name', {
                        required: t('PLEASE_ENTER_ACCOUNT_HOLDER_NAME'),
                        minLength: {
                          value: 2,
                          message: t('MINIMUM_LENGTH_MUST_BE_2')
                        }
                      })}
                    />
                    <label htmlFor='floatingInputCustom1'>
                      {t('ACCOUNT_HOLDER_NAME')}
                      <span className='text-danger'>*</span>
                    </label>
                    <ErrorMessage message={errors?.name?.message} />
                  </Form.Floating>
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group className='mb-3'>
                  <Form.Floating className='mb-3'>
                    <Form.Control
                      id='floatingInputCustom1'
                      type='number'
                      inputMode='numeric'
                      onKeyPress={e => integerInput(e)}
                      onInput={e => preventMax(e, 16)}
                      placeholder='Bank account number'
                      {...register('accountNumber', {
                        required: t('PLEASE_ENTER_BANK_ACCOUNT_NUMBER'),
                        min: {
                          value: 0,
                          message: t('MINIMUM_LENGTH_MUST_BE_0')
                        },
                        minLength: {
                          value: 16,
                          message: t(
                            'BANK_ACCOUNT_NUMBER_SHOULD_NOT_EXCEED_16_DIGITS'
                          )
                        }
                      })}
                    />
                    <label htmlFor='floatingInputCustom1'>
                      {t('BANK_ACCOUNT_NUMBER')}
                      <span className='text-danger'>*</span>
                    </label>
                    <ErrorMessage message={errors?.accountNumber?.message} />
                  </Form.Floating>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className='mb-3'>
                  <Form.Floating className='mb-3'>
                    <Form.Control
                      id='floatingInputCustom1'
                      type='text'
                      placeholder='IFSC code*'
                      onInput={preventSpace}
                      maxLength={28}
                      {...register('ifsc', {
                        required: t('REQUIRED_IBAN')
                      })}
                    />
                    <label htmlFor='floatingInputCustom1'>
                      {t('IFSC_CODE')}
                      <span className='text-danger'>*</span>
                    </label>
                    <ErrorMessage message={errors?.ifsc?.message} />
                  </Form.Floating>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className='mb-3'>
                  <Form.Floating className='mb-3'>
                    <Form.Control
                      id='floatingInputCustom1'
                      type='text'
                      placeholder='Branch name*'
                      maxLength={15}
                      onInput={e => preventMaxValue(e)}
                      onKeyDown={e => {
                        if (['-', '+', '.'].includes(e.key)) {
                          e.preventDefault()
                        }
                      }}
                      {...register('branchName', {
                        required: t('PLEASE_ENTER_BRANCH_NAME'),
                        minLength: {
                          value: 2,
                          message: t('MINIMUM_LENGTH_MUST_BE_2')
                        }
                      })}
                    />
                    <label htmlFor='floatingInputCustom1'>
                      {t('BRANCH_NAME')}
                      <span className='text-danger'>*</span>
                    </label>
                    <ErrorMessage message={errors?.branchName?.message} />
                  </Form.Floating>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className='mb-3'>
                  <Form.Floating className='mb-3'>
                    <Form.Control
                      id='floatingInputCustom1'
                      type='text'
                      placeholder='Email address*'
                      onInput={preventSpace}
                      value={codeValue.toLowerCase()}
                      maxLength={50}
                      {...register('email', {
                        required: t('PLEASE_ENTER_EMAIL_ADDRESS_FIELD'),
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: t('PLEASE_ENTER_VALID_EMAIL_ADDRESS')
                        }
                      })}
                    />
                    <label htmlFor='floatingInputCustom1'>
                      {t('EMAIL_ADDRESS')}
                      <span className='text-danger'>*</span>
                    </label>
                    <ErrorMessage message={errors?.email?.message} />
                  </Form.Floating>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Button className='btn btn_theme' type='submit'>
                  {t('PROCEED')}
                </Button>
              </Col>
              <Col></Col>
            </Row>
          </Form>
          <Modal
            show={show}
            onHide={handleClose}
            centered
            className='verify_modal'
          >
            <Modal.Header closeButton title={t('O_CLOSE')}>
              <Modal.Title> {t('VERIFIED')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='text-center'>
              <img src='./images/verified.png' alt='' />
              <h3>{t('BANK_DETAILS')}</h3>
              <Link
                to='/PersonalInformation'
                state={verificationId}
                className='btn btn_theme '
                onClick={handleShow}
              >
                {t('O_NEXT')}
              </Link>
            </Modal.Body>
          </Modal>
        </div>

        <Footer />
      </div>
    </>
  )
}

export default CardDetails
