import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'

import './assets/css/style.css'
import './assets/css/media.css'
import './assets/css/responsive.css'
import PrivateRoute from './utils/PrivateRoute'
import Register from './Pages/Register'
import Home from './Pages/Home'
import Dashboard from './Pages/Dashboard'
import PersonalInformation from './Pages/PersonalInformation'
import SetPin from './Pages/SetPin'
import TransferMoney from './Pages/TransferMoney'
import Rechargemobile from './Pages/Rechargemobile'
import Electricitybill from './Pages/Electricitybill'
import Dth from './Pages/Dth'
import Bookgas from './Pages/Bookgas'
import Trafficfine from './Pages/Trafficfine'
import CardDetails from './Pages/CardDetails'
import LoanReqType from './Pages/LoanReqType'
import LoanStatus from './Pages/LoanStatus'
import Profile from './Pages/Profile'
import DeleteAccount from './Pages/DeleteAccount'
import TransferToSelfBank from './Pages//TransferToSelfBank'
import MyOrder from './Pages/MyOrder'
import MyPendingInvoices from './Pages/MyPendingInvoices'
import ReferEarn from './Pages/ReferEarn'
import OfferPromo from './Pages/OfferPromo'
import RewardPoint from './Pages/RewardPoint'
import WalletStatement from './Pages/WalletStatement'
import Settings from './Pages/Settings'
import Location from './Pages/Location'
import SendGiftCard from './Pages/SendGiftCard'
import SelectGiftCard from './Pages/SelectGiftCard'
import GiftPreview from './Pages/GiftPreview'
import AddGiftCard from './Pages/AddGiftCard'
import AddMoney from './Pages/AddMoney'
import Wallet from './Pages/Wallet'
import { AuthProvider } from 'context/AuthContext'
import { ToastContextProvider } from 'context/ToastContext'
import PrivatePolicy from 'Pages/PrivatePolicy'
import Faqs from 'Pages/Faqs'
import ForgotPassword from 'Pages/auth/ForgotPassword'
import OtpScreen from 'Pages/auth/OtpScreen'
import RegisterOtp from 'Pages/auth/RegisterOtp'
import MyQrCode from 'Pages/admin/MyQrCode'
import Notification from 'Pages/admin/notification/Notification'
import PublicRoute from 'utils/PublicRoute'
import Invoice from './Pages/Invoice'
import Login from 'Pages/auth/Login'
import ThirdPartyInvoices from 'Pages/ThirdPartyInvoices'
import ThirdPartyInvoicePaymentOTP from 'Pages/ThirdPartyInvoicePaymentOTP'
import SecondaryVerifyOtp from 'Pages/SecondaryVerifyOtp'
import TransferMoneyOtp from 'Pages/TransferMoneyOtp'
import LoginOtp from 'Pages/auth/LoginOtp'
import ErmalBeqiriWord from 'Pages/ErmalBeqiriWord'

function App () {
  return (
    <div className='App'>
      <BrowserRouter>
        <ToastContextProvider>
          <AuthProvider>
            <Routes>
              <Route element={<PrivateRoute />}>
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/add-money' element={<AddMoney />} />
                <Route path='/transfer-money' element={<TransferMoney />} />
                <Route
                  path='/third-party-invoice'
                  element={<ThirdPartyInvoices />}
                />
                <Route
                  path='/third-party-invoice/otp'
                  element={<ThirdPartyInvoicePaymentOTP />}
                />
                <Route path='/secondary/otp' element={<SecondaryVerifyOtp />} />
                <Route
                  path='/wallet/transfer/otp'
                  element={<TransferMoneyOtp />}
                />
                <Route path='/my-profile' element={<Profile />} />
                <Route path='/my-wallet' element={<Wallet />} />
                <Route path='/my-qrcode' element={<MyQrCode />} />
                <Route path='/notifications' element={<Notification />} />

                <Route
                  path='/my-wallet-statement'
                  element={<WalletStatement />}
                />

                <Route path='/my-settings' element={<Settings />} />
                <Route path='/nearby-merchants' element={<Location />} />
                <Route
                  path='/wallet-statements'
                  element={<WalletStatement />}
                />
                <Route path='/settings' element={<Settings />} />

                <Route
                  path='PersonalInformation'
                  element={<PersonalInformation />}
                />

                <Route path='rechargemobile' element={<Rechargemobile />} />
                <Route path='electricitybill' element={<Electricitybill />} />
                <Route path='dth' element={<Dth />} />
                <Route path='bookgas' element={<Bookgas />} />
                <Route path='trafficfine' element={<Trafficfine />} />
                <Route path='AddMoney' element={<AddMoney />} />
                <Route path='loanreqtype' element={<LoanReqType />} />
                <Route path='cardDetails' element={<CardDetails />} />
                <Route path='LoanStatus' element={<LoanStatus />} />
                <Route path='Profile' element={<Profile />} />
                <Route path='DeactivateAccount' element={<DeleteAccount />} />
                <Route
                  path='transfer-money-to-bank'
                  element={<TransferToSelfBank />}
                />
                <Route path='MyOrder' element={<MyOrder />} />
                <Route path='MyPendingInvoices' element={<MyPendingInvoices />} />
                <Route path='referEarn' element={<ReferEarn />} />
                <Route path='/offers' element={<OfferPromo />} />
                <Route path='RewardPoint' element={<RewardPoint />} />

                <Route path='sendGiftCard' element={<SendGiftCard />} />
                <Route path='selectGiftCard' element={<SelectGiftCard />} />
                <Route path='giftPreview' element={<GiftPreview />} />
                <Route path='addgiftCard' element={<AddGiftCard />} />
                <Route path='Wallet' element={<Wallet />} />
                <Route path='invoice' element={<Invoice />} />
              </Route>

              <Route element={<PublicRoute />}>
                <Route path='/' element={<Home />} />
                {/* <Route path='/ermal-beqiris-word' element={<ErmalBeqiriWord />} /> */}
                <Route path='register' element={<Register />} />
                <Route path='/login' element={<Login />} />
                <Route path='register/otp' element={<RegisterOtp />} />
                <Route path='login/otp' element={<LoginOtp />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/otp' element={<OtpScreen />} />
              </Route>
              <Route path='/privacy-policy' element={<PrivatePolicy />} />
              <Route path='/terms-and-conditions' element={<PrivatePolicy />} />
              <Route path='/about-us' element={<PrivatePolicy />} />
              <Route path='/faqs' element={<Faqs />} />
              <Route path='/contact-us' element={<PrivatePolicy />} />
              <Route path='set-pin' element={<SetPin />} />
            </Routes>
          </AuthProvider>
        </ToastContextProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
