import React from 'react'
import Header from '../components/Header'
import Loader from './Loader'

function MainLayout ({ children }) {
  return (
    <div className=''>
      <Loader />

      <Header />
      <div className=''>{children}</div>
    </div>
  )
}

export default MainLayout
