import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
var calendar = require('dayjs/plugin/calendar')
const MySwal = withReactContent(Swal)
dayjs.extend(calendar)
const obj = {
  currencyFormat: (number = '') => {
    const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    const formatted = Number(number).toLocaleString('en', options)
    return formatted
  },
  dateFormat: (date, timeZone) => {
    var convertedDate = new Date(date).toLocaleString(undefined, {
      timeZone: timeZone ? timeZone : 'Asia/Kolkata'
    })

    return convertedDate.toString()
  },
  msgDateFormat: (date, timeZone) => {
    var convertedDate = new Date(date).toLocaleDateString(undefined, {
      timeZone: timeZone ? timeZone : 'Asia/Kolkata'
    })

    return convertedDate.toString()
  },
  matchDateTime: (date, timeZone) => {
    return dayjs(date).calendar(null, {
      sameDay: 'h:mm A', // The same day ( Today at 2:30 AM )
      nextDay: '[Tomorrow]', // The next day ( Tomorrow at 2:30 AM )
      nextWeek: 'dddd [at] h:mm A', // The next week ( Sunday at 2:30 AM )
      lastDay: '[Yesterday at] h:mm A', // The day before ( Yesterday at 2:30 AM )
      lastWeek: '[Last] dddd [at] h:mm A', // Last week ( Last Monday at 2:30 AM )
      sameElse: 'DD/MM/YYYY' // Everything else ( 17/10/2011 )
    })
  },
  marketStatus: s => {
    var status = ''
    switch (s) {
      case 1:
        status = 'Open'
        break
      case 2:
        status = 'In Active'
        break
      case 3:
        status = 'Suspended'
        break
      case 4:
        status = 'Closed'
        break
      case 9:
        status = 'Ball Start'
        break
      default:
        status = ''
    }
    return status
  },
  getSportType: t => {
    var type = ''
    switch (t) {
      case 1:
        type = 'soccer'
        break
      case 2:
        type = 'tennis'
        break
      case 4:
        type = 'cricket'
        break

      default:
        type = ''
    }
    return type
  },
  isInputNumber: event => {
    var char = String.fromCharCode(event.which)
    if (!/[0-9]/.test(char)) {
      event.preventDefault()
    }
  },
  alertFunction: (title, item, changeFunction, deleteIcon, language) => {
    MySwal.fire({
      html: <strong>{title}</strong>,
      icon: deleteIcon ? 'error' : 'warning',
      confirmButtonText: language == '1' ? 'Ok' : 'Ne rregull',
      cancelButtonText: language == '1' ? 'Cancel' : 'Anullo',
      showConfirmButton: 'Okay',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        changeFunction(item)
      }
    })
  },
  getParameterByName: name => {
    const url = window.location.href
    name = name.replace(/[\[\]]/g, '\\$&')
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  },
  ternaryCondition: (condition, first, second) => {
    return condition ? first : second
  }
}

export default obj
