import apiPath from '../utils/apiPath'
import axios from 'axios'
function getHeaders () {
  return { 'Content-Type': 'application/json' }
}
function getLanguage () {
  console.log('localStorage?.getItem', localStorage?.getItem('language'))
  return localStorage?.getItem('language')
    ? localStorage?.getItem('language')
    : 'al'
}
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // timeout: 1000,
  headers: getHeaders()
})

instance.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  config = {
    ...config,
    headers: { ...config.headers, language: getLanguage() }
  }

  if (!token) {
    return config
  }
  document.getElementById('loader').classList.remove('d-none')
  config = {
    ...config,
    headers: { ...config.headers, Authorization: `Bearer ${token}` }
  }
  return config
})

instance.interceptors.response.use(
  function (response) {
    // Do something with response data
    document.getElementById('loader').classList.add('d-none')
    return response
  },
  function (error) {
    const { status } = error?.response

    if (status === 401 || status === 403) {
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('uniqueId')
      window.location.reload()
    } else if (status === 409) {
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
      window.location.reload()
    }
    return Promise.reject(error)
  }
)

function apiGet (url, params = {}) {
  return instance.get(url, { params })
}

// send config with multipart form data if sending images
function apiPost (url, body, config) {
  return instance.post(url, body, config)
}

// send config with multipart form data headers if sending images
function apiPut (url, body, config) {
  return instance.put(url, body, config)
}

function apiDelete (url) {
  return instance.delete(url)
}

export { getHeaders, apiGet, apiPost, apiPut, apiDelete }
