import Footer from 'components/Footer'
import './MyQrCode.css'
import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { ImQrcode } from 'react-icons/im'
import { FaUserAlt } from 'react-icons/fa'
import AuthContext from 'context/AuthContext'
import OImage from 'components/reusable/OImage'
import pathObj from 'utils/apiPath'
import { apiGet } from 'utils/apiFetch'
import { useTranslation } from 'react-i18next'
import { saveAs } from 'file-saver'

const MyQrCode = () => {
  const { t } = useTranslation()
  let { user } = useContext(AuthContext)
  const [qrCode, setQrCode] = useState('')

  const getCustomerQR = async () => {
    try {
      const res = await apiGet(pathObj.customerQrCode)
      setQrCode(res.data.results)
    } catch (error) {
      console.log('error:', error)
    }
  }

  useEffect(() => {
    getCustomerQR()
  }, [])
  const downloadImage = image => {
    saveAs(image, 'QRcode.png')
  }
  return (
    <div className='main_wrap px-0'>
      <div className='m-0 py-4 sidebar_pageHeading fullPage_space'>
        <h5 className='mb-0 text-white'>
          <ImQrcode style={{ marginRight: '6px' }} /> {t('O_MY_QR_CODE')}
        </h5>
      </div>
      <div className='bg-light main_panel rounded-0 p-3 p-md-4 myOrder'>
        <div className='bg-white p-3 p-md-4'>
          <section className='my-qrcode'>
            <div style={{ fontWeight: '500' }}>{t('O_MY_QR_CODE')}</div>

            {user?.profile_pic ? (
              <OImage
                src={user.profile_pic}
                style={{ width: '40px', height: '40px', marginTop: '20px' }}
              />
            ) : (
              <FaUserAlt
                style={{ width: '40px', height: '40px', marginTop: '20px' }}
              />
            )}

            <div className='username'>
              {' '}
              {`${user?.first_name} ${user?.last_name}`}
            </div>
            <div>+355 {user?.mobile}</div>
            <OImage
              src={qrCode}
              style={{ width: '250px', height: '250px', marginBottom: '20px' }}
              alt='qr code'
            />
            <Button
              onClick={() => downloadImage(qrCode)}
              className='btn btn_theme ms-xl-3 ms-sm-2 mt-xl-0 mt-sm-2 '
            >
              {t('SHARE_QR_CODE')}
            </Button>
          </section>
        </div>
      </div>
      <div className='fullPage_space'>
        <Footer />
      </div>
    </div>
  )
}

export default MyQrCode
