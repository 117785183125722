import React from 'react'

const ErrorMessage = ({ message }) => {
  if (message) {
    return <div className='text-danger mt-2'>{message}</div>
  }

  return <></>
}

export default ErrorMessage
