import ToastContext from "context/ToastContext";
import React, { useContext } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { apiPost } from "utils/apiFetch";
import pathObj from "utils/apiPath";
import { useTranslation } from "react-i18next";

const Cards = () => {
  const { register, handleSubmit, reset } = useForm({
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {},
  });
  let { notification } = useContext(ToastContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const res = await apiPost(pathObj.signupUser, formData, config);
      if (res.data.success === true) {
        notification.success(res.data.message);
        reset();
        navigate("/register/otp", {
          state: {
            email: data.email,
            phone: data.phone,
            country_code: "355",
          },
        });
      } else {
        window.scrollTo(0, 0);
        notification.error(res?.data?.message);
      }
    } catch (error) {}
  };

  return (
    <div>
      <div className="card_list d-md-flex align-items-center mb-4">
        <span class="custom_radio">
          &nbsp; <input type="radio" name="radio" />
          <span class="radio_indicator">&nbsp;</span>{" "}
          <span className="radio_text">
            <img src="./images/mastercard.png" className="me-3" alt="" /> 5444
            **** **** ****
          </span>
        </span>

        <span class="custom_radio ms-md-3 mt-2 mt-md-0">
          &nbsp; <input type="radio" name="radio" />
          <span class="radio_indicator">&nbsp;</span>{" "}
          <span className="radio_text">
            <img src="./images/visa.png" className="me-3" alt="" /> 5444 ****
            **** ****
          </span>
        </span>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="gx-3">
          <Col md={6} lg={4}>
            <Form.Group className="mb-3">
              <FloatingLabel
                controlId="floatingSelectGrid"
                label={t("SELECT_CARD_TYPE")}
              >
                <Form.Select
                  aria-label="Floating label select example"
                  {...register("card_type", {
                    required: t("PLEASE_PROVIDE_CARD_TYPE"),
                  })}
                >
                  <option>Visa</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </Col>

          <Col md={6} lg={4}>
            <Form.Group className="mb-3">
              <Form.Floating>
                <Form.Control
                  id="floatingInputCustom"
                  type="text"
                  {...register("name", {
                    required: t("PLEASE_PROVIDE_CARD_HOLDER_NAME"),
                  })}
                  placeholder="name@example.com"
                  maxLength={50}
                />
                <label htmlFor="floatingInputCustom">
                  {t("CARD_HOLDER_NAME")}
                </label>
              </Form.Floating>
            </Form.Group>
          </Col>
          <Col md={6} lg={4}>
            <Form.Group className="mb-3">
              <Form.Floating>
                <Form.Control
                  id="floatingInputCustom"
                  type="number"
                  {...register("card_number", {
                    required: t("PLEASE_PROVIDE_CARD_NUMBER"),
                  })}
                  placeholder="name@example.com"
                />
                <label htmlFor="floatingInputCustom">{t("CARD_NUMBER")}</label>
              </Form.Floating>
            </Form.Group>
          </Col>
          <Col md={6} lg={4}>
            <Row className="gx-2">
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Floating>
                    <Form.Control
                      id="floatingInputCustom"
                      type="text"
                      placeholder="name@example.com"
                    />
                    <label htmlFor="floatingInputCustom">
                      {t("CARD_EXPIRY_MONTH")}
                    </label>
                  </Form.Floating>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Floating>
                    <Form.Control
                      id="floatingInputCustom"
                      type="text"
                      placeholder="name@example.com"
                    />
                    <label htmlFor="floatingInputCustom">
                      {t("CARD_EXPIRY_YEAR")}
                    </label>
                  </Form.Floating>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col md={6} lg={4}>
            <Row className="gx-2 align-items-center">
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Floating>
                    <Form.Control
                      id="floatingInputCustom"
                      type="text"
                      placeholder="name@example.com"
                      maxLength={50}
                    />
                    <label htmlFor="floatingInputCustom">CVV</label>
                  </Form.Floating>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <span class="custom_check">
                    {t("SAVE_THIS_FOR_FUTURE")} &nbsp; <input type="checkbox" />
                    <span class="check_indicator">&nbsp;</span>
                  </span>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Button className="btn btn_theme py-3 px-5 fw-normal fs-5">
          {" "}
          {t("SAVE")}
        </Button>
      </Form>
    </div>
  );
};

export default Cards;
