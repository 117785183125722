import React from 'react'
import { Breadcrumb, Button, Form, Row, Col, Tabs, Tab } from 'react-bootstrap'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function Trafficfine () {
  const { t } = useTranslation()
  return (
    <>
      <Header />

      <div className='main_wrap'>
        <Breadcrumb className='m-0 py-3'>
          <Breadcrumb.Item href='#'>Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Gas</Breadcrumb.Item>
        </Breadcrumb>

        <div className='bg-light-gray main_panel overflow-hidden p-0'>
          <Row>
            <Col lg={4}>
              <div className='left_form_wrap'>
                <div className='page_heading mb-4'>
                  <h2 className='mb-0'>Traffic Fine/ Challan</h2>
                </div>
                <Form className=''>
                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      <Form.Control
                        id='floatingInputCustom1'
                        type='text'
                        placeholder='name@example.com'
                      />
                      <label htmlFor='floatingInputCustom'>
                        Traffic Authority
                      </label>
                    </Form.Floating>
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      <Form.Control
                        id='floatingInputCustom1'
                        type='text'
                        placeholder='name@example.com'
                      />
                      <label htmlFor='floatingInputCustom'>
                        Have a promocode?
                      </label>
                    </Form.Floating>
                  </Form.Group>

                  <Button className='btn btn_theme py-3 w-100'>Proceed</Button>
                </Form>
              </div>
            </Col>

            <Col lg={8}>
              <div className='right_inner_wrap'>
                <div className='plans_data tabs_theme'>
                  <Tabs
                    defaultActiveKey='trafficauthority'
                    id='uncontrolled-tab-example'
                    className='mb-3'
                  >
                    <Tab eventKey='trafficauthority' title='Traffic Authority'>
                      <div className='bg-white theme_tabs_content gas_provider_list'>
                        <ul className='list-unstyled d-flex flex-wrap mb-0'>
                          <li>
                            <Link to='/'>
                              <figure>
                                <img src='images/promologo.png' alt='' />
                              </figure>
                              <h5>GAS 1</h5>
                            </Link>
                          </li>

                          <li>
                            <Link to='/'>
                              <figure>
                                <img src='images/promologo.png' alt='' />
                              </figure>
                              <h5>GAS 2</h5>
                            </Link>
                          </li>

                          <li>
                            <Link to='/'>
                              <figure>
                                <img src='images/promologo.png' alt='' />
                              </figure>
                              <h5>GAS 3</h5>
                            </Link>
                          </li>

                          <li>
                            <Link to='/'>
                              <figure>
                                <img src='images/promologo.png' alt='' />
                              </figure>
                              <h5>GAS 4</h5>
                            </Link>
                          </li>

                          <li>
                            <Link to='/'>
                              <figure>
                                <img src='images/promologo.png' alt='' />
                              </figure>
                              <h5>GAS 5</h5>
                            </Link>
                          </li>

                          <li>
                            <Link to='/'>
                              <figure>
                                <img src='images/promologo.png' alt='' />
                              </figure>
                              <h5>GAS 6</h5>
                            </Link>
                          </li>

                          <li>
                            <Link to='/'>
                              <figure>
                                <img src='images/promologo.png' alt='' />
                              </figure>
                              <h5>GAS 7</h5>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Tab>
                    <Tab eventKey='gaspromocode' title='Promo Code'>
                      <div className='theme_tabs_content'>
                        <div className='promo_value_list d-flex align-items-center mb-0'>
                          <figcaption className='d-sm-flex d-block align-items-center ps-0'>
                            <div className=''>
                              <strong>DTH300 </strong>
                              <p className='my-sm-0 my-2'>
                                Win ₹8 to 300 cashback on Recharging your DTH
                                account for 200 or more
                              </p>
                            </div>
                            <Link to='/' className='btn btn_theme'>
                              {t('O_LEK')}:299
                            </Link>
                          </figcaption>
                        </div>
                        <div className='promo_value_list d-flex align-items-center mb-0'>
                          <figcaption className='d-sm-flex d-block align-items-center ps-0'>
                            <div className=''>
                              <strong>DTH300 </strong>
                              <p className='my-sm-0 my-2'>
                                Win ₹8 to 300 cashback on Recharging your DTH
                                account for 200 or more
                              </p>
                            </div>
                            <Link to='/' className='btn btn_theme'>
                              {t('O_LEK')}:299
                            </Link>
                          </figcaption>
                        </div>

                        <div className='promo_value_list d-flex align-items-center mb-0'>
                          <figcaption className='d-sm-flex d-block align-items-center ps-0'>
                            <div className=''>
                              <strong>DTH300 </strong>
                              <p className='my-sm-0 my-2'>
                                Win ₹8 to 300 cashback on Recharging your DTH
                                account for 200 or more
                              </p>
                            </div>
                            <Link to='/' className='btn btn_theme'>
                              {t('O_LEK')}:299
                            </Link>
                          </figcaption>
                        </div>
                        <div className='promo_value_list d-flex align-items-center mb-0'>
                          <figcaption className='d-sm-flex d-block align-items-center ps-0'>
                            <div className=''>
                              <strong>DTH300 </strong>
                              <p className='my-sm-0 my-2'>
                                Win ₹8 to 300 cashback on Recharging your DTH
                                account for 200 or more
                              </p>
                            </div>
                            <Link to='/' className='btn btn_theme'>
                              {t('O_LEK')}:299
                            </Link>
                          </figcaption>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <Footer />
      </div>
    </>
  )
}

export default Trafficfine
