import React, { useState } from 'react'
import { Form, Col, Row, Button } from 'react-bootstrap'
import Footer from '../components/Footer'
import OtpInput from 'react18-input-otp'
import { apiPost } from 'utils/apiFetch'
import pathObj from 'utils/apiPath'
import ErrorMessage from 'components/reusable/ErrorMessage'
import useToastContext from 'hooks/useToastContext'
function SetPin () {
  const [pin, setPin] = useState('')
  const onChangePin = value => setPin(value)
  const [confirmPin, setConfirmPin] = useState('')
  const onChangeConfirmPin = value => setConfirmPin(value)
  const [message, setMessage] = useState('')
  const [isError, setIsError] = useState(false)

  const notification = useToastContext()

  const validate = () => {
    if (pin.length < 4) {
      setMessage('Please, enter your pin')

      return false
    } else if (confirmPin.length < 4) {
      setMessage('Please, enter your confirm pin')
      return false
    } else if (pin !== confirmPin) {
      setMessage('Your pin do no match')
      return false
    } else {
      return true
    }
  }
  const onSubmit = async data => {
    try {
      const isValid = validate()
      if (!isValid) {
        setIsError(true)
        return
      }
      setIsError(false)
      const payload = {
        pin: Number(confirmPin)
      }
      const result = await apiPost(pathObj.securityPin, payload)
      if (result?.data.success === true) {
        notification.success(result.data.message)
      } else {
        notification.error(result.data.message)
      }
    } catch (error) {}
  }
  return (
    <>
      <div className='main_wrap'>
        <div className='m-0 py-sm-4 py-2'></div>

        <div className='bg-white main_panel p-0 overflow-hidden register'>
          <Row className='g-0 align-items-center h-100'>
            <Col md={6}>
              <Form className='set-pin-sec'>
                <Row>
                  <Col sm={12}>
                    <h4 className='mb-3 mb-sm-5'>Set Pin</h4>
                  </Col>

                  <Col sm={12}>
                    <Form.Group className='mb-4'>
                      <h5 className='mb-2 text-dark'>Pin</h5>
                      <Row className='gx-2'>
                        <OtpInput
                          value={pin}
                          valueLength={4}
                          onChange={onChangePin}
                          isInputNum={true}
                        />
                      </Row>
                    </Form.Group>
                    <Form.Group className='mb-4'>
                      <h5 className='mb-2 text-dark'>Confirm Pin</h5>

                      <OtpInput
                        value={confirmPin}
                        valueLength={4}
                        onChange={onChangeConfirmPin}
                        isInputNum={true}
                      />
                    </Form.Group>
                  </Col>
                  {isError && <ErrorMessage message={message} />}
                  <Col sm={12}>
                    <Button
                      className='btn btn_theme w-100 py-sm-3 mb-3'
                      onClick={onSubmit}
                    >
                      Done
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col
              md={6}
              className='login_right text-center bg-light p-4 d-flex align-items-center justify-content-center'
            >
              <img src='./images/setpin.png' alt='' />
            </Col>
          </Row>
        </div>

        <Footer />
      </div>
    </>
  )
}

export default SetPin
