import { useState } from 'react'
import { Accordion, Row, Col, Modal } from 'react-bootstrap'
import pathObj from 'utils/apiPath'
import { apiPost } from 'utils/apiFetch'
import dayjs from 'dayjs'
import useToastContext from 'hooks/useToastContext'
import { useTranslation } from 'react-i18next'

const LoanTab = props => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const notification = useToastContext()
  const [data, setData] = useState({})
  const [loanData, setLoanData] = useState({})
  const [refData, setRefData] = useState({})
  const handleClose = () => setShow(false)

  const handleShow = async (i, loan) => {
    try {
      console.log('data', i, loan)
      const path = pathObj.loanPreview
      const payload = {
        loanId: loan,
        referenceId: i?.referenceId
      }
      const result = await apiPost(path, payload)
      setLoanData(loan)
      setRefData(i?.referenceId)
      setData(result.data.results)
      setShow(true)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handlePayModal = async () => {
    try {
      const sendData = {
        loanId: loanData,
        referenceId: refData
      }
      const path = pathObj.loanPay
      const result = await apiPost(path, sendData)
      if (result?.data?.success) {
        props.getData()
      }
      setShow(false)
      notification.success(result?.data?.message)
    } catch (error) {
      console.log('getBalance==>', error)
    }
  }

  const handleCloseModal = () => {
    setShow(false)
  }

  return (
    <Accordion defaultActiveKey={null}>
      {props?.data?.docs?.map(res => {
        return (
          <Accordion.Item
            eventKey={res?._id}
            className='border-0 mb-2 px-3 py-2'
          >
            <Accordion.Header className='loan-status-header'>
              <span className='mb-0 text-dark fw-bold d-flex align-items-center first_cols'>
                <figure className='mb-0'>
                  <img
                    src='./images/pending_acc1.png'
                    className='me-2'
                    alt=''
                  />{' '}
                </figure>
                <figcaption className='ps-3'>{res?.loanCreditTitle}</figcaption>
              </span>
              <span className='link fw-bold second_cols'>
                {t('O_LEK')}: {res?.loanAmount}
              </span>
              <span className='text-dark fw-bold third_cols'>
                {t('INSTALLMENTS')}:{' '}
                <span className='text-warning'>{t(res?.status)}</span>
              </span>
              <span className='text-dark fourth_cols'>
                {dayjs(res?.createdAt).format('DD-MM-YYYY hh:mm A')}
              </span>
            </Accordion.Header>

            <Accordion.Body>
              {props?.defaultKey === 'pending' ? (
                <>
                  <ul className='mb-4 mb-md-5 p-0 list-unstyled loan_status_box d-flex flex-wrap'>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('PROCESS_DATE')}{' '}
                        </span>{' '}
                        {dayjs(res?.createdAt).format('DD-MM-YYYY')}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('STATUS')}{' '}
                        </span>{' '}
                        {res?.status}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('PROCESSING_FEE')}{' '}
                        </span>{' '}
                        {t('O_LEK')} - {res?.processingFees}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('LOAN_AMOUNT')}{' '}
                        </span>{' '}
                        {t('O_LEK')} - {res?.loanAmount}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('LOAN_TENURE')}{' '}
                        </span>{' '}
                        {res?.tenure} {t('DAY')}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('FINAL_LOAN_AMOUNT')}{' '}
                        </span>{' '}
                        {t('O_LEK')} - {res?.finalLoanAmount}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('REPAYABLE_AMOUNTS')}{' '}
                        </span>{' '}
                        {t('O_LEK')} - {res?.repayableAmount?.toFixed(2)}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('INTEREST_RATE')}{' '}
                        </span>{' '}
                        {res?.interestRate}%
                      </h6>
                    </li>
                  </ul>

                  <h5>{t('INSTALLMENTS')}</h5>
                  <p>
                    {t('THERE_WILL_BE')} {res?.installments?.length}{' '}
                    {t('INSTALLMENTS')}
                  </p>
                  <ol>
                    {res?.installments?.map((i, index) => (
                      <li className='mb-2'>
                        {t(index + 1)} {t('OF')} ALL {i.installment.toFixed(2)}{' '}
                        , {t('DUE_DATE_WILL_BE')}{' '}
                        {dayjs(i.dueDate).format('DD-MM-YYYY')}
                      </li>
                    ))}
                  </ol>
                </>
              ) : (
                ''
              )}
              {props?.defaultKey === 'approved' ? (
                <>
                  <ul className='mb-4 mb-md-5 p-0 list-unstyled loan_status_box d-flex flex-wrap'>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('PROCESS_DATE')}{' '}
                        </span>{' '}
                        {dayjs(res?.createdAt).format('DD-MM-YYYY')}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('STATUS')}{' '}
                        </span>{' '}
                        {res?.status}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('PROCESSING_FEE')}{' '}
                        </span>{' '}
                        {t('O_LEK')} - {res?.processingFees}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('LOAN_AMOUNT')}{' '}
                        </span>{' '}
                        {t('O_LEK')} - {res?.loanAmount}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('LOAN_TENURE')}{' '}
                        </span>{' '}
                        {res?.tenure} {t('DAY')}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('FINAL_LOAN_AMOUNT')}{' '}
                        </span>{' '}
                        {t('O_LEK')} - {res?.finalLoanAmount}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('REPAYABLE_AMOUNTS')}{' '}
                        </span>{' '}
                        {t('O_LEK')} - {res?.repayableAmount?.toFixed(2)}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('INTEREST_RATE')}{' '}
                        </span>{' '}
                        {res?.interestRate}%
                      </h6>
                    </li>
                  </ul>

                  <h5>{t('INSTALLMENTS')}</h5>
                  <p>
                    {t('THERE_WILL_BE')} {res?.installments?.length}{' '}
                    {t('INSTALLMENTS')}
                  </p>
                  <div className='px-3 installment-sec'>
                    <Row>
                      {res?.installments?.map((i, index) => (
                        <>
                          <Col md={3}>
                            <p className='mb-2'>
                              {index + 1}.{t(index + 1)} of{' '}
                              {i.installment.toFixed(2)} <br />
                              {t('DUE_DATE_WILL_BE')}{' '}
                              {dayjs(i.dueDate).format('DD-MM-YYYY')}
                              <br />
                              {t('STATUS')} :{' '}
                              <span className='text-warning'>
                                {t(i?.status)}
                              </span>{' '}
                            </p>
                            <button
                              disabled={i.status == 'paid' ? true : false}
                              className={
                                i.status == 'paid' ? 'btn-paid' : 'btn btn-sm'
                              }
                              onClick={() => handleShow(i, res?._id)}
                            >
                              {i.status == 'paid' ? (
                                <span>{t('PAID')}</span>
                              ) : (
                                t('PAY')
                              )}
                            </button>
                          </Col>
                        </>
                      ))}
                    </Row>
                  </div>
                </>
              ) : (
                ''
              )}
              {props?.defaultKey === 'cancelled' ? (
                <>
                  <ul className='mb-4 mb-md-5 p-0 list-unstyled loan_status_box d-flex flex-wrap'>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('PROCESS_DATE')}{' '}
                        </span>{' '}
                        {dayjs(res?.createdAt).format('DD-MM-YYYY')}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('STATUS')}{' '}
                        </span>{' '}
                        {res?.status}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('PROCESSING_FEE')}{' '}
                        </span>{' '}
                        {t('O_LEK')} - {res?.processingFees}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('LOAN_AMOUNT')}{' '}
                        </span>{' '}
                        {t('O_LEK')} - {res?.loanAmount}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('LOAN_TENURE')}{' '}
                        </span>{' '}
                        {res?.tenure} {t('DAY')}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('FINAL_LOAN_AMOUNT')}{' '}
                        </span>{' '}
                        {t('O_LEK')} - {res?.finalLoanAmount}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('REPAYABLE_AMOUNTS')}{' '}
                        </span>{' '}
                        {t('O_LEK')} - {res?.repayableAmount?.toFixed(2)}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('INTEREST_RATE')}{' '}
                        </span>{' '}
                        {res?.interestRate}%
                      </h6>
                    </li>
                  </ul>

                  <h5>{t('INSTALLMENTS')}</h5>
                  <p>
                    {t('THERE_WILL_BE')} {res?.installments?.length}{' '}
                    {t('INSTALLMENTS')}
                  </p>
                  <ol>
                    {res?.installments?.map((i, index) => (
                      <li className='mb-2'>
                        {t(index + 1)} of {i.installment.toFixed(2)} ,{' '}
                        {t('DUE_DATE_WILL_BE')}{' '}
                        {dayjs(i.dueDate).format('DD-MM-YYYY')}
                      </li>
                    ))}
                  </ol>
                </>
              ) : (
                ''
              )}
              {props?.defaultKey === 'completed' ? (
                <>
                  <ul className='mb-4 mb-md-5 p-0 list-unstyled loan_status_box d-flex flex-wrap'>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('PROCESS_DATE')}{' '}
                        </span>{' '}
                        {dayjs(res?.createdAt).format('DD-MM-YYYY')}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('STATUS')}{' '}
                        </span>{' '}
                        {res?.status}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('PROCESSING_FEE')}{' '}
                        </span>{' '}
                        {t('O_LEK')} - {res?.processingFees}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('LOAN_AMOUNT')}{' '}
                        </span>{' '}
                        {t('O_LEK')} - {res?.loanAmount}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('LOAN_TENURE')}{' '}
                        </span>{' '}
                        {res?.tenure} {t('DAY')}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('FINAL_LOAN_AMOUNT')}{' '}
                        </span>{' '}
                        {t('O_LEK')} - {res?.finalLoanAmount}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('REPAYABLE_AMOUNTS')}{' '}
                        </span>{' '}
                        {t('O_LEK')} - {res?.repayableAmount?.toFixed(2)}
                      </h6>
                    </li>
                    <li>
                      <h6 className='m-0 fw-bold text-dark fs-6 text-center'>
                        <span className='fw-normal d-block mb-1'>
                          {t('INTEREST_RATE')}{' '}
                        </span>{' '}
                        {res?.interestRate}%
                      </h6>
                    </li>
                  </ul>

                  <h5>{t('INSTALLMENTS')}</h5>
                  <p>
                    {t('THERE_WILL_BE')} {res?.installments?.length}{' '}
                    {t('INSTALLMENTS')}
                  </p>
                  <ol>
                    {res?.installments?.map((i, index) => (
                      <li className='mb-2'>
                        {index + 1}
                        {t(index + 1)} of {i.installment.toFixed(2)} ,{' '}
                        {t('DUE_DATE_WILL_BE')}{' '}
                        {dayjs(i.dueDate).format('DD-MM-YYYY')}
                      </li>
                    ))}
                  </ol>
                </>
              ) : (
                ''
              )}
            </Accordion.Body>
          </Accordion.Item>
        )
      })}
      {props?.data?.docs == 0 ? (
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '500px',
            alignItems: 'center',
            fontSize: '16px'
          }}
        >
          {t('O_NO_RECORD_FOUND')}
        </span>
      ) : (
        ''
      )}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className='verify_modal track_modal'
      >
        <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center p-5'>
          <div className='flex items-center w-full justify-center mb-4'>
            <div className='relative text-center '>
              <div className='w-32 h-30 relative mx-auto'>
                <img src='images/check.png' alt='No_image' />
              </div>
              <h3 className='font-medium text-[18px] mb-4 mt-4'>
                {t('O_LEK')} {data?.totalAmount?.toFixed(2)}{' '}
                {t('WILL_BE_DEDUCTED_FROM_THE_WALLET')}
              </h3>
            </div>
            <div className='d-flex justify-content-center align-items-center'>
              <button
                Button
                className='btn_theme me-3 mb-0'
                style={{ width: '40%' }}
                onClick={() => handleCloseModal()}
              >
                {t('CANCEL')}
              </button>
              <button
                Button
                className='btn_theme mb-0'
                style={{ width: '40%' }}
                type='submit'
                onClick={() => handlePayModal()}
              >
                {t('OK')}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Accordion>
  )
}

export default LoanTab
