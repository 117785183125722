import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import Footer from '../components/Footer'
import { apiGet } from '../utils/apiFetch'
import pathObj from '../utils/apiPath'
import dayjs from 'dayjs'
import useToastContext from 'hooks/useToastContext'
import { useTranslation } from 'react-i18next'
import Alert from 'react-bootstrap/Alert'
import Pagination from 'components/reusable/Pagination'

function RewardPoint () {
  const { t } = useTranslation()
  const [loyaltyPoint, setLoyaltyPoint] = useState()
  const [promoCard, setPromoCard] = useState([])
  const [page, setPage] = useState(1)
  const notification = useToastContext()

  const myLoyaltyPoints = async () => {
    try {
      const path = `${pathObj.myLoyaltyPoints}?page=${page}`
      const result = await apiGet(path)
      setLoyaltyPoint(result.data)
      setPromoCard(result.data.results.docs)
    } catch (error) {
      console.log('getBalance==>', error)
    }
  }

  useEffect(() => {
    myLoyaltyPoints()
  }, [page])

  const pageCount = Math.ceil(loyaltyPoint?.results?.totalPages)
  const docsLength = loyaltyPoint?.results?.docs.length
  const handlePageClick = async event => {
    setPage(event + 1)
  }
  const handleConvertLoyalty = async () => {
    const res = await apiGet(pathObj.loyaltyConvert)
    if (res.data.success) {
      notification.success(res.data.message)
      myLoyaltyPoints()
    } else {
      notification.error(res.data.message)
    }
  }

  const PromoCardComponent = props => {
    const expiryDate = dayjs(props.data.expiry_date).format(
      'DD/MM/YYYY HH:MM A'
    )
    const updatedDate = dayjs(props.data.createdAt).format('DD/MM/YYYY HH:MM A')

    return (
      <>
        <Col md={6}>
          <div className='promo_card'>
            <figcaption className='d-flex p-2 align-items-center justify-content-between'>
              <div className='pb-0'>
                <span
                  style={{ textTransform: 'capitalize', fontWeight: '500' }}
                >
                  {' '}
                  {props.data.title}{' '}
                </span>{' '}
                <br /> {t('ON')} {updatedDate}
              </div>
              <div className='d-flex flex-column'>
                <div className='ms-auto text-end'>
                  <span className='expire_date d-block mb-2'>
                    {t('O_PROMO_CODES_EXPIRE')}: {expiryDate}
                  </span>
                </div>
                <span className='expire_date d-block mb-2'>
                  {t('PROMO_CODES_EARNED_LOYALTY')}: {props.data.point}
                </span>
              </div>
            </figcaption>
          </div>
        </Col>
      </>
    )
  }

  return (
    <>
      {/* <Header /> */}

      <div className='main_wrap px-0 refer_earn'>
        <div className='m-0 py-4 sidebar_pageHeading fullPage_space'>
          <h5 className='mb-0 text-white'>
            <img src='./images/sidebar_icon6.svg' alt='' className='me-2' />{' '}
            {t('NAV_LOYALTY_POINTS')}
          </h5>
        </div>

        <div className='bg-light-gray main_panel rounded-0 p-3 p-md-4'>
          <div className='rounded-0 overflow-hidden bg-white  p-3 p-md-4'>
            <Alert variant='primary'>
              <div className='d-flex flex-wrap align-items-center justify-content-between'>
                <h6>
                  {t('LOYALTY_POINTS')}:{' '}
                  {loyaltyPoint?.results?.totalLoyaltyPoints}
                </h6>

                <button
                  className='copy_tag position-static mw-auto'
                  style={{ border: 0, maxWidth: '100%' }}
                  onClick={() => handleConvertLoyalty()}
                >
                  {t('LOYALTY_POINTS_CONVERT')}
                </button>
              </div>
            </Alert>
            <Row className='gx-3'>
              <Col md={4}>
                <div className='refer_box rounded-2 mb-md-5 mb-3'>
                  <h6 className='m-0 py-4 px-3'>
                    <span className='d-block fw-bold link'>
                      {loyaltyPoint?.results?.transactions}
                    </span>{' '}
                    {t('LOYALTY_POINTS_TRANSACTION')}
                  </h6>
                </div>
              </Col>
              <Col md={4}>
                <div className='refer_box rounded-2 mb-md-5 mb-3'>
                  <h6 className='m-0 py-4 px-3'>
                    <span className='d-block fw-bold link'>
                      {loyaltyPoint?.results?.deals}
                    </span>{' '}
                    {t('LOYALTY_POINTS_DEALS')}
                  </h6>
                </div>
              </Col>
              <Col md={4}>
                <div className='refer_box rounded-2 mb-md-5 mb-3'>
                  <h6 className='m-0 py-4 px-3'>
                    <span className='d-block fw-bold link'>
                      {loyaltyPoint?.results?.payment}
                    </span>{' '}
                    {t('LOYALTY_POINTS_PAYMENT')}
                  </h6>
                </div>
              </Col>
              {loyaltyPoint?.results?.docs?.map((val, ind) => {
                return <PromoCardComponent data={val} key={ind} />
              })}

              <Pagination
                pageCount={pageCount}
                docsLength={docsLength}
                page={page}
                handlePageClick={handlePageClick}
              />
            </Row>
          </div>
        </div>

        <div className='fullPage_space'>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default RewardPoint
