import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import { Tab, Tabs, Container } from 'react-bootstrap'
import Footer from '../components/Footer'
import { useTranslation } from 'react-i18next'
import IndividualRegistration from './auth/registration_type/IndividualRegistration'
import BusinessRegistration from './auth/registration_type/BusinessRegistration'
import {Helmet} from "react-helmet";


function Register () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)

  useEffect(() => {
    if (user !== null) {
      navigate('/dashboard')
    }
  }, [user])
  return (
    <>
      <Helmet>
            <title>{t('REGISTER_REGISTER')}</title>
            <meta name="title" content={t('REGISTER_REGISTER')} />
            <meta name="description" content={t('LOGIN_REGISTER_HERE')} />
            <meta property="og:title" content={t('REGISTER_REGISTER')} />
            <meta property="og:description" content={t('LOGIN_REGISTER_HERE')} />
            <meta name="author" content="ALPAY" />
        </Helmet>
      <Container>
        <div className='main_wrap'>
          <div className='bg-white main_panel p-0 overflow-hidden register_wrap'>
            <h3>{t('SELECT_YOUR_ACCOUNT_TYPE')}</h3>
            <div className='register_wrap_tabs'>
              <Tabs
                defaultActiveKey='individual'
                id='uncontrolled-tab-example'
                className='mb-lg-3 mb-1'
              >
                <Tab eventKey='individual' title={t('REGISTER_AS_INDIVIDUAL')}>
                  <IndividualRegistration />
                </Tab>
                <Tab eventKey='business' title={t('REGISTER_AS_BUSINESS')}>
                  <BusinessRegistration />
                </Tab>
              </Tabs>
            </div>
          </div>
          <Footer />
        </div>
      </Container>
    </>
  )
}

export default Register
