import { Link, useNavigate } from 'react-router-dom'
import { Row, Form, Col, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import useToastContext from 'hooks/useToastContext'
import pathObj from 'utils/apiPath'
import { apiPost } from 'utils/apiFetch'
import ErrorMessage from 'components/reusable/ErrorMessage'
import { useEffect } from 'react'
import { preventMax } from 'utils/reusableMethods'
import { integerInput } from 'utils/InputControl'
import { useTranslation } from 'react-i18next'

function TransferToBank ({ getBalance, handleShow, dropDown }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const notification = useToastContext()
  const {
    register,
    handleSubmit,
    reset: resetBank,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })
  const sendMoneyToBank = async data => {
    try {
      const path = pathObj.sendOTPTransferMoney
      const sendData = { ...data, transferType: 'bank' }

      const result = await apiPost(path, sendData)

      if (result?.data?.success) {
        notification.success(result?.data?.message)
        navigate('/wallet/transfer/otp', { state: sendData })

        resetBank()
        getBalance()
      } else {
        notification.error(result?.data?.message)
      }
      console.log('send Money ======>', result)
    } catch (error) {}
  }
  useEffect(() => {
    if (dropDown === 1) {
      resetBank()
    }
  }, [dropDown])

  const preventSpace = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }

  return (
    <Form onSubmit={handleSubmit(sendMoneyToBank)}>
      <Row>
        <Col md={4}>
          <Form.Group className='mb-3'>
            <Form.Floating>
              <Form.Control
                id='floatingInputCustom'
                type='number'
                placeholder='name@example.com'
                name='account_no'
                onInput={e => preventMax(e, 10)}
                onKeyPress={e => integerInput(e)}
                {...register('account_no', {
                  required: t('PLEASE_ENTER_ACCOUNT_NUMBER'),
                  maxLength: {
                    value: 50,
                    message: t('MAXIMUM_LENGTH_OF_ACCOUNT_NUMBER_IS_50')
                  }
                })}
              />
              <label htmlFor='floatingInputCustom'>
                {t('ACCOUNT_NUMBER')}
                <span className='text-danger'>*</span>
              </label>
              <ErrorMessage message={errors?.account_no?.message} />
            </Form.Floating>
          </Form.Group>
        </Col>

        <Col md={4}>
          <Form.Group className='mb-3'>
            <Form.Floating>
              <Form.Control
                id='floatingInputCustom'
                type='text'
                placeholder='name@example.com'
                name='unique_code'
                onInput={e => preventMax(e, 30)}
                onKeyDown={preventSpace}
                {...register('unique_code', {
                  required: t('PLEASE_ENTER_UNIQUE_CODE'),
                  maxLength: {
                    value: 30,
                    message: t('MAXIMUM_LENGTH_UNIQUE_CODE_IS_30')
                  },
                  pattern: {
                    value:
                      /^[A-Z]{4}[-]{0,1}[A-Z]{2}[-]{0,1}[A-Z0-9]{2}[-]{0,1}[0-9]{3}$/,
                    message: t('INVALID_SWIFT_CODE')
                  }
                })}
              />
              <label htmlFor='floatingInputCustom'>
                {t('UNIQUE_CODE')}
                <span className='text-danger'>*</span>
              </label>
              <ErrorMessage message={errors?.unique_code?.message} />
            </Form.Floating>
          </Form.Group>
        </Col>
        <Col md={4} className='py-3'>
          <Link to='' className='link fw-bold' onClick={handleShow}>
            {t('GET_SWIFT_CODE')}{' '}
          </Link>
        </Col>
        <Col md={4}>
          <Form.Group className='mb-3'>
            <Form.Floating>
              <Form.Control
                id='floatingInputCustom'
                type='number'
                onKeyDown={e => {
                  if (['-', '+', 'e'].includes(e.key)) {
                    e.preventDefault()
                  }
                }}
                onInput={e => preventMax(e, 9)}
                placeholder='name@example.com'
                name='amount'
                {...register('amount', {
                  required: t('PLEASE_ENTER_AMOUNT'),
                  min: {
                    value: 1,
                    message: t('MINIMUM_AMOUNT_IS_1')
                  }
                })}
              />
              <label htmlFor='floatingInputCustom'>
                {t('O_AMOUNT')}
                <span className='text-danger'>*</span>
              </label>
              <ErrorMessage message={errors?.amount?.message} />
            </Form.Floating>
          </Form.Group>
        </Col>
        <Col md={8}>
          <Form.Group className='mb-3'>
            <Form.Floating>
              <Form.Control
                id='floatingInputCustom'
                type='text'
                placeholder='name@example.com'
                name='message'
                maxLength={160}
                onKeyDown={preventSpace}
                {...register('message', {
                  maxLength: {
                    value: 500,
                    message: t('MAXIMUM_LENGTH_OF_MESSAGE_IS_500')
                  }
                })}
              />
              <label htmlFor='floatingInputCustom'>
                {t('GIFT_CARD_MESSAGE')}
              </label>
            </Form.Floating>
          </Form.Group>
        </Col>
      </Row>
      <Button className='btn btn_theme ' type='submit'>
        {t('SEND_MONEY')}
      </Button>
    </Form>
  )
}

export default TransferToBank
