import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Form, Col, Row, Button } from 'react-bootstrap'
import Footer from '../../components/Footer'
import { useForm } from 'react-hook-form'
import ErrorMessage from 'components/reusable/ErrorMessage'
import useToastContext from 'hooks/useToastContext'
import pathObj from 'utils/apiPath'
import { apiPost } from 'utils/apiFetch'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import OtpInput from 'react18-input-otp'
import { useTranslation } from 'react-i18next'
import { validationRules } from 'utils/constants'
import { isEmpty } from 'lodash'

function OtpScreen () {
  const { t } = useTranslation()
  const location = useLocation()
  const data = location.state
  const notification = useToastContext()
  const navigate = useNavigate()
  const [icon1, setIcon1] = useState(true)
  const [icon2, setIcon2] = useState(true)
  const {
    register,
    handleSubmit,
    reset,
    watch,
    trigger,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })

  const [seconds, setSeconds] = useState(59)
  const [minutes, setMinutes] = useState(1)
  let timerRef
  useEffect(() => {
    timerRef = setInterval(() => {
      if (minutes >= 0) {
        if (seconds == 0) {
          if (minutes > 0) {
            setSeconds(60)
            setMinutes(prev => prev - 1)
          } else {
            setSeconds(0)
          }
        } else {
          setSeconds(prev => prev - 1)
        }
      } else {
        clearInterval(timerRef)
      }
    }, 1000)
    console.log(timerRef)
    return () => clearInterval(timerRef)
  })

  const [loading, setLoading] = useState(false)
  const [otp, setOtp] = useState(null)
  const [otpError, setOtpError] = useState('')
  const onSubmit = async data => {
    if (minutes === 0 && seconds === 0) {
      notification.error(t('OTP_EXPIRE'))
      return
    }
    if (!otp) {
      setOtpError(t('OTP_REQUIRE'))
      return
    } else {
      setOtpError('')
    }
    let res
    try {
      setLoading(true)
      let sendData = {}

      if (location.state.type === 'email') {
        sendData.type = 'email'
        sendData.email = location.state.email
      } else if (location.state.type === 'mobile') {
        sendData.type = 'mobile'
        sendData.mobile = location.state.mobile
        sendData.country_code = '355'
      }
      sendData.otp = otp
      sendData.password = data.password

      res = await apiPost(pathObj.forgotPasswordOtp, sendData)
      if (res.data.success) {
        notification.success(res.data.message)
        reset()

        navigate('/login')
      } else {
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setLoading(false)
    }
  }

  const handleChange = value => setOtp(value)

  const handleResendOtp = async () => {
    try {
      if (minutes == 0 && seconds == 0) {
        let sendData = {}
        if (location?.state?.type === 'email') {
          sendData.type = 'email'
          sendData.email = location?.state?.email
        } else if (location?.state?.type === 'mobile') {
          sendData.type = 'mobile'
          sendData.mobile = location?.state?.mobile
          sendData.country_code = '355'
        }
        const res = await apiPost(pathObj.forgotPassword, sendData)
        if (res.data.success === true) {
          notification.success(res.data.message)
          setSeconds(59)
          setMinutes(1)
        } else {
          notification.error(res.data.message)
        }
      } else {
        notification.error(t('WAIT_FOR_OTP'))
      }
    } catch (err) {
      console.log('err:', err)
    }
  }

  useEffect(() => {
    if (otp) {
      setOtpError('')
    }
  }, [otp])

  const password = watch('password')
  useEffect(() => {
    if (!isEmpty(password)) {
      trigger('confirm_password')
    }
  }, [password, trigger])

  const preventSpacePass = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }

  return (
    <>
      <div className='main_wrap'>
        <div className='m-0 py-sm-4 py-2'></div>
        <div className='bg-white forgot_panel p-0 overflow-hidden register main_panel'>
          <Row className='g-0'>
            <Col lg={6}>
              <form
                onSubmit={handleSubmit(onSubmit, () => {
                  if (!otp) {
                    setOtpError(t('OTP_EXPIRE'))
                    return
                  } else {
                    setOtpError('')
                  }
                })}
              >
                <Row>
                  <Col sm={12}>
                    <h4 className='mb-3 mb-sm-5 text-center'>{t('OTP')}</h4>
                  </Col>

                  <Col sm={12}>
                    <label
                      style={{
                        fontWeight: '600'
                      }}
                    >
                      {data.type === 'email' ? 'Email OTP' : 'Phone OTP'}
                      <span className='text-danger'>*</span>
                    </label>
                    <OtpInput
                      inputStyle={{
                        border: 'solid 1px #dfdfdf',
                        width: '5rem',
                        height: '2.4rem',
                        marginRight: '.5rem',
                        outline: 'none',
                        borderRadius: '8px'
                      }}
                      isInputNum={true}
                      value={otp}
                      shouldAutoFocus
                      containerStyle='text-center mt-2'
                      onChange={value => handleChange(value)}
                      numInputs={4}
                      separator={<span> </span>}
                    />
                    <div className='d-flex justify-content-between mt-2'>
                      <div>
                        {t('OTP_EXPIRING')} {minutes}:{seconds}
                      </div>
                      <div
                        style={{
                          textAlign: 'right',
                          color: 'blue',
                          cursor: 'pointer'
                        }}
                        onClick={handleResendOtp}
                      >
                        {t('RESEND_OTP')}
                      </div>
                    </div>

                    <ErrorMessage message={otpError} />
                  </Col>

                  <Col sm={12}>
                    <Form.Group className='mb-3 mt-3'>
                      <Form.Floating className='mb-3'>
                        <Form.Control
                          id='floatingInputCustom2'
                          type={icon1 ? 'password' : 'text'}
                          placeholder='name@example.com'
                          maxLength={15}
                          autoComplete='new-password'
                          onInput={preventSpacePass}
                          {...register('password', {
                            required: t('REGISTER_PASSWORD'),
                            pattern: {
                              value: validationRules.password,
                              message: validationRules.passwordMessage
                            }
                          })}
                        />
                        <label htmlFor='floatingInputCustom'>
                          {t('O_NEW_PASSWORD')}
                          <span className='text-danger'>*</span>
                        </label>
                        {icon1 ? (
                          <span
                            className='password_view cursor-pointer absolute top-[18px] right-[20px]'
                            style={{
                              position: 'absolute',
                              top: '18px',
                              right: '20px'
                            }}
                            onClick={() => setIcon1(!icon1)}
                          >
                            <AiFillEyeInvisible />
                          </span>
                        ) : (
                          <span
                            className='password_view cursor-pointer absolute top-[18px] right-[20px]'
                            onClick={() => setIcon1(!icon1)}
                            style={{
                              position: 'absolute',
                              top: '18px',
                              right: '20px'
                            }}
                          >
                            <AiFillEye />
                          </span>
                        )}
                        <ErrorMessage message={errors?.password?.message} />
                      </Form.Floating>
                    </Form.Group>
                  </Col>

                  <Col sm={12}>
                    <Form.Group className='mb-3 '>
                      <Form.Floating className='mb-3'>
                        <Form.Control
                          id='floatingInputCustom2'
                          type={icon2 ? 'password' : 'text'}
                          placeholder='name@example.com'
                          maxLength={15}
                          autoComplete='new-password'
                          onInput={preventSpacePass}
                          {...register('confirm_password', {
                            required: t('REGISTER_CONFIRM_PASSWORD'),
                            pattern: {
                              value: validationRules.password,
                              message: validationRules.passwordMessage
                            },
                            validate: val => {
                              if (watch('password') !== val) {
                                return 'New password and confirm password does not match.'
                              }
                            }
                          })}
                        />
                        <label htmlFor='floatingInputCustom'>
                          {t('OTP_CONFIRM_PASSWORD')}
                          <span className='text-danger'>*</span>
                        </label>
                        {icon2 ? (
                          <span
                            className='password_view'
                            style={{
                              position: 'absolute',
                              top: '18px',
                              right: '20px',
                              cursor: 'pointer'
                            }}
                            onClick={() => setIcon2(!icon2)}
                          >
                            <AiFillEyeInvisible />
                          </span>
                        ) : (
                          <span
                            className='password_view'
                            onClick={() => setIcon2(!icon2)}
                            style={{
                              position: 'absolute',
                              top: '18px',
                              right: '20px',
                              cursor: 'pointer'
                            }}
                          >
                            <AiFillEye />
                          </span>
                        )}
                        <ErrorMessage
                          message={errors?.confirm_password?.message}
                        />
                      </Form.Floating>
                    </Form.Group>
                  </Col>

                  <Col sm={12}>
                    <Button
                      className='btn btn_theme w-100 py-3 mb-3'
                      type='submit'
                    >
                      {t('O_SUBMIT')}
                    </Button>
                  </Col>
                  <Col sm={12} className='text-center mb-2 mt-4'>
                    {t('O_BACK_TO')}
                    <Link to='/register' className='link'>
                      {' '}
                      {t('O_LOGIN')}
                    </Link>
                  </Col>
                </Row>
              </form>
            </Col>
            <Col
              lg={6}
              className='login_right d-none text-center bg-light p-4 d-lg-flex align-items-center justify-content-center'
            >
              <img src='./images/register_img.png' alt='' />
            </Col>
          </Row>
        </div>

        <Footer />
      </div>
    </>
  )
}

export default OtpScreen
