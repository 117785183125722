import React, { useState, useEffect } from 'react'
import { Row, Table } from 'react-bootstrap'
import Footer from '../components/Footer'
import { apiGet } from '../utils/apiFetch'
import { startCase } from 'lodash'
import pathObj from '../utils/apiPath'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import Pagination from 'components/reusable/Pagination'

function Invoice () {
  const { t } = useTranslation()
  const [invoiceData, setInvoiceData] = useState()
  const [page, setPage] = useState(1)

  const getInvoice = async () => {
    try {
      const path = `${pathObj.getInvoice}?page=${page}`
      const result = await apiGet(path)
      setInvoiceData(result.data)
    } catch (error) {
      console.log('getBalance==>', error)
    }
  }

  useEffect(() => {
    getInvoice()
  }, [page])

  const pageCount = Math.ceil(invoiceData?.results?.totalPages)
  const docsLength = invoiceData?.results?.docs.length
  const handlePageClick = async event => {
    setPage(event + 1)
  }

  return (
    <>
      <div className='main_wrap px-0 wallet_statement'>
        <div className='m-0 py-4 sidebar_pageHeading fullPage_space'>
          <h5 className='mb-0 text-white'>
            <img src='./images/invoice.png' alt='' className='me-2' />{' '}
            {t('NAV_INVOICE')}
          </h5>
        </div>

        <div className='bg-light-gray main_panel rounded-0 p-3 p-md-4'>
          <div className='bg-white common-table td-border invoice-table'>
            <Table striped bordered className='mb-0'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t('INVOICE_TRANSACTION_ID')}</th>
                  <th>{t('O_MOBILE_NUMBER')} (+355)</th>
                  <th>{t('INVOICE_CREATED_DATE')}</th>
                  <th>
                    {t('O_AMOUNT')} ({t('O_LEK')})
                  </th>
                  <th>{t('INVOICE_STATUS')}</th>
                  <th>{t('INVOICE_DOWNLOAD')}</th>
                </tr>
              </thead>
              <tbody>
                {invoiceData?.results?.docs?.map((item, key) => {
                  return (
                    <tr>
                      <td width='5%'>{key + 1}</td>
                      <td>{item?.transactionId}</td>
                      <td className='text_right'>{item?.mobile}</td>
                      <td className='text_right'>
                        {dayjs(item?.createdAt).format('DD/MM/YYYY HH:MM A')}
                      </td>
                      <td className='text_right'>{item.amount}</td>
                      <td className='text_center'>{startCase(item?.status)}</td>
                      <td className='text_center'>
                        <a href={item?.invoice} target='_blank'>
                          {t('INVOICE_DOWNLOAD')}
                        </a>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            <Row className='gx-2'>
              <Pagination
                recordFound
                pageCount={pageCount}
                docsLength={docsLength}
                page={page}
                handlePageClick={handlePageClick}
              />
            </Row>
          </div>
        </div>
        <div className='fullPage_space'>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Invoice
