import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  Alert,
  Modal,
  Form,
  Col,
  Row,
  Button,
  Breadcrumb
} from 'react-bootstrap'
import Footer from '../components/Footer'
import { useForm } from 'react-hook-form'
import ErrorMessage from 'components/reusable/ErrorMessage'
import { apiPost } from 'utils/apiFetch'
import pathObj from 'utils/apiPath'
import useToastContext from 'hooks/useToastContext'
import dayjs from 'dayjs'
import { preventMax } from 'utils/reusableMethods'
import { useTranslation } from 'react-i18next'
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

function LoanReqType () {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [data, setData] = useState(null)
  const [amount, setAmount] = useState(null)
  const [title, setTitle] = useState('')
  const [titleError, setTitleError] = useState('')
  const [tenure, setTenure] = useState('')
  const [amountError, setAmountError] = useState('')
  const [amountErrorValue, setAmountErrorValue] = useState(false)
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })
  const notification = useToastContext()
  const location = useLocation()
  const handleClose = () => setShow(false)
  const handleTenure = e => {
    setShowPreview(false)
    setTenure(e.target.value)
  }

  const checkFields = () => {
    let isValid = true
    if (!title) {
      setTitleError(true)
      isValid = false
    } else {
      setTitleError(false)
    }
    if (!amount) {
      setAmountError(true)
      isValid = false
    } else {
      setAmountError(false)
    }
    return isValid
  }

  const onSubmit = async data => {
    try {
      const isValid = checkFields()
      if (!isValid) return
      if (data.tenure == 'Choose Tenure*') {
        setError('tenure', { type: 'required' }, { shouldFocus: true })
        return
      }
      const result = await apiPost(pathObj.previewLoan, {
        loanAmount: amount,
        tenure: data.tenure,
        loanCreditTitle: title
      })
      if (result?.data?.success === true) {
        setData(result?.data?.results)
        setShowPreview(true)
      } else {
        notification.error(result?.data?.message)
      }
    } catch (error) {}
  }

  const SubmitData = async () => {
    try {
      const formData = new FormData()
      formData.append('BankVerificationId', location?.state?.id)
      formData.append('monthlySalary', location?.state?.monthlySalary)

      if (amount) {
        formData.append('loanAmount', amount)
      }
      if (title) {
        formData.append('loanCreditTitle', title)
      }

      formData.append('tenure', tenure)
      formData.append('frontIdImage', location?.state?.front[0])
      formData.append('backIdImage', location?.state?.back[0])
      formData.append('salaryReceipt', location?.state?.salaryReceipt[0])

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      const result = await apiPost(
        pathObj.submitLoanRequirement,
        formData,
        config
      )
      if (result?.data?.success === true) {
        notification.success(result?.data?.message)
        navigate('/dashboard')
      } else {
        notification.error(result?.data?.message)
      }
    } catch (error) {}
  }

  const preventSpacePass = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }
  return (
    <>
      <div className='main_wrap'>
        <Breadcrumb className='m-0 py-3'>
          <Breadcrumb.Item href='#'>{t('LOGIN_HOME')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t('MICRO_CREDIT_REQUEST')}</Breadcrumb.Item>
        </Breadcrumb>

        <div className='main_panel p-0 overflow-hidden request_type'>
          <Row className='g-0 align-items-center h-100'>
            <Col md={6}>
              <Form
                className='personal_info_form'
                onSubmit={handleSubmit(onSubmit, () => {
                  const isValid = checkFields()
                  if (!isValid) return
                })}
              >
                <div className='get_loan d-sm-flex d-block align-items-center pb-4 border-bottom mb-4'>
                  <h4 className='link mb-0 me-4'>
                    {t('CONGRATULATIONS')}
                    <span className='text-dark d-block pb-sm-0 pb-3'>
                      {t('THE_MAXIMUM_APPROVED_LOAN_AMOUNT')}
                    </span>
                  </h4>
                  <h6 className='mb-0 ms-auto'>
                    {t('O_LEK')} {location.state.loanAmount}
                  </h6>
                </div>

                <Row>
                  <Col sm={12}>
                    <Form.Group className='mb-3'>
                      <Form.Label htmlFor='floatingloanamnt'>
                        {t('loanAmount')}
                        <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        id='floatingloanamnt'
                        type='number'
                        placeholder={t('loanAmount')}
                        onInput={e => preventMax(e, 10)}
                        value={amount}
                        onChange={e => {
                          setAmount(e.target.value)
                          if (e) {
                            setAmount(e.target.value)
                            if (e.target.value == 0) {
                              setAmountError(true)
                              setAmountErrorValue(true)
                            } else {
                              setAmountError(false)
                              setAmountErrorValue(false)
                            }
                          }
                        }}
                        onKeyDown={e => {
                          if (['-', '+', '.', 'e'].includes(e.key)) {
                            e.preventDefault()
                          }
                        }}
                      />
                      {amountError && (
                        <ErrorMessage
                          message={
                            amountErrorValue
                              ? t('VALUE_CAN_NOT_BE_LESS_THAN_1')
                              : t('PLEASE_ENTER_LOAN_AMOUNT_FIELD')
                          }
                        />
                      )}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                      <Form.Label htmlFor='floatingLoanTitle'>
                        {t('LOAN_CREDIT_TITLE')}
                        <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        id='floatingLoanTitle'
                        type='text'
                        placeholder={t('LOAN_CREDIT_TITLE')}
                        maxLength={15}
                        value={title}
                        onInput={preventSpacePass}
                        onChange={e => {
                          setTitle(e.target.value)
                          if (e) {
                            setTitleError(false)
                          }
                        }}
                        onKeyDown={e => {
                          if (['-', '+', '.'].includes(e.key)) {
                            e.preventDefault()
                          }
                        }}
                      />
                      {titleError && (
                        <ErrorMessage
                          message={t('PLEASE_ENTER_GIFT_LOAN_CREDIT_TITLE')}
                        />
                      )}
                    </Form.Group>
                    <Form.Group className='mb-3 '>
                      <Form.Label controlId='floatingSelectGrid' label=''>
                        {t('CHOOSE_TENURE')}
                        <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Select
                        aria-label='example'
                        name='tenure'
                        {...register('tenure', {
                          required: true
                        })}
                        onChange={e => handleTenure(e)}
                      >
                        <option>{t('CHOOSE_TENURE')}</option>
                        <option value='90'>{t('90_DAYS')}</option>
                        <option value='180'>{t('180_DAYS')}</option>
                        <option value='365'>{t('365_DAYS')}</option>
                      </Form.Select>

                      {errors.tenure?.type === 'required' && (
                        <small className='form-text text-danger help-block'>
                          {t('PLEASE_SELECT_TENURE')}
                        </small>
                      )}
                    </Form.Group>
                    <Form.Group className='mb-4'>
                      <span class='custom_check'>
                        {t('ACCEPT')}
                        <input
                          type='checkbox'
                          {...register('check', {
                            required: t('PLEASE_ACCEPT_TERMS_AND_CONDITIONS')
                          })}
                        />
                        <span class='check_indicator'>&nbsp;</span>
                      </span>
                      <Link
                        to='/terms-and-conditions'
                        target='_blank'
                        className='link'
                      >
                        {' '}
                        {t('TERMS_AND_CONDITIONS')}
                      </Link>
                      <ErrorMessage message={errors?.check?.message} />
                    </Form.Group>
                  </Col>

                  <Col sm={12}>
                    <Button
                      className='btn btn_theme w-100 py-3 mb-3'
                      type='submit'
                    >
                      {t('CHECK_SUMMARY')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
            {showPreview ? (
              <Col md={6} className='get_loan bg-light p-4'>
                <h5>{t('INSTRUCTIONS_AND_SUMMARY')}</h5>
                <p>{t('BELOW_ARE_THE_DETAILS_AND_FOR_THE_LOAN_PROCESS')}</p>
                <ol className='mb-4 mb-sm-5'>
                  <li className='mb-2'>
                    {t('THERE_WILL_A_ONE_TIME_PROCESSING_FEE_OF')} {t('O_LEK')}{' '}
                    {data?.processingFees?.toFixed(2)}.
                  </li>
                  <li className='mb-2'>
                    {t('THE_FINAL_LOAN_AMOUNT_WILL_BE')} {t('O_LEK')}{' '}
                    {data?.finalLoanAmount?.toFixed(2)}.
                  </li>
                  <li className='mb-2'>
                    {t('LOAN_TENURE')} : {data.tenure} {t('DAYS')}.
                  </li>
                  <li className='mb-2'>
                    {t('INTEREST_RATE')} : {data.interestRate}%
                  </li>
                  <li className='mb-2'>
                    {t('REPAYABLE_AMOUNT')} : {t('O_LEK')}{' '}
                    {data?.repayableAmount?.toFixed(2)}
                  </li>
                  <li className='mb-2'>
                    {t('INSTALLMENT')} :
                    <ul className='list-unstyled mt-2'>
                      {data?.installments?.map((item, i) => (
                        <li className='mb-2'>
                          {i + 1}.) {t(i + 1)} {t('INSTALLMENT_WILL_BE_OF')}{' '}
                          {t('O_LEK')} {item?.installment?.toFixed(2)}{' '}
                          {t('AND_DUE_DATE_IS')} :{' '}
                          {dayjs(item?.dueDate).format('ll')}
                        </li>
                      ))}
                    </ul>
                  </li>
                </ol>
                <Alert className='alert alert-warning'>
                  <span className='d-block fw-bold'>{t('NOTE')} :</span>
                  {t('PENALTY_WILL_BE_CHARGED')}
                </Alert>
                <Col
                  sm={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Col sm={3}>
                    <Button
                      className='btn btn_theme w-100 py-3 mb-3'
                      type='submit'
                      onClick={() => SubmitData()}
                    >
                      {t('SUBMIT')}
                    </Button>
                  </Col>
                </Col>
              </Col>
            ) : (
              ''
            )}
          </Row>
        </div>

        <Footer />
        <Modal
          show={show}
          onHide={handleClose}
          centered
          className='verify_modal track_modal'
        >
          <Modal.Body className='text-center p-5'>
            <img src='./images/check.png' alt='' className='mb-4 opacity-1' />
            <p className='text-center'>{t('YOUR_REQUEST_IS_UNDER_PROCESS')} </p>
            <p>{t('YOU_CAN_TRACK_THE_REQUEST')}</p>

            <Link to='/dashboard' className='btn btn_theme '>
              {t('TRACK')}
            </Link>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default LoanReqType
