import { Outlet } from 'react-router-dom'
import MainLayout from '../layout/MainLayout'
const PublicRoute = ({ children, ...rest }) => {
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  )
}

export default PublicRoute
