import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { apiGet } from '../utils/apiFetch'
import pathObj from '../utils/apiPath'
import { Tabs, Tab, Row, Col, Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Footer from '../components/Footer'
import Pagination from 'components/reusable/Pagination'
import { startCase } from 'lodash'
const dayjs = require('dayjs')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

function Wallet () {
  const { t } = useTranslation()
  const [balance, setBalance] = useState()
  const [errorMessage] = useState('')
  const [show, setShow] = useState(false)
  const [page, setPage] = useState(1)
  const [resultMessage, setResultMessage] = useState('Data Fatching...')

  const handleClose = () => setShow(false)
  const url = resultMessage?.success
    ? './images/check.png'
    : './images/wrong.png'

  const myWallet = async () => {
    try {
      const path = `${pathObj.walletStatement}?page=${page}`
      const result = await apiGet(path)
      setBalance(result?.data?.results)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    myWallet()
  }, [page])

  const pageCount = Math.ceil(balance?.totalPages)
  const docsLength = balance?.docs.length
  const handlePageClick = async event => {
    setPage(event + 1)
  }

  const StatementTitle = ({ data }) => {
    if (data.type === 'credit') {
      if (data?.transaction_type === 'add_money_to_wallet') {
        return t('ADD_MONEY_TO')
      } else if (data?.transaction_type === 'gift_card') {
        return t('GIFT_CARD_REDEEMED_TO')
      } else if (data?.transaction_type === 'referral_bonus') {
        return t('REFERRAL_BONUS_ADDED_TO')
      } else if (data?.transaction_type === 'sign_in_bonus') {
        return t('ADD_MONEY_TO')
      } else {
        return t('RECEIVED_MONEY_FROM')
      }
    } else {
      if (data?.transaction_type === 'gift_card') {
        return t('GIFT_CARD_SENT')
      } else {
        return t('PAID_MONEY_TO')
      }
    }
  }
  const StatementName = ({ data }) => {
    if (data.type === 'credit') {
      if (data?.transaction_type === 'add_money_to_wallet') {
        return t('Wallet')
      } else if (data?.transaction_type === 'gift_card') {
        return t('Wallet')
      } else if (data?.transaction_type === 'referral_bonus') {
        return t('Wallet')
      } else if (data?.transaction_type === 'sign_in_bonus') {
        return t('Wallet')
      } else if (data?.transaction_type === 'loyaltyPoint') {
        return t('Loyalty points')
      } else {
        return `${data?.receiver_data?.[0]?.first_name}  ${data?.receiver_data?.[0]?.last_name}`
      }
    } else {
      if (data?.transaction_type === 'gift_card') {
        return t('Wallet')
      } else {
        return `${data?.sender_data?.[0]?.first_name}  ${data?.sender_data?.[0]?.last_name}`
      }
    }
  }

  const TabData = () => {
    return balance?.docs?.map((val, ind) => {
      return (
        <div className='paid_money mb-2 p-2 rounded-3' key={ind}>
          <div className='d-flex align-items-center justify-content-between flex-wrap'>
            <div className='dps_fix_three'>
              <div
                className='d-flex align-items-center'
                style={{ width: '250px' }}
              >
                <img src='./images/green-left.png' alt='' className='me-2' />
                <div style={{ width: '20rem' }}>
                  <h4>
                    <StatementTitle data={val} />
                  </h4>
                  <h5 className='fw-normal'>
                    <StatementName data={val} />
                    <span className='d-block'>{t(val?.transaction_type)}</span>
                    <span className='d-block'>{startCase(val?.message)}</span>
                  </h5>
                </div>
              </div>
            </div>
            <div className='dps_fix_three'>
              <h5 className='fw-normal'>
                {dayjs(val.createdAt).format('DD-MM-YYYY hh:mm A')}
              </h5>
            </div>
            <div className='flex-end' style={{ width: '6rem' }}>
              <h6 className='p-0'>
                {t('O_LEK')} {val?.transaction_amount?.toFixed(2)}{' '}
                <span className='d-block text-success p-0 fw-normal failed_text'>
                  {t('O_SUCCESS')}
                </span>
              </h6>
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <div className='main_wrap px-0 wallet_statement'>
        <div className='m-0 py-4 sidebar_pageHeading fullPage_space'>
          <h5 className='mb-0 text-white'>
            <img src='./images/sidebar_icon3.svg' alt='' className='me-2' />{' '}
            {t('O_MY_WALLET')}
          </h5>
        </div>
        <div className='bg-light-gray main_panel rounded-0 p-3 p-md-4'>
          <div className='rounded-0 overflow-hidden bg-white  p-3 p-md-4'>
            <Row>
              <Col lg={4}>
                <div className='avail pb-4 mb-lg-2 mb-4'>
                  <h5 className='text-white mb-3'>
                    {t('MY_WALLET_BALANCE')}
                    <span className='d-block'>
                      {t('O_LEK')} {balance?.wallet_amount}
                    </span>
                  </h5>
                  <Button
                    type='btn'
                    className='id_btn text-white bg-dark fw-bold px-4 py-2'
                  >
                    ID - XYV458722221
                  </Button>
                </div>
              </Col>

              <Col lg={4}>
                <div className='mb-lg-2 mb-4 wallet_amount text-lg-start text-center'>
                  <Link
                    className='btn btn_theme ms-xl-3 ms-sm-2 mt-xl-3 mt-sm-2'
                    to={'/add-money'}
                  >
                    {t('MY_WALLET_ADD_MONEY')}
                  </Link>
                  {errorMessage?.amount?.length > 0 ? (
                    <span className='text-danger'>{errorMessage?.amount}</span>
                  ) : null}
                </div>
              </Col>
            </Row>

            <div className='recent_order mt-4'>
              <h3>{t('MY_WALLET_STATEMENT')} </h3>
              <Tabs
                defaultActiveKey='all'
                id='uncontrolled-tab-example'
                className='mb-3 border-0'
              >
                <Tab eventKey='all' title={t('O_ALL')}>
                  <TabData />
                  <Pagination
                    pageCount={pageCount}
                    docsLength={docsLength}
                    page={page}
                    handlePageClick={handlePageClick}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        <div className='fullPage_space'>
          <Footer />
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        className='verify_modal track_modal'
      >
        <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center p-5'>
          <img src={url} alt='' className='mb-4 opacity-1' />
          <p className='text-center'>
            {resultMessage?.message ? resultMessage?.message : null}
          </p>

          <Button
            onClick={() => {
              setShow(!show)
            }}
            className='btn btn_theme mb-2'
          >
            {t('O_CLOSE')}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Wallet
