import React, { useContext, useState, useEffect } from 'react'
import AuthContext from 'context/AuthContext'
import { useLocation } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import pathObj from '../utils/apiPath'
import { apiGet } from '../utils/apiFetch'
import parse from 'html-react-parser'
import HomeFooter from 'components/HomeFooter'
import HomeHeader from 'components/HomeHeader'

export default function PrivatePolicy () {
  let { language } = useContext(AuthContext)
  const [slug, setSlug] = useState()
  const [slugContent, setSlugContent] = useState()
  const location = useLocation().pathname.slice(
    1,
    useLocation().pathname.length
  )

  const staticPage = async () => {
    const path = pathObj.staticContent
    const pathContent = pathObj.staticContentSlug
    const result = await apiGet(path)
    const resultContent = await apiGet(pathContent + '=' + location)
    setSlugContent(resultContent?.data?.results?.content)
    result?.data?.results?.map((val, ind) => {
      if (val.slug == location) {
        console.log('val', val)
        setSlug(val || 0)
      }
    })
  }
  useEffect(() => {
    staticPage()
  }, [language])

  return (
    <>
      <HomeHeader />

      <section className='banner-part'>
        <Container className='p-10'>
          {slugContent ? parse(slugContent) : null}
        </Container>
      </section>

      <HomeFooter />
    </>
  )
}
