import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import Footer from 'components/Footer'
import { apiGet } from 'utils/apiFetch'
import pathObj from 'utils/apiPath'
import dayjs from 'dayjs'
import ReactPaginate from 'react-paginate'
import { IoMdNotifications } from 'react-icons/io'
import { useTranslation } from 'react-i18next'

function Notification () {
  const { t } = useTranslation()
  const [notifications, setNotifications] = useState([])
  const [page, setPage] = useState(1)

  const pageSize = 10

  const getNotifications = async () => {
    try {
      const params = {
        page_size: pageSize,
        page
      }
      const res = await apiGet(`${pathObj.getNotifications}`, params)
      setNotifications(res?.data?.results)
    } catch (error) {
      console.log('error:', error?.response?.data?.message)
    }
  }

  useEffect(() => {
    getNotifications()
  }, [page])

  const pageCount = Math.ceil(notifications?.totalPages)
  const handlePageClick = async event => {
    setPage(event.selected + 1)
  }

  const Pagination = () => {
    if (notifications?.docs?.length > 0) {
      return (
        <div id='react-paginate'>
          <ReactPaginate
            previousLabel={t('O_PREVIOUS')}
            nextLabel={t('O_NEXT')}
            breakLabel='...'
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName='pagination'
            subContainerClassName='pages pagination'
            activeClassName='active'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='page-item'
            previousLinkClassName='page-link'
            nextClassName='page-item'
            nextLinkClassName='page-link'
            forcePage={page - 1}
          />
        </div>
      )
    } else {
      return (
        <Col md={6}>
          <div className='promo_card align-items-center'>
            <figcaption className='d-sm-flex p-2 align-items-center'>
              <div>
                <h4 className='mb-0'>{t('O_NO_RECORD_FOUND')}</h4>
              </div>
            </figcaption>
          </div>
        </Col>
      )
    }
  }

  return (
    <>
      <div className='main_wrap px-0 refer_earn'>
        <div className='m-0 py-4 sidebar_pageHeading fullPage_space'>
          <h5 className='mb-0 text-white'>
            <IoMdNotifications
              color='white'
              className='me-2'
              style={{ fontSize: '1.5rem' }}
            />
            {t('NOTIFICATIONS')}
          </h5>
        </div>
        <div className='bg-light main_panel rounded-0 p-3 p-md-4 myOrder'>
          <div className='rounded-0 overflow-hidden bg-white  p-3 p-md-4'>
            <Row>
              {notifications?.docs?.map((item, ind) => {
                return (
                  <Col md={6} key={ind}>
                    <div className='promo_card align-items-center'>
                      <figcaption className='d-sm-flex p-2 align-items-center'>
                        <div>
                          <h4 className='mb-0'>{item?.title} </h4>
                          <p>{item?.body}</p>
                          <div className='promo_code' />
                        </div>
                        <div className='ms-auto text-sm-end'>
                          <span className='expire_date d-block mb-2'>
                            {dayjs(item?.createdAt).format(
                              'DD-MM-YYYY hh:mm A'
                            )}
                          </span>
                        </div>
                      </figcaption>
                    </div>
                  </Col>
                )
              })}
            </Row>
            <Pagination />
          </div>
        </div>

        <div className='fullPage_space'>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Notification
