import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Form, Col, Row, Button } from 'react-bootstrap'
import Footer from '../../components/Footer'
import { useForm } from 'react-hook-form'
import ErrorMessage from 'components/reusable/ErrorMessage'
import useToastContext from 'hooks/useToastContext'
import pathObj from 'utils/apiPath'
import { apiPost } from 'utils/apiFetch'
import { preventMax } from 'utils/reusableMethods'
import { validationRegex } from 'utils/constants'
import { integerInput } from 'utils/InputControl'
import {Helmet} from "react-helmet";


function ForgotPassword () {
  const { t } = useTranslation()
  const notification = useToastContext()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })
  const onSubmit = async data => {
    let res
    try {
      let sendData = {}
      let input = data?.email || data?.phone

      if (input === data?.phone) {
        sendData.type = 'mobile'
        sendData.country_code = '355'
        sendData.mobile = input
      } else {
        sendData.type = 'email'
        sendData.email = input
      }
      res = await apiPost(pathObj.forgotPassword, sendData)
      if (res.data.success) {
        notification.success(res.data.message)
        reset()
        navigate('/otp', {
          state: {
            type: sendData.type,
            [sendData.type]:
              sendData.type === 'email' ? sendData.email : sendData.mobile,
            country_code: sendData.country_code
          }
        })
      } else {
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.log('err:', err)
      notification.error(res?.data?.message)
    }
  }

  const preventSpacePass = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }
  const [radioValueMobile, setRadioValueMobile] = useState('mobile')
  const codeValue = watch('email') ? watch('email') : ''

  return (
    <>
      <Helmet>
          <title>{t('FORGOT_PASSWORD')}</title>
          <meta name="title" content={t('FORGOT_PASSWORD')} />
          <meta name="description" content={t('SETTINGS_CHANGE_PASSWORD')} />
          <meta property="og:title" content={t('FORGOT_PASSWORD')} />
          <meta property="og:description" content={t('SETTINGS_CHANGE_PASSWORD')} />
          <meta name="author" content="ALPAY" />
      </Helmet>
      <div className='main_wrap'>
        <div className='m-0 py-sm-4 py-2'></div>
        <div className='bg-white forgot_panel p-0 overflow-hidden register main_panel'>
          <Row className='g-0 '>
            <Col lg={6}>
              <form onSubmit={handleSubmit(onSubmit)} className='forget-form'>
                <Row className='gx-sm-4 gx-2'>
                  <Col sm={12}>
                    <h4 className='mb-3 mb-sm-5 text-center'>
                      {t('FORGOT_PASSWORD')}
                    </h4>
                  </Col>
                  <Col sm={12} className='mb-2'>
                    <div className='d-flex justify-content-center mb-3'>
                      <h5 className='d-sm-block d-none'>
                        {t('FORGOT_PASSWORD_RESET')}:
                      </h5>
                      {['radio'].map(type => (
                        <div
                          key={`inline-${type}`}
                          className='d-flex align-items-center ps-3'
                        >
                          <Form.Check
                            inline
                            label={t('REGISTER_MOBILE_LABEL')}
                            name='group1'
                            value='mobile'
                            className='d-flex align-items-center'
                            type={type}
                            id={`inline-${type}-Mobile`}
                            defaultChecked
                            onChange={e => setRadioValueMobile(e.target.value)}
                          />
                          <Form.Check
                            inline
                            label={t('REGISTER_EMAIL_LABEL')}
                            name='group1'
                            value='email'
                            className='d-flex align-items-center'
                            type={type}
                            id={`inline-${type}-Email`}
                            onChange={e => setRadioValueMobile(e.target.value)}
                          />
                        </div>
                      ))}
                    </div>
                  </Col>
                  {radioValueMobile === 'email' && (
                    <Col sm={12}>
                      <Form.Group className='mb-3'>
                        <Form.Floating className='mb-3'>
                          <Form.Control
                            id='floatingInputCustom2'
                            type='text'
                            maxLength={50}
                            onInput={preventSpacePass}
                            value={codeValue.toLowerCase()}
                            placeholder='name@example.com'
                            {...register('email', {
                              required: t('REGISTER_EMAIL'),
                              pattern: {
                                value: validationRegex.email,
                                message: t('REGISTER_EMAIL_EX')
                              }
                            })}
                          />
                          <label htmlFor='floatingInputCustom'>
                            {t('O_EMAIL')}
                            <span className='text-danger'>*</span>
                          </label>
                          <ErrorMessage message={errors?.email?.message} />
                        </Form.Floating>
                      </Form.Group>
                    </Col>
                  )}
                  {radioValueMobile === 'mobile' && (
                    <>
                      <Col sm={3} className='col-3'>
                        <Form.Select
                          aria-label='Default select example'
                          {...register('country_code', {})}
                          disabled
                        >
                          <option value='355'>+355</option>
                        </Form.Select>
                      </Col>
                      <Col sm={9} className='col-9'>
                        <Form.Group className='mb-3'>
                          <Form.Floating className='mb-3'>
                            <Form.Control
                              id='floatingInputCustom2'
                              type='number'
                              onKeyPress={e => integerInput(e)}
                              onInput={e => preventMax(e, 9)}
                              placeholder='name@example.com'
                              {...register('phone', {
                                required: t('REGISTER_MOBILE'),
                                min: {
                                  value: 0,
                                  message: t('REGISTER_MIN_VALUE')
                                },
                                minLength: {
                                  value: 9,
                                  message: t('REGISTER_MIN_LENGTH_MOBILE')
                                }
                              })}
                            />
                            <label htmlFor='floatingInputCustom2'>
                              {t('O_MOBILE_NUMBER')}
                              <span className='text-danger'>*</span>
                            </label>

                            <ErrorMessage message={errors?.phone?.message} />
                          </Form.Floating>
                        </Form.Group>
                      </Col>
                    </>
                  )}
                  <Col sm={12} className='mt-3'>
                    <Button
                      className='btn btn_theme w-100 py-3 mb-3'
                      type='submit'
                    >
                      {t('O_SUBMIT')}
                    </Button>
                  </Col>
                  <Col sm={12} className='text-center mb-2 mt-3'>
                    {t('LOGIN_BACK_TO')}
                    <Link to='/login' className='link'>
                      {' '}
                      {t('O_LOGIN_TAB')}
                    </Link>
                  </Col>
                </Row>
              </form>
            </Col>
            <Col
              lg={6}
              className='login_right d-none text-center bg-light p-4 d-lg-flex align-items-center justify-content-center'
            >
              <img src='./images/register_img.png' alt='' />
            </Col>
          </Row>
        </div>

        <Footer />
      </div>
    </>
  )
}

export default ForgotPassword
