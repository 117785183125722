import React from 'react'
import ReactPaginate from 'react-paginate'
import { useTranslation } from 'react-i18next'

const Pagination = ({
  handlePageClick,
  pageCount,
  page,
  docsLength = 0,
  recordFound
}) => {
  const { t } = useTranslation()
  const handlePage = event => {
    handlePageClick(event.selected)
  }

  return (
    <div
      className={
        recordFound
          ? 'md:flex md:justify-end p-4 text-center'
          : 'md:flex md:justify-end'
      }
    >
      {docsLength > 0 ? (
        <ReactPaginate
          previousLabel={t('O_PREVIOUS')}
          nextLabel={t('O_NEXT')}
          breakLabel='...'
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePage}
          containerClassName='pagination'
          subContainerClassName='pages pagination'
          activeClassName='active'
          breakClassName='page-item'
          breakLinkClassName='page-link'
          pageClassName='page-item'
          pageLinkClassName='page-link'
          previousClassName='page-item'
          previousLinkClassName='page-link'
          nextClassName='page-item'
          nextLinkClassName='page-link'
          forcePage={page - 1}
        />
      ) : (
        t('O_NO_RECORD_FOUND')
      )}
    </div>
  )
}

export default Pagination
