import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import Footer from '../components/Footer'
import { startCase } from 'lodash'
import { apiGet } from '../utils/apiFetch'
import pathObj from '../utils/apiPath'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import Pagination from 'components/reusable/Pagination'
import classNames from 'classnames'

function MyOrder () {
  const { t } = useTranslation()
  const [orderData, setOrderData] = useState()
  const [page, setPage] = useState(1)

  const [active_tab, setActiveTab] = useState('dealPayments')

  const handleTabClick = (tab, e) => {
    setPage(1)
    setActiveTab(tab)
  }
  const myOrder = async () => {
    try {
      const path = `${pathObj.transaction}?page=${page}&orderType=${active_tab}`
      const result = await apiGet(path)
      setOrderData(result.data)
    } catch (error) {
      console.log('getBalance==>', error)
    }
  }

  const pageCount = Math.ceil(orderData?.results?.totalPages)
  const docsLength = orderData?.results?.docs.length
  const handlePageClick = async event => {
    setPage(event + 1)
  }

  useEffect(() => {
    myOrder()
  }, [page, active_tab])

  return (
    <>
      <div className='main_wrap px-0'>
        <div className='m-0 py-4 sidebar_pageHeading fullPage_space'>
          <h5 className='mb-0 text-white'>
            <img
              src='./images/transactions_white.png'
              alt=''
              className='me-2'
            />{' '}
            {t('O_MY_ORDERS')}
          </h5>
        </div>
        <div className='bg-light main_panel rounded-0 p-3 p-md-4 myOrder'>
          <div className='bg-white p-3 p-md-4'>
            <div className='order_list d-flex flex-wrap align-items-start mb-4 mb-md-5'>
              <button
                onClick={handleTabClick.bind(this, 'NFC')}
                className={classNames(
                  'bg-white d-flex align-items-center border rounded-3 p-2 pe-3 me-3',
                  {
                    active: active_tab === 'NFC'
                  }
                )}
              >
                <span className='me-4'>
                  <img src='./images/order1.png' className='me-2' alt='' />{' '}
                  {t('NFC_PAYMENT')}
                </span>
                <img
                  src='./images/angle_right.png'
                  alt=''
                  className='ms-auto'
                />
              </button>

              <button
                onClick={handleTabClick.bind(this, 'dealPayments')}
                className={classNames(
                  'bg-white d-flex align-items-center border rounded-3 p-2 pe-3 me-3',
                  {
                    active: active_tab === 'dealPayments'
                  }
                )}
              >
                <span className='me-4'>
                  <img src='./images/order4.png' className='me-2' alt='' />{' '}
                  {t('DEAL_PAYMENTS')}
                </span>
                <img
                  src='./images/angle_right.png'
                  alt=''
                  className='ms-auto'
                />
              </button>
            </div>

            <div className='recent_order'>
              <h5 className='text-dark mb-3 pb-3 border-bottom'>
                {t('RECENT_ORDERS')}
              </h5>

              <Row className='gx-2'>
                {orderData?.results?.docs?.map((val, ind) => {
                  let color = 'red'
                  if (val.status == 'completed') {
                    color = '#33e30b'
                  } else if (val?.status == 'pending') {
                    color = 'orange'
                  }
                  return (
                    <Col md={4}>
                      <div className='me-3 mb-3'>
                        <div className='border p-2 pe-3 d-flex align-items-start rounded-4'>
                          <div className='d-flex align-items-center pe-3 pe-sm-4 pe-md-5'>
                            <img
                              src='./images/order_dummy.png'
                              className='me-2'
                              alt=''
                            />
                            <p className='m-0 text-dark text-sm'>
                              <b className='d-block fw-bold'>
                                {startCase(val?.transaction_type)}
                              </b>{' '}
                              {startCase(val?.payment_mode)} <br /> {t('O_LEK')}{' '}
                              {val?.transaction_amount}{' '}
                            </p>
                          </div>
                          <h6 className='ms-auto text-center text-sm'>
                            {dayjs(val?.createdAt).format('DD/MM/YYYY')}
                            <span
                              className='rounded-3 alert  p-2 fw-normal border-0 d-table mb-0 mx-auto mt-1'
                              style={{ background: color }}
                            >
                              {startCase(val?.status)}
                            </span>{' '}
                          </h6>
                        </div>
                      </div>
                    </Col>
                  )
                })}
              </Row>
              <Pagination
                pageCount={pageCount}
                docsLength={docsLength}
                page={page}
                handlePageClick={handlePageClick}
              />
            </div>
          </div>
        </div>
        <div className='fullPage_space'>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default MyOrder
