export const generateRandomID = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const  containsNumbers = (str) => {
    return /\d/.test(str);
}


export const validateFile = (file) => {
    let file_error_msg = "";
    const supportedTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (supportedTypes.includes(file?.type)) {
    } else {
        file_error_msg = "Only jpeg,jpg and png are supported.";
    }

    if(file?.size > 1000*1000*5){
        file_error_msg = 'Please, upload file size less than 5mb.'
    }

    return file_error_msg
  };

  export const supportedImageTypes = ["image/jpeg", "image/jpg", "image/png"];

  export const preventMax = (e,length=9) => {
    if (e.target.value.length > length) {
      e.target.value = e.target.value.slice(0, length)
    }
  }
