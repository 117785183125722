import ErrorMessage from 'components/reusable/ErrorMessage'
import useToastContext from 'hooks/useToastContext'
import { useEffect, useState } from 'react'
import {
  Row,
  Form,
  Col,
  Button,
  Dropdown,
  Modal,
  Spinner
} from 'react-bootstrap'

import { preventMax } from 'utils/reusableMethods'
import { useForm } from 'react-hook-form'
import { apiPost } from 'utils/apiFetch'
import pathObj from 'utils/apiPath'
import { isEmpty } from 'lodash'
import { integerInput } from 'utils/InputControl'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

function WithdrawToCashOut ({ getBalance, dropDown }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const notification = useToastContext()
  const [show, setShow] = useState(false)
  const [result, setResult] = useState()
  const [countryCode, setCountryCode] = useState('')
  const [mobile, setMobile] = useState('')
  const [amount, setAmount] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const cashOut = async data => {
    try {
      setLoading(true)
      if (!isEmpty(mobile) && !isEmpty(amount)) {
        const path = pathObj.sendOTPTransferMoney
        const sendData = {
          country_code: 355,
          mobile,
          amount,
          message,
          transferType: 'cashOut'
        }
        const result = await apiPost(path, sendData)
        if (result?.data?.success) {
          notification.success(result?.data?.message)
          navigate('/wallet/transfer/otp', { state: sendData })

          setShow(false)
          setCountryCode('')
          setMobile('')
          setAmount('')
          setMessage('')
          resetCashOut()
          getBalance()
        } else {
          notification.error(result?.data?.message)
        }
      }
    } catch (error) {}
    setLoading(false)
  }
  const cashOutPreview = async data => {
    try {
      setCountryCode(data?.country_code || '355')
      setMobile(data?.mobile)
      setAmount(data?.amount)
      setMessage(data?.message)
      const path = pathObj.cashOutPreview
      const sendData = { ...data, countryCode: 355, type: 'mobile' }
      const result = await apiPost(path, sendData)
      if (result?.data?.success) {
        setResult(result?.data?.results)
        setShow(true)
      } else {
        notification.error(result?.data?.message)
      }
    } catch (error) {}
  }

  const handleCloseModal = () => {
    setShow(false)
  }

  const {
    register,
    handleSubmit,
    setValue,
    reset: resetCashOut,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })

  useEffect(() => {
    if (dropDown === 2) {
      resetCashOut()
    }
  }, [dropDown])
  const mobileNumber = value => {
    if (value.length >= 9) {
      setValue('mobile', value.slice(0, 9))
    }
  }
  const preventSpace = e => {
    if (['Enter'].includes(e.key)) {
      e.preventDefault()
    }
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }

  const renderButton = loading ? (
    <Button className='btn_theme' style={{ width: '40%' }}>
      <Spinner animation='border' size='sm' />
    </Button>
  ) : (
    <Button
      className='btn_theme'
      style={{ width: '40%' }}
      onClick={() => cashOut()}
    >
      {t('O_SUBMIT')}
    </Button>
  )

  return (
    <Form onSubmit={handleSubmit(cashOutPreview)} className='mobile-form-sec'>
      <Row>
        <Col md={4}>
          <Row className='gx-2'>
            <Col className='col-3'>
              <Dropdown className='mb-3' placeholder='name@example.com'>
                <Dropdown.Toggle
                  id='dropdown-basic'
                  className='py-3 bg-transparent text-dark fw-normal'
                >
                  +355
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>+355</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col className='col-9'>
              <Form.Group className='mb-3'>
                <Form.Floating>
                  <Form.Control
                    id='floatingInputCustom'
                    type='number'
                    name='mobile'
                    placeholder='Mobile number'
                    autofocus
                    onKeyPress={e => integerInput(e)}
                    onInput={e => preventMax(e, 9)}
                    {...register('mobile', {
                      required: t('REGISTER_MOBILE'),
                      onChange: e => {
                        mobileNumber(e.target.value)
                      },
                      minLength: {
                        value: 7,
                        message: t('REGISTER_MOBILE_CONTAIN_SEVEN')
                      },
                      maxLength: {
                        value: 9,
                        message: t('REGISTER_MIN_LENGTH_MOBILE')
                      }
                    })}
                  />
                  <label htmlFor='floatingInputCustom'>
                    {t('O_MOBILE_NUMBER')}
                    <span className='text-danger'>*</span>
                  </label>
                  <ErrorMessage message={errors?.mobile?.message} />
                </Form.Floating>
              </Form.Group>
            </Col>
          </Row>
        </Col>

        <Col md={4}>
          <Form.Group className='mb-3'>
            <Form.Floating>
              <Form.Control
                id='floatingInputCustom'
                type='number'
                name='amount'
                placeholder='name@example.com'
                onInput={e => preventMax(e, 9)}
                onKeyPress={e => integerInput(e)}
                {...register('amount', {
                  required: t('AMOUNT_FIELD'),
                  min: {
                    value: 10,
                    message: t('MIN_AMOUNT')
                  }
                })}
                aria-invalid={errors.amount ? 'true' : 'false'}
              />
              <label htmlFor='floatingInputCustom'>
                {t('O_AMOUNT')}
                <span className='text-danger'>*</span>
              </label>
              <ErrorMessage message={errors?.amount?.message} />
            </Form.Floating>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className='mb-3'>
            <Form.Floating>
              <Form.Control
                id='floatingInputCustom'
                type='text'
                name='message'
                placeholder='name@example.com'
                maxLength={500}
                onKeyDown={preventSpace}
                {...register('message', {
                  maxLength: {
                    value: 500,
                    message: t('MESSAGE_LENGTH')
                  }
                })}
              />
              <label htmlFor='floatingInputCustom'>
                {t('GIFT_CARD_MESSAGE')}
              </label>
            </Form.Floating>
          </Form.Group>
        </Col>
      </Row>
      <Button
        className='btn btn_theme'
        type='submit'
        onClick={data => cashOutPreview(data)}
      >
        {t('SEND_MONEY')}
      </Button>
      {result ? (
        <Modal
          show={show}
          onHide={handleCloseModal}
          centered
          className='verify_modal track_modal'
        >
          <Modal.Header closeButton>
            <Modal.Title> {t('PREVIEW_CASH_OUT')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='flex items-center w-full justify-center mb-4'>
              <div className='relative text-center '>
                <div className='w-32 h-30 relative mx-auto'>
                  <img src='images/check.png' alt='No_image' />
                </div>
                <div className='relative text-center '>
                  <h3>
                    {t('CUSTOMER_WILL_GET')} {result?.amountToBeReceived}{' '}
                    {t('ON_TRANSFER')} {result?.amount} {t('O_LEK')}.
                  </h3>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-between'>
              {renderButton}

              <Button
                className='btn_theme'
                style={{ width: '40%' }}
                onClick={() => handleCloseModal()}
              >
                {t('O_CANCEL')}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        ''
      )}
    </Form>
  )
}

export default WithdrawToCashOut
