import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row, Offcanvas, Form } from 'react-bootstrap'
import Footer from '../components/Footer'
import { apiGet } from '../utils/apiFetch'
import { startCase } from 'lodash'
import pathObj from '../utils/apiPath'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import Pagination from 'components/reusable/Pagination'

function WalletStatement () {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const [walletData, setWalletData] = useState()
  const [inputData, setInputData] = useState('')
  const [readMore, setReadMore] = useState(false)
  const [readMoreId, setReadMoreId] = useState(null)
  const [filterData, setFilterData] = useState('')
  const [page, setPage] = useState(1)
  const [start_date, setStart_date] = useState('')
  const [end_date, setEnd_date] = useState('')
  const walletStatementData = async clear => {
    try {
      const status = inputData === 'all' ? '' : inputData

      let payload = { page, status, end_date, start_date }
      if (clear) {
        payload = {}
      }
      const path = pathObj.walletStatement
      const result = await apiGet(path, payload)
      setWalletData(result.data)
    } catch (error) {
      console.log('getBalance==>', error)
    }
  }

  const onChange = e => {
    setInputData(e.target.value)
  }
  const filter = () => {
    setPage(1)
    setShow(false)
    setFilterData(inputData)
    walletStatementData()
  }
  const clearData = () => {
    setInputData('')
    setShow(false)
    setPage(1)
    setFilterData('')
    setEnd_date('')
    setStart_date('')
    walletStatementData('clear')
  }

  const handleClose = () => {
    setShow(false)
    walletStatementData()
  }
  const handleShow = () => setShow(true)

  const pageCount = Math.ceil(walletData?.results?.totalPages)
  const docsLength = walletData?.results?.docs.length

  const handlePageClick = async event => {
    setPage(event + 1)
  }

  const StatementTitle = ({ data }) => {
    if (data.type === 'credit') {
      if (data?.transaction_type === 'add_money_to_wallet') {
        return t('ADD_MONEY_TO')
      } else if (data?.transaction_type === 'gift_card') {
        return t('GIFT_CARD_REDEEMED_TO')
      } else if (data?.transaction_type === 'referral_bonus') {
        return t('REFERRAL_BONUS_ADDED_TO')
      } else if (data?.transaction_type === 'sign_in_bonus') {
        return t('ADD_MONEY_TO')
      } else {
        return t('RECEIVED_MONEY_FROM')
      }
    } else {
      if (data?.transaction_type === 'gift_card') {
        return t('GIFT_CARD_SENT')
      } else {
        return t('PAID_MONEY_TO')
      }
    }
  }
  const StatementName = ({ data }) => {
    if (data.type === 'credit') {
      if (data?.transaction_type === 'add_money_to_wallet') {
        return t('Wallet')
      } else if (data?.transaction_type === 'gift_card') {
        return t('Wallet')
      } else if (data?.transaction_type === 'referral_bonus') {
        return t('Wallet')
      } else if (data?.transaction_type === 'sign_in_bonus') {
        return t('Wallet')
      } else if (data?.transaction_type === 'loyaltyPoint') {
        return t('Loyalty points')
      } else {
        return `${data?.receiver_data?.[0]?.first_name}  ${data?.receiver_data?.[0]?.last_name}`
      }
    } else {
      if (data?.transaction_type === 'gift_card') {
        return t('Wallet')
      } else {
        return `${data?.sender_data?.[0]?.first_name}  ${data?.sender_data?.[0]?.last_name}`
      }
    }
  }

  const StatementTemplate = ({ data, ind }) => {
    return (
      <>
        <div className='paid_money mb-2 p-2 rounded-3 w-100'>
          <div className='d-flex align-items-center justify-content-between flex-wrap'>
            <div className='dps_fix_three  dps_first_sec'>
              <div className='d-flex align-items-center'>
                {data?.type === 'debit' ? (
                  <img
                    src='./images/purple-right.png'
                    alt={data?.type}
                    className='me-2'
                    style={{
                      transform: 'rotate(180deg)'
                    }}
                  />
                ) : (
                  <img
                    src='./images/purple-right.png'
                    alt=''
                    className='me-2'
                  />
                )}
                <div>
                  <h4>
                    <StatementTitle data={data} />
                  </h4>
                  <h5 className='fw-normal'>
                    <StatementName data={data} />
                    <span className='d-block'>{t(data?.transaction_type)}</span>
                    <>
                      {readMoreId !== ind && (
                        <span
                          className='d-block tran-cater'
                          title={data?.message}
                        >
                          {data?.message}
                        </span>
                      )}
                      {readMoreId !== ind && data?.message?.length > 37 && (
                        <span
                          onClick={() => {
                            setReadMore(!readMore)
                            setReadMoreId(ind)
                          }}
                          style={{ color: '#6610f2' }}
                        >
                          {t('READ_MORE')}
                        </span>
                      )}
                    </>
                    {readMoreId == ind && (
                      <>
                        {' '}
                        <span className='d-block ' title={data?.message}>
                          {data?.message}
                        </span>
                        <span
                          onClick={() => setReadMoreId(null)}
                          style={{ color: '#6610f2' }}
                        >
                          {t('READ_LESS')}
                        </span>
                      </>
                    )}
                  </h5>
                </div>
              </div>
            </div>
            <div className='dps_fix_three'>
              <h5 className='fw-normal'>
                {dayjs(data?.createdAt).format('DD-MM-YYYY hh:mm A')}
              </h5>
            </div>
            <div className='flex-end dps_third-sec'>
              <h6 className='p-0 text-end mt-2'>
                {t('O_LEK')} {data?.transaction_amount?.toFixed(2)}
                <span className='d-block fw-normal'>
                  <img src='./images/green_tick.svg' alt='' className='me-2' />

                  {startCase(data?.status)}
                </span>
              </h6>
            </div>
          </div>
        </div>
      </>
    )
  }

  useEffect(() => {
    walletStatementData()
  }, [page, filterData])
  return (
    <>
      <div className='main_wrap px-0 wallet_statement'>
        <div className='m-0 py-4 sidebar_pageHeading fullPage_space'>
          <h5 className='mb-0 text-white'>
            <img src='./images/sidebar_icon4.svg' alt='' className='me-2' />{' '}
            {t('O_WALLET_STATEMENT')}
          </h5>
        </div>

        <div className='bg-light-gray main_panel rounded-0 p-3 p-md-4'>
          <div className='rounded-0 overflow-hidden bg-white  p-3 p-md-4'>
            <h3 className='mb-4'>
              {t('O_WALLET_STATEMENT')}{' '}
              <img
                src='./images/filter.svg'
                alt=''
                className='ms-2'
                role='button'
                onClick={handleShow}
                title='Filter'
              />
            </h3>
            <Row className='gx-2'>
              {walletData?.results?.docs?.map((val, ind) => {
                console.log('ind', ind)
                return (
                  <Col md={6} key={ind} className='d-flex'>
                    {' '}
                    <StatementTemplate data={val} key={ind} ind={ind} />{' '}
                  </Col>
                )
              })}
              <Pagination
                pageCount={pageCount}
                docsLength={docsLength}
                page={page}
                handlePageClick={handlePageClick}
              />
            </Row>
          </div>
        </div>
        <div className='fullPage_space'>
          <Footer />
        </div>
      </div>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement='end'
        className='filter-sidebar-sec'
      >
        <Offcanvas.Header closeButton className='border-bottom'>
          <Offcanvas.Title>{t('O_FILTER')}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <div className='mb-4'>
              <span className='custom_check'>
                <Link to='' className='link text-dark fw-normal'>
                  {t('WALLET_STATEMENT_ALL')}
                </Link>
                <input
                  type='radio'
                  value='all'
                  checked={inputData === 'all'}
                  name='myradio'
                  onChange={e => onChange(e)}
                />
                <span className='check_indicator'>&nbsp;</span>
              </span>
            </div>
            <div className='mb-4'>
              <span className='custom_check'>
                <Link to='' className='link text-dark fw-normal'>
                  {t('WALLET_STATEMENT_COMPLETED')}
                </Link>
                <input
                  type='radio'
                  value='completed'
                  checked={inputData === 'completed'}
                  name='myradio'
                  onClick={onChange}
                />
                <span className='check_indicator'>&nbsp;</span>
              </span>
            </div>
            <div className='mb-4'>
              <span className='custom_check'>
                <Link to='' className='link text-dark fw-normal'>
                  {t('WALLET_STATEMENT_PENDING')}
                </Link>
                <input
                  type='radio'
                  value='pending'
                  checked={inputData === 'pending'}
                  name='myradio'
                  onChange={e => onChange(e)}
                />
                <span className='check_indicator'>&nbsp;</span>
              </span>
            </div>

            <div className='mb-4'>
              <span className='custom_check'>
                <Link to='' className='link text-dark fw-normal'>
                  {t('WALLET_STATEMENT_FAILED')}
                </Link>
                <input
                  type='radio'
                  value='failed'
                  checked={inputData === 'failed'}
                  name='myradio'
                  onChange={e => onChange(e)}
                />
                <span className='check_indicator'>&nbsp;</span>
              </span>
            </div>
            <Form>
              <h5>{t('DATE_RANGE')}</h5>
              <Form.Group className='my-3' controlId='formBasicEmail'>
                <Form.Label>{t('GIFT_CARD_FROM')}</Form.Label>
                <Form.Control
                  type='date'
                  placeholder='Enter email'
                  onChange={e => setStart_date(e.target.value)}
                  value={start_date}
                />
              </Form.Group>

              <Form.Group className='my-3' controlId='formBasicEmail'>
                <Form.Label>{t('GIFT_CARD_TO')}</Form.Label>
                <Form.Control
                  type='date'
                  placeholder='Enter email'
                  onChange={e => setEnd_date(e.target.value)}
                  value={end_date}
                  min={start_date}
                />
              </Form.Group>
            </Form>
          </div>
        </Offcanvas.Body>
        <div className='refer_bottom pb-3 text-center'>
          <Link
            to=''
            className='btn-sm btn bg-white text-dark me-3 link px-5 py-2 copy_btn rounded-2 '
            onClick={clearData}
          >
            {t('WALLET_STATEMENT_CLEAR')}
          </Link>
          <Link
            to=''
            className='btn-sm btn  px-5 border py-2 rounded-2 '
            onClick={filter}
          >
            {t('O_FILTER')}
          </Link>
        </div>{' '}
      </Offcanvas>
    </>
  )
}

export default WalletStatement
