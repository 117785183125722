import OImage from 'components/reusable/OImage'
import GoogleMapReact from 'google-map-react'
import { useContext, useState } from 'react'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { GiPositionMarker } from 'react-icons/gi'
import pinIcon from 'assets/images/pin.png'
import { RxCross2 } from 'react-icons/rx'
import { startCase } from 'lodash'
import AuthContext from 'context/AuthContext'

// InfoWindow component
const InfoWindow = props => {
  const { data, setMerchantDetails, handleInfoBoxClose } = props
  const infoWindowStyle = {
    position: 'relative',
    top: 0,
    left: '-120px',
    width: 320,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: '10px 20px',
    fontSize: 14,
    zIndex: 100,
    borderRadius: '8px'
  }

  return (
    <div style={infoWindowStyle}>
      <span style={{ position: 'absolute', top: 0, right: 0 }}>
        <RxCross2 onClick={handleInfoBoxClose} />
      </span>
      <div className='d-flex '>
        <aside
          className='d-flex align-items-center '
          style={{ marginRight: '1rem' }}
        >
          <OImage
            src={data?.business_logo}
            style={{
              width: '2rem',
              height: '2rem',
              borderRadius: '50%'
            }}
          />
        </aside>
        <aside className='d-flex justify-content-between '>
          <div>
            <div
              className='mb-1 fw-semibold'
              style={{ color: ' #8F00FF', cursor: 'pointer' }}
              onClick={e => {
                setMerchantDetails(data)
              }}
            >
              {' '}
              {startCase(`${data?.first_name} ${data?.last_name}`)}
            </div>
            <div className='mb-1'>{startCase(data?.category?.name)} </div>
            <div className='mb-1'>
              {' '}
              +{data?.country_code} {data?.mobile}
            </div>
          </div>
          <div className='ms-4 d-flex align-items-end'>
            <div className='border p-1' style={{ borderRadius: '.5rem' }}>
              <FaMapMarkerAlt style={{ marginRight: '5px' }} />
              {Math.round(data?.distance)} km
            </div>
          </div>
        </aside>
      </div>
    </div>
  )
}

const Marker = ({
  icon,
  item,
  show,
  key,
  setMerchantDetails,
  handleInfoBoxClose
}) => {
  if (icon) {
    return (
      <div style={{ height: '50px', width: '100px' }} key={key}>
        <GiPositionMarker
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '-22px',
            top: '-50px',
            transform: 'translate(1px,0px)',
            borderRadius: '50%'
          }}
        />
      </div>
    )
  }
  return (
    <div style={{ height: '50px', width: '100px' }} key={key}>
      <div style={{ position: 'absolute', left: '-15px', top: '-45px' }}>
        <img src={pinIcon} alt='' />
        <div style={{ position: 'absolute', top: '0' }}>
          <OImage
            src={item?.business_logo}
            style={{
              width: '33px',
              height: '33px',

              padding: '3px',
              transform: 'translate(1px,0px)',
              borderRadius: '50%'
            }}
          />
        </div>
      </div>

      {show && (
        <InfoWindow
          data={item}
          setMerchantDetails={setMerchantDetails}
          handleInfoBoxClose={handleInfoBoxClose}
        />
      )}
    </div>
  )
}

export default function OMap ({
  nearByMerchants,
  setMerchantDetails,
  currentCoordinate
}) {
  let { user } = useContext(AuthContext)
  const defaultProps = {
    center: {
      lat: currentCoordinate.lat,
      lng: currentCoordinate.lng
    },

    zoom: 12
  }
  const [markerInfoWindow, setMarkerInfoWindow] = useState({})

  const onChildCallback = (key, item) => {
    setMarkerInfoWindow({
      [key]: true
    })
  }

  const handleInfoBoxClose = key => {
    setMarkerInfoWindow({
      [key]: false
    })
  }

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '800px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDEyGDSkwFiSER8lSfqxEGxAuWPWJ9CBwM' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onChildClick={(key, item) => onChildCallback(key, item)}
        options={{
          draggable: true,
          disableDoubleClickZoom: true,
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          panControl: false,
          rotateControl: false,
          fullscreenControl: false
        }}
      >
        {nearByMerchants?.map((item, i) => {
          return (
            <Marker
              key={i}
              item={item}
              lat={item?.location?.coordinates[1]}
              lng={item?.location?.coordinates[0]}
              show={markerInfoWindow?.[i]}
              setMerchantDetails={setMerchantDetails}
              handleInfoBoxClose={items => handleInfoBoxClose(items)}
            />
          )
        })}

        <Marker
          key='current-location'
          lat={defaultProps?.center?.lat}
          lng={defaultProps?.center?.lng}
          show={false}
          handleInfoBoxClose={i => handleInfoBoxClose(i)}
          icon={user?.profile_pic}
        />
      </GoogleMapReact>
    </div>
  )
}
