import ErrorMessage from 'components/reusable/ErrorMessage'
import useToastContext from 'hooks/useToastContext'
import { useEffect } from 'react'
import { Row, Form, Col, Button, Dropdown } from 'react-bootstrap'

import { preventMax } from 'utils/reusableMethods'
import { useForm } from 'react-hook-form'
import { apiPost } from 'utils/apiFetch'
import pathObj from 'utils/apiPath'
import { integerInput } from 'utils/InputControl'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

function TransferToMobile ({ getBalance, dropDown }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const sendMoneyToMobile = async data => {
    try {
      const path = pathObj.sendOTPTransferMoney

      const sendData = { ...data, country_code: 355, transferType: 'mobile' }
      const result = await apiPost(path, sendData)
      if (result?.data?.success) {
        notification.success(result?.data?.message)
        navigate('/wallet/transfer/otp', { state: sendData })
        resetMobile()
        getBalance()
      } else {
        notification.error(result?.data?.message)
      }
    } catch (error) {}
  }

  const notification = useToastContext()

  const {
    register,
    handleSubmit,
    setValue,
    reset: resetMobile,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })

  useEffect(() => {
    if (dropDown === 0) {
      resetMobile()
    }
  }, [dropDown])

  const mobileNumber = value => {
    if (value.length >= 9) {
      setValue('mobile', value.slice(0, 9))
    }
  }

  const preventSpace = e => {
    if (['Enter'].includes(e.key)) {
      e.preventDefault()
    }
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }

  return (
    <Form
      onSubmit={handleSubmit(sendMoneyToMobile)}
      className='mobile-form-sec'
    >
      <Row className='gx-lg-3 gx-1'>
        <Col md={4}>
          <Row className='gx-2'>
            <Col className='col-3'>
              <Dropdown className='mb-3' placeholder='name@example.com'>
                <Dropdown.Toggle
                  id='dropdown-basic'
                  className='py-3 bg-transparent text-dark fw-normal'
                >
                  +355
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>+355</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col className='col-9'>
              <Form.Group className='mb-3'>
                <Form.Floating>
                  <Form.Control
                    id='floatingInputCustom'
                    type='number'
                    name='mobile'
                    placeholder='name@example.com'
                    autofocus
                    onKeyPress={e => integerInput(e)}
                    onInput={e => preventMax(e, 9)}
                    {...register('mobile', {
                      required: t('REGISTER_MOBILE'),
                      onChange: e => {
                        mobileNumber(e.target.value)
                      },
                      minLength: {
                        value: 7,
                        message: t('REGISTER_MOBILE_CONTAIN_SEVEN')
                      },
                      maxLength: {
                        value: 9,
                        message: t('REGISTER_MIN_LENGTH_MOBILE')
                      }
                    })}
                  />
                  <label htmlFor='floatingInputCustom'>
                    {t('O_MOBILE_NUMBER')}
                    <span className='text-danger'>*</span>
                  </label>
                  <ErrorMessage message={errors?.mobile?.message} />
                </Form.Floating>
              </Form.Group>
            </Col>
          </Row>
        </Col>

        <Col md={4}>
          <Form.Group className='mb-3'>
            <Form.Floating>
              <Form.Control
                id='floatingInputCustom'
                type='number'
                name='amount'
                placeholder='name@example.com'
                onInput={e => preventMax(e, 9)}
                onKeyPress={e => integerInput(e)}
                onKeyDown={e => {
                  if (['-', '+', 'e', 'Enter'].includes(e.key)) {
                    e.preventDefault()
                  }
                }}
                {...register('amount', {
                  required: t('AMOUNT_FIELD'),
                  min: {
                    value: 10,
                    message: t('MIN_AMOUNT')
                  }
                })}
                aria-invalid={errors.amount ? 'true' : 'false'}
              />
              <label htmlFor='floatingInputCustom'>
                {t('O_AMOUNT')}
                <span className='text-danger'>*</span>
              </label>
              <ErrorMessage message={errors?.amount?.message} />
            </Form.Floating>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className='mb-3'>
            <Form.Floating>
              <Form.Control
                id='floatingInputCustom'
                type='text'
                name='message'
                onKeyDown={preventSpace}
                placeholder='name@example.com'
                maxLength={500}
                {...register('message', {
                  maxLength: {
                    value: 500,
                    message: t('MESSAGE_LENGTH')
                  }
                })}
              />
              <label htmlFor='floatingInputCustom'>
                {t('GIFT_CARD_MESSAGE')}
              </label>
            </Form.Floating>
          </Form.Group>
        </Col>
      </Row>
      <Button className='btn btn_theme' type='submit'>
        {t('SEND_MONEY')}
      </Button>
    </Form>
  )
}

export default TransferToMobile
