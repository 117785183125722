import React, { useContext } from 'react'
import Footer from '../components/Footer'
import AuthContext from 'context/AuthContext'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon
} from 'react-share'

function ReferEarn () {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const refMessage = t("REFER_MESSAGE").replace("[REFCODe]", user.referral_code)
  return (
    <>
      <div className='main_wrap refer_earn'>
        <div className='m-0 py-4 sidebar_pageHeading fullPage_space'>
          <h5 className='mb-0 text-white'>
            <img src='./images/sidebar_icon8.svg' alt='' className='me-2' />{' '}
            {t('NAV_REFER_AND_EARN')}
          </h5>
        </div>
        <div className='bg-light-gray p-4 common-height'>
          <div className='main_panel rounded-0 overflow-hidden bg-white pb-3'>
            <div className='text-center'>
              <h3 className='mb-0'>{t('REFER_AND_EARN_UPTO')}</h3>
              <div>
                <img src='images/refer.png' className='refer_img' />
                <h5>
                  {t('REFER_AND_EARN_REFERRAL')}{' '}
                  <span className='d-block mt-1'> {user.referral_code}</span>
                </h5>
                <div className='refer_bottom mb-md-5 pb-md-5 mb-2 pb-2'>
                  <OverlayTrigger
                    trigger='click'
                    key='bottom'
                    rootClose
                    placement='bottom'
                    overlay={
                      <Popover id={`popover-positioned-bottom`}>
                        <Popover.Body className='social_share_buttons'>
                          <FacebookShareButton
                            url={refMessage}
                            style={{ marginRight: '4px' }}
                          >
                            <FacebookIcon size={40} round={true} />
                          </FacebookShareButton>

                          <LinkedinShareButton
                            url={refMessage}
                            style={{ marginRight: '4px' }}
                          >
                            <LinkedinIcon size={40} round={true} />
                          </LinkedinShareButton>

                          <WhatsappShareButton
                            url={refMessage}
                            style={{ marginRight: '4px' }}
                          >
                            <WhatsappIcon size={40} round={true} />
                          </WhatsappShareButton>

                          <TwitterShareButton
                            url={refMessage}
                            style={{ marginRight: '4px' }}
                          >
                            <TwitterIcon size={40} round={true} />
                          </TwitterShareButton>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button
                      to=''
                      className='btn-sm btn me-3 px-3 py-2 rounded-2 mb-2 mb-sm-0'
                    >
                      <img src='./images/share.svg' alt='' className='me-1' />{' '}
                      {t('REFER_AND_EARN_SHARE_LINK')}
                    </Button>
                  </OverlayTrigger>

                  <Button
                    to=''
                    className='btn-sm btn bg-white text-dark px-5 py-2 copy_btn rounded-2 slipDownButton'
                    onClick={() => {
                      navigator.clipboard.writeText(user?.referral_code)
                      alert(t('REFERAL_CODE_CLIPBOARD'))
                    }}
                  >
                    {t('O_COPY')}
                  </Button>
                </div>{' '}
              </div>
            </div>
            <h4>{t('REFER_AND_EARN_WHAT')}</h4>
            <p>{t('REFER_AND_EARN_INVITE')}</p>
          </div>
        </div>

        <Footer />
      </div>
    </>
  )
}

export default ReferEarn
