import icon1 from './assets/images/profile.svg'
import icon3 from './assets/images/sidebar_icon3.svg'
import icon4 from './assets/images/sidebar_icon4.svg'
import icon5 from './assets/images/sidebar_icon5.svg'
import icon6 from './assets/images/sidebar_icon6.svg'
import icon8 from './assets/images/sidebar_icon8.svg'
import icon9 from './assets/images/sidebar_icon9.svg'
import icon10 from './assets/images/sidebar_icon10.svg'
import QrCodeWhite from './assets/images/qr-code_white.png'
import Transaction from './assets/images/transactions_white.png'
import NearbyMerchant from './assets/images/Nearby-merchants_white.png'
import Dashboard from './assets/images/dashboard_white.png'
import Invoice from './assets/images/invoice.png'
import { useTranslation } from 'react-i18next'

const UseChange = ({ data }) => {
  let { t } = useTranslation()
  return t(data)
}

export const sidebarData = [
  {
    id: 1,
    menu: <>{<UseChange data='NAV_DASHBOARD' />}</>,
    link: '/dashboard',
    menuImg: Dashboard
  },
  {
    id: 2,
    menu: <>{<UseChange data='NAV_MY_PROFILE' />}</>,
    link: '/profile',
    menuImg: icon1
  },

  {
    id: 3,
    menu: <>{<UseChange data='O_MY_QR_CODE' />}</>,
    link: '/my-qrcode',
    menuImg: QrCodeWhite
  },
  {
    id: 4,
    menu: <>{<UseChange data='O_MY_WALLET' />}</>,
    link: '/my-wallet',
    menuImg: icon3
  },

  {
    id: 5,
    menu: <>{<UseChange data='O_WALLET_STATEMENT' />}</>,
    link: '/my-wallet-statement',
    menuImg: icon4
  },

  {
    id: 6,
    menu: <>{<UseChange data='O_MY_ORDERS' />}</>,
    link: '/MyOrder',
    menuImg: Transaction
  },

  {
    id: 7,
    menu: <>{<UseChange data='O_MY_PENDING_INVOICES' />}</>,
    link: '/MyPendingInvoices',
    menuImg: Transaction
  },

  {
    id: 8,
    menu: <>{<UseChange data='NAV_INVOICE' />}</>,
    link: '/invoice',
    menuImg: Invoice
  },

  {
    id: 9,
    menu: <>{<UseChange data='O_OFFERS_AND_PROMO_CODES' />}</>,
    link: '/offers',
    menuImg: icon5
  },

  {
    id: 10,
    menu: <>{<UseChange data='O_TRANSFER_MONEY_TO_BANK' />}</>,
    link: '/transfer-money-to-bank',
    menuImg: icon5
  },

  {
    id: 11,
    menu: <>{<UseChange data='NAV_LOYALTY_POINTS' />}</>,
    link: '/RewardPoint',
    menuImg: icon6
  },

  {
    id: 12,
    menu: <>{<UseChange data='NAV_LOAN_STATUS' />}</>,
    link: '/loanStatus',
    menuImg: icon6
  },

  {
    id: 13,
    menu: <>{<UseChange data='NAV_REFER_AND_EARN' />}</>,
    link: '/ReferEarn',
    menuImg: icon8
  },

  {
    id: 14,
    menu: <>{<UseChange data='NAV_GIFT_CARD' />}</>,
    link: '/SelectGiftCard',
    menuImg: icon9
  },

  {
    id: 15,
    menu: <>{<UseChange data='NAV_NEARBY_MERCHANTS' />}</>,
    link: '/nearby-merchants',
    menuImg: NearbyMerchant
  },
  {
    id: 16,
    menu: <>{<UseChange data='NAV_SETTINGS' />}</>,
    link: '/Settings',
    menuImg: icon10
  }
]
