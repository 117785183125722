import React, { useEffect } from 'react'
import { Offcanvas } from 'react-bootstrap'
import { RxCross2 } from 'react-icons/rx'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import userAvatar from 'assets/images/profile_page.png'

function Sidebar ({ show, sidebarData, user, handleClose, handleToggle }) {
  const { t } = useTranslation()
  useEffect(() => {
    let timeoutRef
    if (show) {
      timeoutRef = setTimeout(() => {
        removeAttributeFunc()
      }, [100])
    }
    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef)
      }
    }
  }, [show])

  const removeAttributeFunc = () => {
    const sidebarRef = document.getElementsByClassName('sidebar')[0]
    sidebarRef?.removeAttribute('tabindex')
  }
  return (
    <>
      <Offcanvas show={show} className='sidebar text-white pt-0'>
        <div className='text-center'>
          <button className='sidebar_off'>
            <RxCross2 onClick={() => handleClose()} />{' '}
          </button>
          <div>
            <img src='./images/logo.png' alt='' className='pb-4' />
            <div className='profile'>
              <img src={user?.profile_pic || userAvatar} alt='' className='' />
            </div>
            <h4>
              {t('SIDEBAR_WELCOME')}{' '}
              <span className='d-block'>{`${user?.first_name} ${user?.last_name}`}</span>{' '}
            </h4>
          </div>

          {/* scroller-bar */}

          <div className='scrollbar pt-4 pb-5 sidebar_main justify-content-center font-regular  overflow-y-auto'>
            {sidebarData &&
              sidebarData?.map((item, i) => {
                return (
                  <NavLink
                    key={i}
                    to={item?.link}
                    onClick={handleToggle}
                    className={({ isActive }) =>
                      isActive
                        ? 'active d-flex align-items-center ps-4 py-3  mb-2 text-white'
                        : 'd-flex align-items-center ps-4 py-3  mb-2 text-white'
                    }
                  >
                    <span className='me-2'>
                      {item?.menuImg ? (
                        <img src={item?.menuImg} alt='profile' />
                      ) : (
                        item?.icons
                      )}
                    </span>
                    {item?.menu}

                    {item?.cashback && (
                      <h5 className='cashback'>{item?.cashback}</h5>
                    )}
                  </NavLink>
                )
              })}

            <div className='px-4 download-app'>
              <a
                target='_blank'
                href=' https://play.google.com/store/apps/details?id=com.app.alpay'
                className='justify-content-center btn-sm btn d-flex align-items-center bg-white rounded-3'
              >
                <img src='./images/google-play.svg' alt='' className='me-2 ' />{' '}
                {t('SIDEBAR_DOWNLOAD_APP')}{' '}
              </a>
            </div>
          </div>

          {/* scroller-bar */}
        </div>
      </Offcanvas>
    </>
  )
}

export default Sidebar
