import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useTranslation } from 'react-i18next'

function GiftPreview () {
  const { t } = useTranslation()
  return (
    <>
      <Header />

      <div className='main_wrap px-0'>
        <div className='m-0 py-4 sidebar_pageHeading fullPage_space'>
          <h5 className='mb-0 text-white'>
            <img src='./images/sidebar_icon3.svg' alt='' className='me-2' />
            My Wallet
          </h5>
        </div>
        <div className='bg-light main_panel rounded-0 p-3 p-md-4 sendgift'>
          <div className='bg-white p-3 p-md-4 tabs_theme'>
            <div className='card_balance d-flex flex-wrap align-items-center px-3'>
              <h5 className='text-white mt-2'>
                Gift & credit balance:
                <span className=''> {t('O_LEK')} 200.00</span>
              </h5>
              <Button
                type='btn'
                className='id_btn text-white bg-dark fw-bold px-3 ms-md-auto ms-0 my-md-0 my-2 border-0'
              >
                ID - XYV458722221
              </Button>
            </div>
            <div
              defaultActiveKey='browse_plan'
              id='uncontrolled-tab-example'
              className='mb-3 border-top mt-2 pt-4'
            >
              <div eventKey='send_gift' title='Sent Gift Cards'>
                <div className='d-sm-flex  align-items-center'>
                  <h4 className='mb-sm-0 mb-3'>Gift preview</h4>

                  <Link to='/AddGiftCard' className='btn btn_theme ms-auto'>
                    Add any gift card
                  </Link>
                  <Link
                    to='/sendGiftCard'
                    className='btn btn_theme ms-sm-3 ms-1'
                  >
                    Sent gift cards
                  </Link>
                </div>
                <div className='bday_card  p-2 mt-3  border-md-0 border-1 border rounded-2'>
                  <div className='d-md-flex justify-content-between text-md-start text-center '>
                    <div className='d-md-flex my-sm-0 my-3'>
                      <img
                        src='./images/gift_type.png'
                        alt=''
                        className='my-md-0 my-3'
                      />
                      <h5 className='fs-5 fw-normal text-grey mb-md-0 mb-3'>
                        Gift type
                        <span className='d-block fs-6 mt-1 text-dark mt-1'>
                          Birthday
                        </span>
                      </h5>
                    </div>
                    <h6 className='fs-5 fw-normal text-grey mb-md-0 mb-3'>
                      Gift amount
                      <span className='d-block text-dark  mt-1'>$1500</span>
                    </h6>
                    <h6 className='fs-5 fw-normal text-grey mb-md-0 mb-3'>
                      Gifted to
                      <span className='d-block text-dark  mt-1'>
                        Petter richmond
                      </span>
                    </h6>
                    <h6 className='fs-5 fw-normal text-grey mb-md-0 mb-3'>
                      From
                      <span className='d-block text-dark  mt-1'>
                        Nathan richmond
                      </span>
                    </h6>
                    <h6 className='fs-5 fw-normal text-grey me-md-5 me-0 mb-md-0 mb-3'>
                      Delivery date
                      <span className='d-block text-dark  mt-1'>
                        25 Nov 2022{' '}
                      </span>
                    </h6>
                  </div>
                  <div className='px-sm-5 px-2 pt-3'>
                    <h5 className='mx-md-3 mx-1 fs-5 text-md-start text-center'>
                      Message
                    </h5>
                    <p className='mx-md-3 mx-1 fs-6 text-grey text-md-start text-center'>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.{' '}
                    </p>
                    <Link
                      to='/GiftPreview'
                      className='btn btn_theme ms-3 py-2 mt-3 d-md-block d-flex justify-content-center'
                    >
                      Gift now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='fullPage_space'>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default GiftPreview
