import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, Row, Col, Modal } from 'react-bootstrap'
import Footer from '../components/Footer'
import { useForm } from 'react-hook-form'
import AuthContext from 'context/AuthContext'
import ErrorMessage from 'components/reusable/ErrorMessage'
import { apiDelete, apiGet, apiPost } from 'utils/apiFetch'
import pathObj from 'utils/apiPath'
import OImage from 'components/reusable/OImage'
import { preventMax, validateFile } from 'utils/reusableMethods'
import userAvatar from 'assets/images/profile_page.png'
import dashCircleFill from 'assets/images/dash-circle.png'
import { useTranslation } from 'react-i18next'
import { integerInput } from 'utils/InputControl'
import { validationRegex } from 'utils/constants'
import useToastContext from 'hooks/useToastContext'
import { useNavigate } from 'react-router-dom'
import { startCase } from 'lodash'

function Profile () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors, isDirty, dirtyFields }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })
  let { user, updateProfile } = useContext(AuthContext)
  const [fileMsg, setFileMsg] = useState({
    identityCardBack: '',
    identityCardFront: '',
    businessLicense: '',
    businessExtract: '',
    user_image: ''
  })
  const [secondaryEmailOpen, setSecondaryEmailOpen] = useState(false)
  const [secondaryEmail, setSecondaryEmail] = useState('')
  const [isSecondaryEmailCheck, setIsSecondaryEmailCheck] = useState(false)
  const [isSecondaryMobileCheck, setIsSecondaryMobileCheck] = useState(false)
  const [removeEmailModal, setRemoveEmailModal] = useState(false)
  const [removeMobileModal, setRemoveMobileModal] = useState(false)
  const [isPrimaryEmail, setIsPrimaryEmail] = useState(false)
  const [isPrimaryMobile, setIsPrimaryMobile] = useState(false)
  const [isPrimaryEmailAlert, setIsPrimaryEmailAlert] = useState(false)
  const [isPrimaryMobileAlert, setIsPrimaryMobileAlert] = useState(false)
  const [secondaryMobile, setSecondaryMobile] = useState('')
  const [secondaryMobileNumberError, setSecondaryMobileNumberError] =
    useState('')

  const notification = useToastContext()
  const [secondaryMobileNoOpen, setSecondaryMobileNoOpen] = useState(false)
  let userImageWatch = watch('user_image')

  const handleProfileUpdate = async data => {
    if (
      !isDirty ||
      fileMsg.identityCardBack ||
      fileMsg.identityCardFront ||
      fileMsg.user_image
    ) {
      return
    }
    const formData = new FormData()
    if (dirtyFields.first_name) {
      formData.append('first_name', data?.first_name)
    }
    if (dirtyFields.last_name) {
      formData.append('last_name', data?.last_name)
    }
    if (dirtyFields.email) {
      formData.append('email', data?.email)
    }
    if (dirtyFields.mobile) {
      formData.append('mobile', data?.mobile)
    }
    if (dirtyFields.country_code) {
      formData.append('country_code', data?.country_code)
    }
    if (dirtyFields.address) {
      formData.append('address', data?.address)
    }

    if (dirtyFields?.identityCardBack) {
      formData.append('identityCardBack', data?.identityCardBack?.[0])
    }
    if (dirtyFields?.identityCardFront) {
      formData.append('identityCardFront', data.identityCardFront[0])
    }

    if (dirtyFields?.user_image) {
      formData.append('user_image', data.user_image[0])
    }

    updateProfile(formData, () => {
      window.scrollTo(0, 0)
      getProfileDetails()
    })
  }
  const [profile, setProfile] = useState({})

  const getProfileDetails = async () => {
    let res = null

    try {
      res = await apiGet(pathObj.getProfile)
      if (res?.data.success === true) {
        reset({
          email: res?.data?.results?.email,
          NID: res?.data?.results?.NID,
          niptNo: res?.data?.results?.niptNo,
          businessName: res?.data?.results?.businessName,
          first_name: res?.data?.results?.first_name,
          last_name: res?.data?.results?.last_name,
          mobile: res?.data?.results?.mobile,
          country_code: res?.data?.results?.country_code,
          address: res?.data?.results?.address
        })
        if (res?.data?.results?.secondaryEmail) {
          setSecondaryEmail(res?.data?.results?.secondaryEmail)
          setIsSecondaryEmailCheck(true)
        }
        if (res?.data?.results?.secondaryMobile) {
          setSecondaryMobile(res?.data?.results?.secondaryMobile)
          setIsSecondaryMobileCheck(true)
        }
        setProfile(res?.data?.results)
      }
    } catch (err) {
      console.log('err:', err)
    }
  }
  const handleCloseSecondaryEmail = () => {
    setSecondaryEmailOpen(false)
    setIsPrimaryEmail(false)
  }
  const handleCloseSecondaryMobileNo = () => {
    setSecondaryMobileNoOpen(false)
  }

  const codeValue = watch('secondaryEmail') ? watch('secondaryEmail') : ''

  const preventSpace = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }
  const onSubmitSecondaryEmail = async () => {
    const res = await apiPost(pathObj.updateSecondaryEmail, { secondaryEmail })
    if (res?.data?.success) {
      notification.success(res.data.message)
      setSecondaryEmailOpen(false)
      navigate('/secondary/otp', { state: { secondaryEmail } })
    } else {
      notification.error(res.data.message)
    }
  }
  const checkSecondaryMobileNumber = () => {
    let isValid = true
    if (secondaryMobile.length < 9) {
      isValid = false
      setSecondaryMobileNumberError(t('REGISTER_MOBILE_MIN_LENGTH'))
    } else {
      setSecondaryMobileNumberError('')
    }
    return isValid
  }
  const onSubmitSecondaryMobile = async () => {
    const isValid = checkSecondaryMobileNumber()
    if (!isValid) return
    const res = await apiPost(pathObj.updateSecondaryMobile, {
      secondaryMobile
    })
    if (res?.data?.success) {
      notification.success(res.data.message)
      setSecondaryEmailOpen(false)
      navigate('/secondary/otp', { state: { secondaryMobile } })
    } else {
      notification.error(res.data.message)
    }
  }
  const deleteSecondaryEmail = async () => {
    const res = await apiDelete(pathObj.deleteSecondaryEmail)
    if (res.data.success) {
      notification.success(res.data.message)
      setRemoveEmailModal(false)
      getProfileDetails()
      setIsSecondaryEmailCheck(false)
    } else {
      notification.error(res.data.message)
    }
  }
  const deleteSecondaryMobile = async () => {
    const res = await apiDelete(pathObj.deleteSecondaryMobile)
    if (res.data.success) {
      notification.success(res.data.message)
      setRemoveMobileModal(false)
      getProfileDetails()
      setIsSecondaryMobileCheck(false)
    } else {
      notification.error(res.data.message)
    }
  }
  const deleteEmailModalClose = () => {
    setRemoveEmailModal(false)
  }
  const deleteMobileModalClose = () => {
    setRemoveMobileModal(false)
  }
  const onChangePrimaryEmailAlert = () => {
    setIsPrimaryEmailAlert(true)
  }
  const onChangePrimaryMobileAlert = () => {
    setIsPrimaryMobileAlert(true)
  }
  const changePrimaryEmail = async () => {
    const res = await apiPost(pathObj.changePrimaryEmail)
    if (res.data.success) {
      setIsPrimaryEmail(false)
      setSecondaryEmailOpen(false)
      setIsPrimaryEmailAlert(false)
      notification.success(res.data.message)
      getProfileDetails()
    } else {
      notification.error(res.data.message)
    }
  }
  const changePrimaryMobile = async () => {
    const res = await apiPost(pathObj.changePrimaryMobile)
    if (res.data.success) {
      setIsPrimaryMobile(false)
      setSecondaryMobileNoOpen(false)
      setIsPrimaryMobileAlert(false)
      notification.success(res.data.message)
      getProfileDetails()
    } else {
      notification.error(res.data.message)
    }
  }

  useEffect(() => {
    getProfileDetails()
  }, [])
  useEffect(() => {
    if (watch('user_image')?.length > 0) {
      let user_image_msg = validateFile(watch('user_image')[0])
      if (user_image_msg) {
        setFileMsg({
          ...fileMsg,
          user_image: user_image_msg
        })
      } else {
        setFileMsg({
          ...fileMsg,
          user_image: ''
        })
      }
    }
  }, [watch('user_image')])

  useEffect(() => {
    if (watch('identityCardFront')?.length > 0) {
      let identityCardFront_msg = validateFile(watch('identityCardFront')[0])
      if (identityCardFront_msg) {
        setFileMsg({
          ...fileMsg,
          identityCardFront: identityCardFront_msg
        })
      } else {
        setFileMsg({
          ...fileMsg,
          identityCardFront: ''
        })
      }
    }
  }, [watch('identityCardFront')])

  useEffect(() => {
    if (watch('identityCardBack')?.length > 0) {
      let identityCardBack_msg = validateFile(watch('identityCardBack')[0])
      if (identityCardBack_msg) {
        setFileMsg({
          ...fileMsg,
          identityCardBack: identityCardBack_msg
        })
      } else {
        setFileMsg({
          ...fileMsg,
          identityCardBack: ''
        })
      }
    }
  }, [watch('identityCardBack')])
  useEffect(() => {
    if (watch('businessExtract')?.length > 0) {
      let businessExtract_msg = validateFile(watch('businessExtract')[0])
      if (businessExtract_msg) {
        setFileMsg({
          ...fileMsg,
          businessExtract: businessExtract_msg
        })
      } else {
        setFileMsg({
          ...fileMsg,
          businessExtract: ''
        })
      }
    }
  }, [watch('businessExtract')])
  useEffect(() => {
    if (watch('businessLicense')?.length > 0) {
      let businessLicense_msg = validateFile(watch('businessLicense')[0])
      if (businessLicense_msg) {
        setFileMsg({
          ...fileMsg,
          businessLicense: businessLicense_msg
        })
      } else {
        setFileMsg({
          ...fileMsg,
          businessLicense: ''
        })
      }
    }
  }, [watch('businessLicense')])
  return (
    <>
      <div className='main_wrap px-0'>
        <div className='m-0 py-4 sidebar_pageHeading fullPage_space'>
          <h5 className='mb-0 text-white'>
            <img src='./images/profile_page.png' alt='' className='me-2' />
            {t('MY_PROFILE')}
          </h5>
        </div>
        <div className='bg-light main_panel rounded-0 p-3 p-md-4 profile'>
          <div className='bg-white p-3 p-md-4'>
            <Form onSubmit={handleSubmit(handleProfileUpdate)}>
              <div className='profile_pic position-relative mb-4 mb-sm-5'>
                <div className='profile_img position-relative'>
                  {userImageWatch?.[0] && fileMsg?.user_image === '' ? (
                    <OImage src={URL.createObjectURL(userImageWatch[0])} />
                  ) : (
                    <OImage src={user?.profile_pic || userAvatar} />
                  )}

                  <span className='change_icon position-absolute'>
                    {' '}
                    <img src='./images/plus_icon.png' alt='' />
                  </span>
                  <Form.Control
                    type='file'
                    title={t('DISPLAY_PIC')}
                    className='position-absolute h-100 opacity-0'
                    accept='image/png,image/jpeg,image/jpg'
                    {...register('user_image', {
                      required: {
                        value: false,
                        message: 'Please,provide profile picture.'
                      }
                    })}
                  />
                </div>

                <div className='mb-0 text-center'>
                  {profile?.userType === 'business'
                    ? profile?.niptNo
                    : profile?.NID}
                </div>
                <div className='text-center'>
                  <ErrorMessage message={fileMsg.user_image} />
                </div>
              </div>

              <Row>
                <Col sm={6}>
                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      <Form.Control
                        id='floatingInputCustom1'
                        type='text'
                        maxLength={15}
                        onInput={preventSpace}
                        autoFocus
                        placeholder='name@example.com'
                        {...register('first_name', {
                          required: t('REGISTER_FIRST_NAME'),
                          validate: value => {
                            return !!value.trim()
                              ? true
                              : t('REGISTER_WHITE_SPACE')
                          }
                        })}
                      />
                      <label htmlFor='floatingInputCustom'>
                        {t('O_FIRST_NAME')}
                        <span className='text-danger'>*</span>
                      </label>
                      <ErrorMessage message={errors?.first_name?.message} />
                    </Form.Floating>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      <Form.Control
                        id='floatingInputCustom2'
                        type='text'
                        maxLength={15}
                        onInput={preventSpace}
                        placeholder='name@example.com'
                        {...register('last_name', {
                          required: t('REGISTER_LAST_NAME'),
                          validate: value => {
                            return !!value.trim()
                              ? true
                              : t('REGISTER_WHITE_SPACE')
                          }
                        })}
                      />
                      <label htmlFor='floatingInputCustom'>
                        {t('O_LAST_NAME')}
                        <span className='text-danger'>*</span>
                      </label>
                      <ErrorMessage message={errors?.last_name?.message} />
                    </Form.Floating>
                  </Form.Group>
                </Col>
                <Col sm={3} className='col-3'>
                  <div className='position-relative country-label'>
                    <span>{t('O_COUNTRY_CODE')}</span>
                    <Form.Select
                      aria-label='Default select example'
                      disabled={true}
                      style={{ height: '58px' }}
                      {...register('country_code', {})}
                    >
                      <option selected value='355'>
                        +355
                      </option>
                    </Form.Select>
                  </div>
                  <ErrorMessage message={errors?.country_code?.message} />
                </Col>
                <Col sm={9}>
                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      <Form.Control
                        id='floatingInputCustom2'
                        type='number'
                        disabled={true}
                        onKeyPress={e => integerInput(e)}
                        onInput={e => preventMax(e, 9)}
                        placeholder='name@example.com'
                        {...register('mobile', {})}
                      />
                      <label htmlFor='floatingInputCustom'>
                        {t('O_MOBILE_NUMBER')}{' '}
                        <sup className='text-danger'></sup>
                      </label>

                      <ErrorMessage message={errors?.mobile?.message} />
                    </Form.Floating>
                  </Form.Group>
                </Col>
                <Col>
                  <Button
                    className='py-2 btn btn_theme float-end mb-3'
                    type='button'
                    onClick={() => setSecondaryMobileNoOpen(true)}
                  >
                    {t('Add')}
                  </Button>
                </Col>
                <Col sm={12}>
                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      <Form.Control
                        id='floatingInputCustom2'
                        type='text'
                        disabled={true}
                        maxLength={50}
                        placeholder='name@example.com'
                        {...register('email', {})}
                      />
                      <label htmlFor='floatingInputCustom'>
                        {t('O_EMAIL')} <sup className='text-danger'></sup>
                      </label>

                      <ErrorMessage message={errors?.email?.message} />
                    </Form.Floating>
                  </Form.Group>
                </Col>
                <Col>
                  <Button
                    className='py-2 btn btn_theme float-end mb-3'
                    type='button'
                    onClick={() => setSecondaryEmailOpen(true)}
                  >
                    {t('Add')}
                  </Button>
                </Col>
                <Col sm={12}>
                  <span className='mb-3 d-block mt-3'>
                    <h5 className='d-flex justify-content-between align-items-center'>
                      {t('ACCOUNT_TYPE')}
                      <span className='fs-5'>
                        {' '}
                        {startCase(profile?.userType)}
                      </span>
                    </h5>
                  </span>
                </Col>

                {profile.userType === 'individual' && (
                  <>
                    <Col sm={12}>
                      <Form.Group className='mb-3'>
                        <Form.Floating className='mb-3'>
                          <Form.Control
                            id='NID'
                            type='text'
                            onInput={preventSpace}
                            disabled
                            placeholder={t('ENTER_NID')}
                            {...register('NID', {})}
                          />
                          <label htmlFor='NID'>
                            {t('ENTER_NID')}
                            <span className='text-danger'>*</span>
                          </label>

                          <ErrorMessage message={errors?.NID?.message} />
                        </Form.Floating>
                      </Form.Group>
                    </Col>
                    <Col sm={12}>
                      <h4 className='mb-3'>{t('UPLOAD_IDENTITY_CARD')}</h4>
                    </Col>

                    <Col sm={6}>
                      <Form.Group className='mb-3'>
                        <div className='position-relative'>
                          <Form.Control
                            type='file'
                            className='position-absolute h-100 opacity-0'
                            accept='image/png,image/jpeg,image/jpg'
                            {...register('identityCardFront', {})}
                            disabled
                          />
                          <div
                            className='input_file d-flex align-items-center rounded-3 p-3 form-control '
                            style={{ background: '#E9ECEF' }}
                          >
                            {t('FRONT')}
                            <span className='rounded-circle ms-auto'>
                              <img
                                src='./images/upload.svg'
                                className='me-2'
                                alt=''
                              />
                            </span>
                          </div>
                          <ErrorMessage
                            message={
                              errors?.identityCardFront?.message ||
                              fileMsg.identityCardFront
                            }
                          />
                          <div className='mt-2'>
                            {watch('identityCardFront')?.length > 0 &&
                            fileMsg?.identityCardFront === '' ? (
                              <>
                                <OImage
                                  src={URL.createObjectURL(
                                    watch('identityCardFront')?.[0]
                                  )}
                                  style={{ width: '8rem', height: '8rem' }}
                                />
                              </>
                            ) : (
                              profile?.identityCardFront &&
                              fileMsg?.identityCardFront === '' && (
                                <OImage
                                  src={profile?.identityCardFront}
                                  style={{ width: '8rem', height: '8rem' }}
                                />
                              )
                            )}
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className='mb-3'>
                        <div className='position-relative'>
                          <Form.Control
                            type='file'
                            className='position-absolute h-100 opacity-0'
                            accept='image/png,image/jpeg,image/jpg'
                            {...register('identityCardBack', {})}
                            disabled
                          />
                          <div
                            className='input_file d-flex align-items-center rounded-3  p-3 form-control'
                            style={{ background: '#E9ECEF' }}
                          >
                            {t('BACK')}
                            <span className='rounded-circle ms-auto'>
                              <img
                                src='./images/upload.svg'
                                className='me-2'
                                alt=''
                              />
                            </span>
                          </div>
                          <ErrorMessage
                            message={
                              errors?.identityCardBack?.message ||
                              fileMsg.identityCardBack
                            }
                          />
                          <div className='mt-2'>
                            {watch('identityCardBack')?.length > 0 &&
                            fileMsg?.identityCardBack === '' ? (
                              <>
                                <OImage
                                  src={URL.createObjectURL(
                                    watch('identityCardBack')?.[0]
                                  )}
                                  style={{ width: '8rem', height: '8rem' }}
                                />
                              </>
                            ) : (
                              profile?.identityCardBack &&
                              fileMsg?.identityCardBack === '' && (
                                <OImage
                                  src={profile?.identityCardBack}
                                  style={{ width: '8rem', height: '8rem' }}
                                />
                              )
                            )}
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                  </>
                )}
                {profile.userType === 'business' && (
                  <>
                    <Col sm={12}>
                      <Form.Group className='mb-3'>
                        <Form.Floating className='mb-3'>
                          <Form.Control
                            id='businessName'
                            type='text'
                            onInput={preventSpace}
                            disabled
                            placeholder={t('BUSINESS_NAME')}
                            {...register('businessName', {
                              required: t('REGISTER_BUSINESS_NAME')
                            })}
                          />
                          <label htmlFor='businessName'>
                            {t('BUSINESS_NAME')}
                            <span className='text-danger'>*</span>
                          </label>

                          <ErrorMessage
                            message={errors?.businessName?.message}
                          />
                        </Form.Floating>
                      </Form.Group>
                    </Col>
                    <Col sm={12}>
                      <Form.Group className='mb-3'>
                        <Form.Floating className='mb-3'>
                          <Form.Control
                            id='niptNo'
                            type='text'
                            onInput={preventSpace}
                            disabled
                            placeholder={t('ENTER_NIPT_NO')}
                            {...register('niptNo', {
                              required: t('REGISTER_NIPT_NO')
                            })}
                          />
                          <label htmlFor='niptNo'>
                            {t('ENTER_NIPT_NO')}
                            <span className='text-danger'>*</span>
                          </label>

                          <ErrorMessage message={errors?.niptNo?.message} />
                        </Form.Floating>
                      </Form.Group>
                    </Col>
                    <Col sm={12}>
                      <h4 className='mb-3'>
                        {t('ADMINISTRATOR_IDENTITY_CARD')}
                      </h4>
                    </Col>

                    <Col sm={6}>
                      <Form.Group className='mb-3'>
                        <div className='position-relative'>
                          <Form.Control
                            type='file'
                            className='position-absolute h-100 opacity-0'
                            accept='image/png,image/jpeg,image/jpg'
                            {...register('identityCardFront', {})}
                            disabled
                          />
                          <div
                            className='input_file d-flex align-items-center rounded-3 p-3 form-control '
                            style={{ background: '#E9ECEF' }}
                          >
                            {t('FRONT')}
                            <span className='rounded-circle ms-auto'>
                              <img
                                src='./images/upload.svg'
                                className='me-2'
                                alt=''
                              />
                            </span>
                          </div>
                          <ErrorMessage
                            message={
                              errors?.identityCardFront?.message ||
                              fileMsg.identityCardFront
                            }
                          />
                          <div className='mt-2'>
                            {watch('identityCardFront')?.length > 0 &&
                            fileMsg?.identityCardFront === '' ? (
                              <>
                                <OImage
                                  src={URL.createObjectURL(
                                    watch('identityCardFront')?.[0]
                                  )}
                                  style={{ width: '8rem', height: '8rem' }}
                                />
                              </>
                            ) : (
                              profile?.identityCardFront &&
                              fileMsg?.identityCardFront === '' && (
                                <OImage
                                  src={profile?.identityCardFront}
                                  style={{ width: '8rem', height: '8rem' }}
                                />
                              )
                            )}
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className='mb-3'>
                        <div className='position-relative'>
                          <Form.Control
                            type='file'
                            className='position-absolute h-100 opacity-0'
                            accept='image/png,image/jpeg,image/jpg'
                            {...register('identityCardBack', {})}
                            disabled
                          />
                          <div
                            className='input_file d-flex align-items-center rounded-3  p-3 form-control'
                            style={{ background: '#E9ECEF' }}
                          >
                            {t('BACK')}
                            <span className='rounded-circle ms-auto'>
                              <img
                                src='./images/upload.svg'
                                className='me-2'
                                alt=''
                              />
                            </span>
                          </div>
                          <ErrorMessage
                            message={
                              errors?.identityCardBack?.message ||
                              fileMsg.identityCardBack
                            }
                          />
                          <div className='mt-2'>
                            {watch('identityCardBack')?.length > 0 &&
                            fileMsg?.identityCardBack === '' ? (
                              <>
                                <OImage
                                  src={URL.createObjectURL(
                                    watch('identityCardBack')?.[0]
                                  )}
                                  style={{ width: '8rem', height: '8rem' }}
                                />
                              </>
                            ) : (
                              profile?.identityCardBack &&
                              fileMsg?.identityCardBack === '' && (
                                <OImage
                                  src={profile?.identityCardBack}
                                  style={{ width: '8rem', height: '8rem' }}
                                />
                              )
                            )}
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col sm={12}>
                      <h4 className='mb-3'>
                        {t('UPLOAD_BUSINESS_EXTRACT_AND_BUSINESS_LICENSE')}
                      </h4>
                    </Col>

                    <Col sm={6}>
                      <Form.Group className='mb-3'>
                        <div className='position-relative'>
                          <Form.Control
                            type='file'
                            className='position-absolute h-100 opacity-0'
                            accept='image/png,image/jpeg,image/jpg'
                            {...register('businessExtract', {})}
                            disabled
                          />
                          <div
                            className='input_file d-flex align-items-center rounded-3 p-3 form-control '
                            style={{ background: '#E9ECEF' }}
                          >
                            {t('BUSINESS_EXTRACT')}
                            <span className='rounded-circle ms-auto'>
                              <img
                                src='./images/upload.svg'
                                className='me-2'
                                alt=''
                              />
                            </span>
                          </div>
                          <ErrorMessage
                            message={
                              errors?.businessExtract?.message ||
                              fileMsg.businessExtract
                            }
                          />
                          <div className='mt-2'>
                            {watch('businessExtract')?.length > 0 &&
                            fileMsg?.businessExtract === '' ? (
                              <>
                                <OImage
                                  src={URL.createObjectURL(
                                    watch('businessExtract')?.[0]
                                  )}
                                  style={{ width: '8rem', height: '8rem' }}
                                />
                              </>
                            ) : (
                              profile?.businessExtract &&
                              fileMsg?.businessExtract === '' && (
                                <OImage
                                  src={profile?.businessExtract}
                                  style={{ width: '8rem', height: '8rem' }}
                                />
                              )
                            )}
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className='mb-3'>
                        <div className='position-relative'>
                          <Form.Control
                            type='file'
                            className='position-absolute h-100 opacity-0'
                            accept='image/png,image/jpeg,image/jpg'
                            {...register('businessLicense', {})}
                            disabled
                          />
                          <div
                            className='input_file d-flex align-items-center rounded-3  p-3 form-control'
                            style={{ background: '#E9ECEF' }}
                          >
                            {t('BUSINESS_LICENSE')}
                            <span className='rounded-circle ms-auto'>
                              <img
                                src='./images/upload.svg'
                                className='me-2'
                                alt=''
                              />
                            </span>
                          </div>
                          <ErrorMessage
                            message={
                              errors?.businessLicense?.message ||
                              fileMsg.businessLicense
                            }
                          />
                          <div className='mt-2'>
                            {watch('businessLicense')?.length > 0 &&
                            fileMsg?.businessLicense === '' ? (
                              <>
                                <OImage
                                  src={URL.createObjectURL(
                                    watch('businessLicense')?.[0]
                                  )}
                                  style={{ width: '8rem', height: '8rem' }}
                                />
                              </>
                            ) : (
                              profile?.businessLicense &&
                              fileMsg?.businessLicense === '' && (
                                <OImage
                                  src={profile?.businessLicense}
                                  style={{ width: '8rem', height: '8rem' }}
                                />
                              )
                            )}
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                  </>
                )}
                <Col sm={12}>
                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      <Form.Control
                        id='address'
                        type='text'
                        onInput={preventSpace}
                        placeholder={t('ADDRESS')}
                        {...register('address', {})}
                      />
                      <label htmlFor='address'>{t('ADDRESS')}</label>
                      {watch('address') && (
                        <span
                          className='text-danger address-dash-circle position-absolute end-0 top-0 bottom-0 p-3 '
                          onClick={() => setValue('address', '')}
                        >
                          <img src={dashCircleFill} alt='' />
                        </span>
                      )}
                    </Form.Floating>
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Button
                    className='btn btn_theme w-100 py-3'
                    type='submit'
                    disabled={!isDirty}
                  >
                    {t('MY_PROFILE_SAVE_CHANGES')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        <div className='fullPage_space'>
          <Footer />
        </div>
      </div>
      <Modal
        show={secondaryEmailOpen}
        onHide={handleCloseSecondaryEmail}
        centered
        className='verify_modal email_detail'
      >
        <Modal.Header closeButton title={t('O_CLOSE')}>
          <Modal.Title>
            {' '}
            {t('SET_PRIMARY_EMAIL')}
            <small className='d-block email_small_head'>
              {t('ONLY_TWO_EMAIL_ID_CAN_BE_ADDED')}
            </small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center p-3'>
          <div className='email_form'>
            <div className='mb-3'>
              <div className='email_grp position-relative'>
                <label className=''>
                  <input
                    type='checkbox'
                    checked={isPrimaryEmail === false}
                    name=''
                    id=''
                  />
                  {profile?.email}
                </label>
              </div>
              {!isSecondaryEmailCheck && (
                <Form.Group className='my-3' controlId='secondaryEmail'>
                  <Form.Label>
                    <span className='plushcricle'>+</span>
                    {t('ADD_EMAIL')}
                  </Form.Label>
                  <Form.Control
                    type='text'
                    maxLength={50}
                    onInput={preventSpace}
                    placeholder='name@example.com'
                    value={codeValue.toLowerCase()}
                    {...register('secondaryEmail', {
                      onChange: e => setSecondaryEmail(e.target.value),
                      pattern: {
                        value: validationRegex.email,
                        message: t('REGISTER_EMAIL_EX')
                      }
                    })}
                  />
                  <ErrorMessage message={errors?.secondaryEmail?.message} />
                </Form.Group>
              )}
              {isSecondaryEmailCheck && (
                <div className='email_grp position-relative'>
                  <label className=''>
                    <input
                      type='checkbox'
                      name='isPrimaryEmail'
                      id='isPrimaryEmail'
                      checked={isPrimaryEmail}
                      onChange={e => {
                        setIsPrimaryEmail(e.target.checked)
                        setSecondaryEmailOpen(true)
                      }}
                    />
                    {secondaryEmail}
                  </label>
                  <span className='email_crose'>
                    <button
                      type='button'
                      class='btn-close'
                      aria-label='Close'
                      onClick={() => {
                        setSecondaryEmailOpen(false)
                        setRemoveEmailModal(true)
                      }}
                    />
                  </span>
                </div>
              )}
            </div>

            <Button
              type='button'
              className='px-5 p-3 fs-6 text-white copy_btn link m-10 px-3 mt-4'
              onClick={
                isPrimaryEmail
                  ? onChangePrimaryEmailAlert
                  : !isSecondaryEmailCheck
                  ? handleSubmit(onSubmitSecondaryEmail)
                  : ''
              }
              title={t('O_PROCEED')}
            >
              {t('O_PROCEED')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={removeEmailModal}
        centered
        className='verify_modal email_detail'
      >
        <Modal.Header closeButton={false} title={t('O_CLOSE')}>
          <Modal.Title className='d-flex justify-content-center align-item w-100'>
            {t('ALPay')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center p-3 '>
          <h6> {t('ARE_YOU_SURE_WANT_TO_REMOVE')}</h6>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-between align-center w-100'>
            <Button
              className='dev-remove-modal'
              onClick={deleteEmailModalClose}
              title={t('O_CANCEL')}
            >
              {t('O_CANCEL')}
            </Button>

            <Button
              className='dev-remove-modal'
              onClick={deleteSecondaryEmail}
              title={t('OK')}
            >
              {t('OK')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isPrimaryEmailAlert}
        centered
        className='verify_modal email_detail'
      >
        <Modal.Header closeButton={false} title={t('O_CLOSE')}>
          <Modal.Title className='d-flex justify-content-center align-item w-100'>
            {t('ALPay')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center p-3 '>
          <h6>{t('PRIMARY_EMAIL_ALERT_TEXT')}</h6>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-between align-center w-100'>
            <Button
              className='dev-remove-modal'
              onClick={() => {
                setIsPrimaryEmailAlert(false)
                setSecondaryEmailOpen(false)
                setIsPrimaryEmail(false)
              }}
              title={t('O_CANCEL')}
            >
              {t('O_CANCEL')}
            </Button>

            <Button
              className='dev-remove-modal'
              onClick={changePrimaryEmail}
              title={t('OK')}
            >
              {t('OK')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={secondaryMobileNoOpen}
        onHide={handleCloseSecondaryMobileNo}
        centered
        className='verify_modal email_detail'
      >
        <Modal.Header closeButton title={t('O_CLOSE')}>
          <Modal.Title>
            {' '}
            {t('SET_PRIMARY_PHONE')}
            <small className='d-block email_small_head'>
              {t('ONLY_TWO_MOBILE_NO_CAN_BE_ADDED')}
            </small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center p-3'>
          <div className='email_form'>
            <div className='mb-3'>
              <div className='email_grp position-relative'>
                <label className=''>
                  <input
                    type='checkbox'
                    name=''
                    checked={isPrimaryMobile === false}
                    id=''
                  />
                  +355 {profile?.mobile}
                </label>
              </div>
              {!isSecondaryMobileCheck && (
                <Form.Group className='my-3' controlId='formBasicEmail'>
                  <Form.Label>
                    <span className='plushcricle'>+</span>
                    {t('ADD_NUMBER')}
                  </Form.Label>
                  <div className='d-flex with_country'>
                    <span className='country_code'>+355</span>
                    <Form.Control
                      id='secondaryMobile'
                      type='number'
                      inputMode='numeric'
                      onKeyPress={e => integerInput(e)}
                      onInput={e => preventMax(e, 9)}
                      placeholder='Mobile number'
                      onChange={e => {
                        setSecondaryMobile(e.target.value)
                        if (e.target.value.length == 9) {
                          setSecondaryMobileNumberError('')
                        }
                      }}
                    />
                  </div>
                  {secondaryMobileNumberError && (
                    <ErrorMessage message={secondaryMobileNumberError} />
                  )}
                </Form.Group>
              )}
              {isSecondaryMobileCheck && (
                <div className='email_grp position-relative'>
                  <label className=''>
                    <input
                      type='checkbox'
                      name='isPrimaryMobile'
                      id='isPrimaryMobile'
                      checked={isPrimaryMobile}
                      onChange={e => {
                        setIsPrimaryMobile(e.target.checked)
                        setSecondaryMobileNoOpen(true)
                      }}
                    />
                    +355 {secondaryMobile}
                  </label>
                  <span className='email_crose'>
                    <button
                      type='button'
                      class='btn-close'
                      aria-label='Close'
                      onClick={() => {
                        setSecondaryMobileNoOpen(false)
                        setRemoveMobileModal(true)
                      }}
                    />
                  </span>
                </div>
              )}
            </div>

            <Button
              className='px-5 p-3 fs-6 text-white copy_btn link m-10 px-3 mt-4'
              onClick={
                isPrimaryMobile
                  ? onChangePrimaryMobileAlert
                  : !isSecondaryMobileCheck
                  ? onSubmitSecondaryMobile
                  : ''
              }
              title={t('O_PROCEED')}
            >
              {t('O_PROCEED')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={removeMobileModal}
        centered
        className='verify_modal email_detail'
      >
        <Modal.Header closeButton={false} title={t('O_CLOSE')}>
          <Modal.Title className='d-flex justify-content-center align-item w-100'>
            {t('ALPay')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center p-3 '>
          <h6>{t('ARE_YOU_SURE_WANT_TO_REMOVE')}</h6>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-between align-center w-100'>
            <Button
              className='dev-remove-modal'
              onClick={deleteMobileModalClose}
              title={t('O_CANCEL')}
            >
              {t('O_CANCEL')}
            </Button>

            <Button
              className='dev-remove-modal'
              onClick={deleteSecondaryMobile}
              title={t('OK')}
            >
              {t('OK')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isPrimaryMobileAlert}
        centered
        className='verify_modal email_detail'
      >
        <Modal.Header closeButton={false} title={t('O_CLOSE')}>
          <Modal.Title className='d-flex justify-content-center align-item w-100'>
            {t('ALPay')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center p-3 '>
          <h6>{t('PRIMARY_NUMBER_ALERT_TEXT')}</h6>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-between align-center w-100'>
            <Button
              className='dev-remove-modal'
              onClick={() => {
                setIsPrimaryMobileAlert(false)
                setSecondaryMobileNoOpen(false)
                setIsPrimaryMobile(false)
              }}
              title={t('O_CANCEL')}
            >
              {t('O_CANCEL')}
            </Button>

            <Button
              className='dev-remove-modal'
              onClick={changePrimaryMobile}
              title={t('OK')}
            >
              {t('OK')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Profile
