import { Navigate, Outlet } from 'react-router-dom'
import { useContext } from 'react'
import { isEmpty } from 'lodash'
import MainLayout from '../layout/MainLayout'
import AuthContext from '../context/AuthContext'
const PrivateRoute = ({ children, ...rest }) => {
  let { user } = useContext(AuthContext)
  return !isEmpty(user) ? (
    <>
      <MainLayout>
        <Outlet />
      </MainLayout>
    </>
  ) : (
    <Navigate to='/register' />
  )
}

export default PrivateRoute
