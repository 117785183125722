import { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import Footer from '../components/Footer'
import LoanTab from './LoanTab'
import pathObj from 'utils/apiPath'
import { apiGet } from 'utils/apiFetch'
import ReactPaginate from 'react-paginate'
import { useTranslation } from 'react-i18next'

function LoanStatus () {
  const { t } = useTranslation()
  const [defaultKey, setDefaultKey] = useState('pending')
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)

  const handleSelect = async activeKey => {
    setPage(1)
    setDefaultKey(activeKey)
  }
  const handlePageClick = async event => {
    setPage(event.selected + 1)
  }
  const getData = async () => {
    try {
      const path =
        pathObj.loanStatus + '?status=' + defaultKey + '&page=' + page
      const result = await apiGet(path)
      if (result?.data?.success) {
        setData(result?.data?.results)
      }
    } catch (error) {
      console.log('getBalance==>', error)
    }
  }
  const Pagination = () => {
    const pageCount = Math.ceil(data.totalPages)

    if (data?.docs?.length > 0) {
      return (
        <div id='react-paginate'>
          <ReactPaginate
            previousLabel={t('O_PREVIOUS')}
            nextLabel={t('O_NEXT')}
            breakLabel={<a href=''>...</a>}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName='pagination'
            subContainerClassName='pages pagination'
            activeClassName='active'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='page-item'
            previousLinkClassName='page-link'
            nextClassName='page-item'
            nextLinkClassName='page-link'
            forcePage={page - 1}
          />
        </div>
      )
    }
  }

  useEffect(() => {
    getData()
  }, [defaultKey, page])

  return (
    <>
      <div className='main_wrap px-0'>
        <div className='m-0 py-sm-4 py-3 sidebar_pageHeading fullPage_space'>
          <h5 className='mb-0 text-white'>
            <img src='./images/sidebar_icon6.svg' alt='' className='me-2' />{' '}
            {t('NAV_LOAN_STATUS')}
          </h5>
        </div>

        <div className='bg-light main_panel rounded-0 fullPage_space py-4 loan_status'>
          <Tabs
            defaultActiveKey='pending'
            id='uncontrolled-tab-example'
            className='mb-3 border-0 justify-content-sm-between'
            onSelect={handleSelect}
          >
            <Tab eventKey='pending' title={t('PENDING')}>
              <LoanTab defaultKey={defaultKey} data={data} />
            </Tab>
            <Tab eventKey='approved' title={t('APPROVED')}>
              <LoanTab defaultKey={defaultKey} data={data} getData={getData} />
            </Tab>
            <Tab eventKey='cancelled' title={t('CANCELLED')}>
              <LoanTab defaultKey={defaultKey} data={data} />
            </Tab>
            <Tab eventKey='completed' title={t('COMPLETED')}>
              <LoanTab defaultKey={defaultKey} data={data} />
            </Tab>
          </Tabs>
          <Pagination />
        </div>
        <div className='fullPage_space'>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default LoanStatus
