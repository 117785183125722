import React, { useContext, useEffect, useState } from 'react'
import AuthContext from 'context/AuthContext'
import { Container, Accordion, Col } from 'react-bootstrap'
import HomeHeader from 'components/HomeHeader'
import HomeFooter from 'components/HomeFooter'
import { apiGet } from 'utils/apiFetch'
import { useTranslation } from 'react-i18next'
import pathObj from 'utils/apiPath'

export default function Faqs () {
  const { language } = useContext(AuthContext)

  const [faqs, setFaqs] = useState([])

  const getFaqs = async () => {
    try {
      const res = await apiGet(pathObj.getFaqs)
      setFaqs(res?.data?.results)
    } catch (err) {
      console.log('err:', err)
    }
  }
  const { t } = useTranslation()
  useEffect(() => {
    getFaqs()
  }, [language])

  return (
    <>
      <HomeHeader />
      <section className='faq-sec'>
        <Container className=''>
          <h2 className='section-title text-center mt-5'>{t('FOOTER_FAQS')}</h2>
          <div className='row align-items-center'>
            <Col md={7}>
              <Accordion defaultActiveKey='0'>
                {faqs?.map((item, i) => {
                  return (
                    <Accordion.Item eventKey={i}>
                      <Accordion.Header>{item?.title}</Accordion.Header>
                      <Accordion.Body>{item?.content}</Accordion.Body>
                    </Accordion.Item>
                  )
                })}
              </Accordion>
            </Col>
            <Col md={5}>
              <div className='ms-lg-3 text-center text-lg-end'>
                <img
                  src={
                    language == 1
                      ? '../images/new/faq-crypt.png'
                      : '../images/new/al-faq-crypt.png'
                  }
                  alt=''
                />
              </div>
            </Col>
          </div>
        </Container>
      </section>
      <HomeFooter />
    </>
  )
}
