import React from 'react'
import {
  Breadcrumb,
  Button,
  FloatingLabel,
  Form,
  Row,
  Col
} from 'react-bootstrap'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function Electricitybill () {
  const { t } = useTranslation()
  return (
    <>
      <Header />

      <div className='main_wrap'>
        <Breadcrumb className='m-0 py-3'>
          <Breadcrumb.Item href='#'>Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Electricity Bill</Breadcrumb.Item>
        </Breadcrumb>

        <div className='bg-light-gray main_panel overflow-hidden p-0 electricity_bill'>
          <Row>
            <Col lg={4}>
              <div className='left_form_wrap'>
                <div className='page_heading mb-4'>
                  <h2 className='mb-0'>Electricity Bill</h2>
                </div>
                <Form className=''>
                  <Form.Group className='mb-3'>
                    <FloatingLabel
                      controlId='floatingSelectGrid'
                      label='Operator'
                    >
                      <Form.Select aria-label='Floating label select example'>
                        <option>State</option>
                        <option value='1'>One</option>
                        <option value='2'>Two</option>
                        <option value='3'>Three</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <FloatingLabel
                      controlId='floatingSelectGrid'
                      label='Operator'
                    >
                      <Form.Select aria-label='Floating label select example'>
                        <option>Electricity Board</option>
                        <option value='1'>One</option>
                        <option value='2'>Two</option>
                        <option value='3'>Three</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      <Form.Control
                        id='floatingInputCustom1'
                        type='text'
                        placeholder='name@example.com'
                      />
                      <label htmlFor='floatingInputCustom'>
                        Consumer Number
                      </label>
                    </Form.Floating>
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      <Form.Control
                        id='floatingInputCustom1'
                        type='text'
                        placeholder='name@example.com'
                      />
                      <label htmlFor='floatingInputCustom'>
                        Have a promocode?
                      </label>
                    </Form.Floating>
                  </Form.Group>

                  <Button className='btn btn_theme py-3 w-100'>
                    Proceed to recharge{' '}
                  </Button>
                </Form>

                <div className='promo_list pt-3 pt-md-5'>
                  <div className='midium_title d-flex align-content-center justify-content-between'>
                    <h3 className='mb-0'>Promo code</h3>
                    <Link to='/' className='text-decoration-none'>
                      View More
                    </Link>
                  </div>

                  <div className='promo_card'>
                    <figure>
                      <img src='images/promo.png' alt='' />
                    </figure>
                    <figcaption>
                      <h4>Get 10% Off on All Recharge </h4>
                      <p>There are many variations of passages</p>
                      <div className='promo_code'>
                        <strong>CODE </strong> - XXX555521
                      </div>
                      <span className='expire_date'>Expire - 12 July 2022</span>
                      <Link to='/' className='copy_tag'>
                        Copy
                      </Link>
                    </figcaption>
                  </div>

                  <div className='promo_card'>
                    <figure>
                      <img src='images/promo.png' alt='' />
                    </figure>
                    <figcaption>
                      <h4>Get 10% Off on All Recharge </h4>
                      <p>There are many variations of passages</p>
                      <div className='promo_code'>
                        <strong>CODE </strong> - XXX555521
                      </div>
                      <span className='expire_date'>Expire - 12 July 2022</span>
                      <Link to='/' className='copy_tag'>
                        Copy
                      </Link>
                    </figcaption>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={8}>
              <div className='right_inner_wrap'>
                <div className='promo_value_list d-sm-flex d-block align-items-center text-sm-start text-center  bg-white'>
                  <figure className='m-sm-0 m-auto'>
                    <img src='images/promologo.png' alt='' />
                  </figure>
                  <figcaption className='d-sm-flex d-block align-items-center mt-sm-0 mt-3'>
                    <strong>
                      {' '}
                      <Link to='/' className='promo-small-title '>
                        Central Power Distribution corporation
                      </Link>
                    </strong>
                    <Link
                      to='/dth'
                      className='btn btn_theme d-block mt-sm-0 mt-3'
                    >
                      {t('O_LEK')}:299
                    </Link>
                  </figcaption>
                </div>
                <div className='promo_value_list d-sm-flex d-block align-items-center text-sm-start text-center  bg-white'>
                  <figure className='m-sm-0 m-auto'>
                    <img src='images/promologo.png' alt='' />
                  </figure>
                  <figcaption className='d-sm-flex d-block align-items-center mt-sm-0 mt-3'>
                    <strong>
                      {' '}
                      <Link to='/' className='promo-small-title '>
                        Central Power Distribution corporation
                      </Link>
                    </strong>
                    <Link
                      to='/dth'
                      className='btn btn_theme d-block mt-sm-0 mt-3'
                    >
                      {t('O_LEK')}:299
                    </Link>
                  </figcaption>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <Footer />
      </div>
    </>
  )
}

export default Electricitybill
