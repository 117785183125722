import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import Footer from '../components/Footer'
import { apiGet } from 'utils/apiFetch'
import pathObj from 'utils/apiPath'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

function OfferPromo () {
  const { t } = useTranslation()
  const [promoCode, setPromoCode] = useState([])
  const getBalance = async () => {
    try {
      const path = pathObj.promoCodes
      const result = await apiGet(path)
      console.log('result', result)

      setPromoCode(result?.data?.results)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    getBalance()
  }, [])

  return (
    <>
      <div className='main_wrap px-0 refer_earn'>
        <div className='m-0 py-4 sidebar_pageHeading fullPage_space'>
          <h5 className='mb-0 text-white'>
            <img src='./images/sidebar_icon5.svg' alt='' className='me-2' />{' '}
            {t('O_OFFERS_AND_PROMO_CODES')}
          </h5>
        </div>
        <div className='bg-light main_panel rounded-0 p-3 p-md-4 myOrder'>
          <div className='rounded-0 overflow-hidden bg-white  p-3 p-md-4'>
            <Row>
              {promoCode?.length &&
                promoCode?.map((val, ind) => {
                  return (
                    <Col md={6} key={ind}>
                      <div className='promo_card align-items-center'>
                        <figcaption className='d-sm-flex p-2'>
                          <div style={{ width: '70%' }}>
                            <h4 className='mb-0'>{val.title} </h4>
                            <p style={{ wordBreak: 'break-all' }}>
                              {val.description}
                            </p>
                            <div className='promo_code'>
                              <strong>{t('OFFERS_PROMO_CODES_CODE')} </strong> -{' '}
                              {val.code}
                            </div>
                          </div>
                          <div className='ms-auto text-sm-end'>
                            <span className='expire_date d-block mb-2'>
                              {t('O_PROMO_CODES_EXPIRE')} -{' '}
                              {dayjs(val.validity).format('DD/MM/YYYY HH:MM A')}
                            </span>
                            <Link
                              to=''
                              className='copy_tag position-static'
                              onClick={() => {
                                navigator.clipboard.writeText(val?.code)
                                alert(
                                  'Promo code has been copied to your clipboard.'
                                )
                              }}
                            >
                              {t('O_COPY')}
                            </Link>
                          </div>
                        </figcaption>
                      </div>
                    </Col>
                  )
                })}
            </Row>

            {!promoCode.length ? (
              <>
                <div style={{ textAlign: 'center', fontWeight: '500' }}>
                  {t('O_NO_RECORD_FOUND')}
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className='fullPage_space'>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default OfferPromo
