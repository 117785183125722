import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Modal,
  Form,
  Offcanvas,
  Row,
  Col,
  Button,
  FloatingLabel,
  Spinner
} from 'react-bootstrap'
import Footer from '../components/Footer'
import { apiGet, apiPost } from 'utils/apiFetch'
import pathObj from 'utils/apiPath'
import { FaRegUser } from 'react-icons/fa'
import OImage from 'components/reusable/OImage'
import OMap from 'components/shared/Map/Map'
import useToastContext from 'hooks/useToastContext'
import Slider from 'react-input-slider'
import { useTranslation } from 'react-i18next'
import NearbyMerchant from '../assets/images/Nearby-merchants_white.png'
import AuthContext from 'context/AuthContext'

function Location () {
  const { t } = useTranslation()
  const [show1, setShow1] = useState(false)

  const handleClose1 = () => setShow1(false)

  const notification = useToastContext()
  const { language } = useContext(AuthContext)

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const [nearByMerchants, setNearByMerchants] = useState([])

  const [merchantDetails, setMerchantDetails] = useState(null)

  // filter states
  const [keyword, setKeyword] = useState('')
  const [categoryId, setCategoryId] = useState('')
  const [subCategoryId, setSubCategoryId] = useState('')
  const mapRange = useRef(10)

  const [categoryList, setCategoryList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [subCatList, setSubCatList] = useState([])

  const [currentCoordinate, setCurrentCoordinate] = useState({
    lat: 0,
    lng: 0
  })

  const [merchantsLoading, setMerchantLoading] = useState(false)

  const findNearbyMerchants = async () => {
    let params = {
      range: mapRange.current,
      keyword,
      categoryId: categoryId,
      subCategoryId: subCategoryId?.value
    }

    navigator.geolocation.getCurrentPosition(function (location) {
      params.latitude = location.coords.latitude
      params.longitude = location.coords.longitude

      setCurrentCoordinate({
        lat: location?.coords?.latitude,
        lng: location.coords.longitude
      })
      if (location.coords.latitude && location.coords.longitude) {
        locationCallback(params)
      }
    })
  }

  const locationCallback = async params => {
    try {
      console.log(params)

      setMerchantLoading(true)
      const res = await apiGet(pathObj.nearbyMerchants, params)
      const nearbyMerchantsUpdate = []
      res.data.results?.map((item, i) => {
        if (
          item?.location?.coordinates?.[0] &&
          item?.location?.coordinates?.[1]
        ) {
          nearbyMerchantsUpdate.push(item)
        }
      })
      setNearByMerchants(nearbyMerchantsUpdate)

      setMerchantLoading(false)
    } catch (error) {
      console.log('error:', error)
      setMerchantLoading(false)
    }
  }

  // FOR MAP FUNCTIONS

  const getCatSubCatList = async () => {
    try {
      const res = await apiGet(pathObj.getCategorySubCategoryList)
      setCategoryList(res?.data?.results?.category_list)
      setSubCatList(res?.data?.results?.sub_category_list)
      const localCatOptions = []
      res?.data?.results?.category_list?.map((item, i) => {
        localCatOptions.push({
          value: item?._id,
          label: item?.name
        })
      })
      setCategoryOptions(localCatOptions)
    } catch (error) {
      console.log('error:', error)
    }
  }

  const [categoryOptions, setCategoryOptions] = useState([])
  const [catSubCatOptions, setCatSubCatOptions] = useState([])

  const handleReset = () => {
    setKeyword('')
    setCategoryId('')
    setSubCategoryId('')

    mapRange.current = 10
    findNearbyMerchants()
  }

  const [selectedDeals, setSelectedDeals] = useState([])

  const handleOfferBuy = async () => {
    if (!selectedDeals?.length) {
      return
    }
    try {
      setIsLoading(true)
      const sendData = {
        merchantId: merchantDetails?._id,
        offerIds: JSON.stringify([...selectedDeals])
      }

      const res = await apiPost(pathObj.postDeals, sendData)
      if (res.data.success) {
        notification.success(res?.data?.message)
      }
    } catch (error) {
      console.error('error:', error)
    }
    setIsLoading(false)
  }

  const categoryChangeOption = value => {
    setCategoryId(value?.target?.value)
  }
  const subCategoryChangeOption = value => {
    const data = {
      value: value?.target?.value,
      label: value?.target.label
    }
    setSubCategoryId(data)
  }

  const handleDealSelection = (e, item) => {
    if (e.target.checked) {
      setSelectedDeals([...selectedDeals, item?._id])
    } else {
      if (selectedDeals.includes(item?._id)) {
        let localDeals = [...selectedDeals]
        localDeals = localDeals.filter(row => row !== item?._id)
        setSelectedDeals(localDeals)
      }
    }
  }

  useEffect(() => {
    // preparing subcategory options
    const localSubCatOptions = []
    subCatList?.map((item, i) => {
      if (categoryId && categoryId === item?.category_id) {
        localSubCatOptions.push({
          value: item?._id,
          label: item?.name
        })
      }
    })
    setCatSubCatOptions(localSubCatOptions)
  }, [categoryId, language])
  useEffect(() => {
    if (keyword || categoryId || subCategoryId || mapRange.current) {
      findNearbyMerchants()
    }
  }, [keyword, categoryId, subCategoryId, language])

  useEffect(() => {
    getCatSubCatList()
  }, [language])
  useEffect(() => {
    findNearbyMerchants()
  }, [mapRange, keyword, categoryId, subCategoryId, language])
  return (
    <>
      <div className='main_wrap  px-0'>
        <div className='m-0 py-4 sidebar_pageHeading fullPage_space'>
          <h5 className='mb-0 text-white'>
            <img src={NearbyMerchant} alt='' className='me-2' />
            {t('NAV_NEARBY_MERCHANTS')}
          </h5>
        </div>
        <div className='mx-7' style={{ margin: '0 4rem' }}>
          <div
            className='bg-white overflow-hidden map_right'
            style={{ borderRadius: '15px' }}
          >
            <Row className='g-0'>
              <Col md={6} style={{ minHeight: '800px' }}>
                <div className='position-relative google-map-image'>
                  {nearByMerchants?.length > 0 ? (
                    <OMap
                      nearByMerchants={nearByMerchants}
                      setMerchantDetails={setMerchantDetails}
                      currentCoordinate={currentCoordinate}
                    />
                  ) : (
                    <>
                      {merchantsLoading ? (
                        <div className='text-center mt-3 fw-bold fs-5'>
                          {t('NEARBY_MERCHANT_LOADING')}...
                        </div>
                      ) : (
                        <>
                          <OMap
                            nearByMerchants={nearByMerchants}
                            setMerchantDetails={setMerchantDetails}
                            currentCoordinate={currentCoordinate}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </Col>
              <Col md={6} className='px-3 px-sm-4 py-md-4 py-sm-4 py-3'>
                <div>
                  <section className='d-flex flex-wrap'>
                    <Form.Group className='mb-3 '>
                      <Button
                        className='btn btn_theme'
                        style={{
                          height: '40px',
                          width: '100px',
                          padding: '5px 10px'
                        }}
                        onClick={() => setShow(true)}
                        title={t('O_FILTER')}
                      >
                        {t('O_FILTER')}
                      </Button>
                    </Form.Group>
                  </section>
                </div>
                {merchantDetails && (
                  <main>
                    <div className='d-flex align-items-center locate_mark mb-3'>
                      <div className='icon rounded-circle border p-0 me-3'>
                        {merchantDetails?.business_logo ? (
                          <OImage
                            src={merchantDetails?.business_logo}
                            style={{
                              width: '2rem',
                              height: '2rem',
                              borderRadius: '50%'
                            }}
                          />
                        ) : (
                          <FaRegUser />
                        )}
                      </div>
                      <h5 className='m-0 link'>
                        {`${merchantDetails?.first_name} ${merchantDetails?.last_name} `}
                        <span className='text-dark fw-normal fs-6 d-block mt-1'></span>
                      </h5>
                      <span className='km ms-auto border rounded-2 p-1 px-2 fs-6 text-grey'>
                        <img src='./images/locate.svg'></img>{' '}
                        {Math.round(merchantDetails?.distance)} Km
                      </span>
                    </div>

                    <h6 className='sCategory mb-3'>
                      {t('NEARBY_MERCHANT_SUB_CATEGORY')}{' '}
                      {merchantDetails?.sub_category?.name && (
                        <span
                          className='border rounded-4 p-2 fw-normal ms-3'
                          style={{ textTransform: 'capitalize' }}
                        >
                          {merchantDetails?.sub_category?.name}
                        </span>
                      )}
                    </h6>
                    <h6>{t('NEARBY_MERCHANT_CONTACT')}</h6>
                    <div className='d-flex flex-wrap contact_info mb-4'>
                      <div className='text'>
                        <p className='mb-2 fs-6'>
                          {merchantDetails?.business_address}
                        </p>
                        <p className='mb-2 fs-6'></p>
                        <Link to='' className='link fs-6'>
                          {`+${merchantDetails?.country_code} ${merchantDetails?.mobile}`}
                        </Link>
                      </div>
                      <div className='ms-auto d-flex align-items-center'>
                        <a
                          className='theme-border rounded-circle p-1 d-flex align-items-center justify-content-center'
                          href={`http://maps.google.com/maps?q=${merchantDetails?.location?.coordinates[1]},${merchantDetails?.location?.coordinates[0]}`}
                          target='_blank'
                        >
                          <img src='./images/nav.svg' alt='' />
                        </a>
                        <a
                          className='theme-border rounded-circle p-1 d-flex align-items-center justify-content-center ms-2'
                          href={`tel:344${merchantDetails?.mobile}`}
                        >
                          <img src='./images/call.svg' alt='' />
                        </a>
                      </div>
                    </div>
                    <h6>{t('NEARBY_MERCHANT_ABOUT_STORE')}</h6>

                    <p>
                      {merchantDetails?.description ? (
                        merchantDetails?.description
                      ) : (
                        <>{t('NEARBY_MERCHANT_NO_DESCRIPTION')}</>
                      )}
                    </p>

                    <div className='deals mt-4'>
                      <h6>{t('NEARBY_MERCHANT_DEALS')}</h6>
                      {!merchantDetails?.deals?.length ? (
                        <div>{t('NEARBY_MERCHANT_NO_DEALS')} </div>
                      ) : (
                        <div>
                          {merchantDetails?.deals?.map((item, i) => {
                            return (
                              <div
                                className='border rounded-3 p-2 d-flex align-items-center flex-wrap mb-2'
                                key={i}
                              >
                                <figure className='mb-0'>
                                  <OImage
                                    src={
                                      item?.image
                                        ? item?.image
                                        : './images/deals.png'
                                    }
                                    style={{ width: '60px', height: '60px' }}
                                  />
                                </figure>
                                <figcaption className='pe-2 ps-3'>
                                  <h6 className=' mb-2'>{item?.title}</h6>
                                  <h6 className='link fw-normal mb-1'>
                                    {t('O_LEK')}:{' '}
                                    <span>
                                      <del>{item?.actualPrice}</del>
                                    </span>{' '}
                                    {item?.offerPrice}
                                  </h6>
                                  <p className='fs-6  mb-0'>
                                    {item?.description}
                                  </p>
                                </figcaption>
                                <div className='default_checkbox ms-auto'>
                                  <span className='custom_check'>
                                    &nbsp;{' '}
                                    <input
                                      type='checkbox'
                                      onClick={e =>
                                        handleDealSelection(e, item)
                                      }
                                    />
                                    <span className='check_indicator'>
                                      &nbsp;
                                    </span>
                                  </span>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      )}

                      {selectedDeals?.length > 0 &&
                        (isLoading ? (
                          <Button className='w-100 btn_theme mt-4'>
                            <Spinner animation='border' size='sm' />
                          </Button>
                        ) : (
                          <Button
                            className='w-100 btn_theme mt-4'
                            onClick={() => handleOfferBuy()}
                          >
                            {t('NEARBY_MERCHANT_BUY_NOW')}
                          </Button>
                        ))}
                    </div>
                  </main>
                )}
              </Col>
            </Row>
          </div>
          <Footer />
        </div>
      </div>
      {show ? (
        <Offcanvas show={true} onHide={handleClose} placement='end'>
          <Offcanvas.Header
            closeButton
            className='border-bottom'
            title={t('O_CLOSE')}
          >
            <Offcanvas.Title>{t('O_FILTER')}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form.Group className='mb-3'>
              <FloatingLabel controlId='floatingSelectGrid' label='Category'>
                <Form.Select
                  aria-label='Floating label select example'
                  onChange={categoryChangeOption}
                  value={categoryId}
                  title='Category'
                >
                  <option value={''} label={''}>
                    {t('NEARBY_MERCHANT_SELECT_AN_OPTION')}
                  </option>
                  {categoryOptions.map((val, ind) => {
                    return (
                      <option value={val.value} key={ind}>
                        {val.label}
                      </option>
                    )
                  })}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group className='mb-3'>
              <FloatingLabel
                controlId='floatingSelectGrid'
                label='Sub category'
              >
                <Form.Select
                  aria-label='Floating label select example'
                  onChange={subCategoryChangeOption}
                  title='Sub category'
                >
                  <option value={null} label={null}>
                    {t('NEARBY_MERCHANT_SELECT_AN_OPTION')}
                  </option>
                  {catSubCatOptions.map((val, ind) => {
                    return (
                      <option
                        value={val.value}
                        label={val.label}
                        hidden={val.value === ''}
                        key={ind}
                      >
                        {val.value}
                      </option>
                    )
                  })}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>

            <Form.Group className='mb-4'>
              <p style={{ fontSize: '16px' }}>
                {t('NEARBY_MERCHANT_SELECT_RANGE')} (km)
              </p>
              <div style={{ textAlign: 'center', width: '100%' }}>
                {mapRange.current}km
              </div>
              <div className='d-flex align-items-center'>
                <span style={{ marginRight: '3px' }}>1</span>
                {'  '}

                <Slider
                  axis='x'
                  x={mapRange.current}
                  onChange={({ x }) => {
                    mapRange.current = x
                  }}
                  onDragEnd={e => {
                    findNearbyMerchants()
                  }}
                  styles={{
                    width: '100%',
                    track: {
                      backgroundColor: '#F9F6EE'
                    },
                    active: {
                      backgroundColor: '#6104f7'
                    },
                    thumb: {
                      width: 20,
                      height: 20
                    },
                    disabled: {
                      opacity: 0.5
                    }
                  }}
                />

                <span style={{ marginLeft: '3px' }}>100</span>
              </div>
            </Form.Group>
          </Offcanvas.Body>
          <div className='refer_bottom pb-3 text-center'>
            <Link
              to=''
              className='btn-sm btn bg-white text-dark me-3 link px-5 py-2 copy_btn rounded-2 '
              onClick={handleReset}
              title={t('NEARBY_MERCHANT_RESET')}
            >
              {t('NEARBY_MERCHANT_RESET')}
            </Link>
          </div>{' '}
        </Offcanvas>
      ) : null}

      <Modal
        show={show1}
        onHide={handleClose1}
        centered
        className='verify_modal track_modal'
      >
        <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center p-5'>
          <img src='./images/check.png' alt='' className='mb-4 opacity-1' />
          <h4>{t('O_CONGRATULATION')}!</h4>
          <p className='text-center'>{t('NEARBY_MERCHANT_BOUGHT_DEAL')}</p>
          <Link to='/' className='btn btn_theme mb-2'>
            {t('NEARBY_MERCHANT_GO_HOME')}
          </Link>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Location
