import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { apiGet, apiPost } from '../utils/apiFetch'
import pathObj from '../utils/apiPath'
import { useForm } from 'react-hook-form'
import { Row, Form, Col, Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Footer from '../components/Footer'
import ErrorMessage from 'components/reusable/ErrorMessage'
import useToastContext from 'hooks/useToastContext'

function TransferToSelfBank () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const notification = useToastContext()
  const [show, setShow] = useState(false)
  const [amount, setAmount] = useState(null)
  const [showFirst, setShowFirst] = useState(true)
  const [showSecond, setShowSecond] = useState(false)
  const [showThird, setShowThird] = useState(false)
  const [bankList, setBankList] = useState([])
  const [bank, setBank] = useState(null)
  const [commission, setCommission] = useState(null)
  const [resultMessage, setResultMessage] = useState('Data Fatching...')

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit
  } = useForm()

  const handleClose = () => {
    setShow(!show)
    navigate('/dashboard')
  }
  const url = resultMessage?.success
    ? './images/check.png'
    : './images/wrong.png'

  const bankListData = async () => {
    const path = pathObj.bankList
    const result = await apiGet(path)
    setBankList(result?.data?.results)
  }
  const bankCommissionData = async () => {
    const path = pathObj.bankCommission
    const result = await apiGet(path)
    setCommission(result?.data?.results)
  }

  const finalSubmit = async () => {
    const result = await apiPost(pathObj.transferToBank, {
      amount,
      bankAccountId: bank?._id
    })
    if (result?.data?.success === true) {
      setResultMessage(result?.data)
      setShow(true)
    } else {
      notification.error(result?.data?.message)
    }
  }

  const handleBankChange = (e, item) => {
    setShowThird(false)
    setBank(item)
  }
  const handleChangeAmount = e => {
    var value = e.target.value
    const pattern = /^\d{0,5}(\.\d{0,2})?$/
    if (e.target.value === '') {
      e.target.value = e.target.value.slice(e.target.value?.length - 1, -1)
    }
    const isValid = pattern.test(value)
    if (!isValid) {
      value = value.slice(0, -1)
    }
    setValue('amount', value)
    setShowSecond(false)
    setShowThird(false)
    setBank(null)
    setAmount(parseFloat(e.target.value))
  }

  const submitBankChange = e => {
    if (bank != null) {
      setShowThird(true)
    } else {
      notification.error('Please select a bank.')
    }
  }

  const onSubmit = data => {
    setShowSecond(true)
  }

  useEffect(() => {
    bankListData()
    bankCommissionData()
  }, [])

  var pressedKeys = []

  return (
    <>
      <div className='main_wrap px-0 wallet_statement'>
        <div className='m-0 py-4 sidebar_pageHeading fullPage_space'>
          <h5 className='mb-0 text-white'>
            <img src='./images/sidebar_icon5.svg' alt='' className='me-2' />{' '}
            {t('O_TRANSFER_MONEY_TO_BANK')}
          </h5>
        </div>

        <div className='bg-light-gray main_panel rounded-0 p-3 p-md-4'>
          <div className='rounded-0 overflow-hidden bg-white  p-3 p-md-4'>
            <Row>
              {showFirst ? (
                <Col lg={4}>
                  <div className='transfer_money_block'>
                    <h3 className='mb-2'>
                      {' '}
                      {t('SEND_MONEY_FROM_WALLET_TO_BANK')}
                    </h3>

                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Form.Floating className='w-lg-50 w-100'>
                        <Form.Control
                          id='amount'
                          type='number'
                          name='amount'
                          step='any'
                          {...register('amount', {
                            required: t('PLEASE_ENTER_AMOUNT'),
                            onChange: e => handleChangeAmount(e)
                          })}
                          onKeyDown={e => {
                            pressedKeys.push(e.key)
                            var lastKey = pressedKeys[pressedKeys.length - 2]
                            if (lastKey == '.') {
                              if (['-', '.', '+', 'e'].includes(e.key)) {
                                e.preventDefault()
                              }
                            } else if (['-', '+', 'e'].includes(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        <label htmlFor='floatingInputCustom1'>
                          {t('O_AMOUNT')}
                          <span className='text-danger'>*</span>
                        </label>
                      </Form.Floating>
                      <ErrorMessage message={errors.amount?.message} />

                      <Button
                        className='btn btn_theme my-3 w-100'
                        type='submit'
                      >
                        {t('O_PROCEED')}
                      </Button>
                    </Form>
                  </div>
                </Col>
              ) : (
                ''
              )}
              {showSecond ? (
                <Col lg={4}>
                  <div className='bank_list_block px-0 px-lg-5'>
                    <h3 className='mb-2'> {t('SELECT_BANK')}</h3>
                    {bankList?.map((item, i) => (
                      <div className='bank_list d-flex align-items-center mb-2 p-2 rounded-3'>
                        <div className='bank_list_left d-flex flex-1 align-items-center'>
                          <figure className='mb-0 me-2'>
                            <img src='../images/bank.jpg' />
                          </figure>
                          <strong>{item?.bankName}</strong>
                        </div>

                        <div class='form-check p-0'>
                          <input
                            class=' m-0'
                            type='radio'
                            value={item?._id}
                            name='flexRadioDefault'
                            id='flexRadioDefault1'
                            onChange={e => handleBankChange(e, item)}
                          />{' '}
                          <span className='custom_radio'></span>
                        </div>
                      </div>
                    ))}

                    <Button
                      className='btn btn_theme my-3 w-100'
                      onClick={submitBankChange}
                    >
                      {t('O_PROCEED')}
                    </Button>
                  </div>
                </Col>
              ) : (
                ''
              )}

              {showThird ? (
                <Col lg={4}>
                  <div className='bank_detail'>
                    <h3 className='mb-2'> {t('O_SENDING_MONEY_TO_BANK')}</h3>
                    <ul className='list-unstyled'>
                      <li>
                        {t('A/C_NO')} {bank?.accountNo}
                      </li>
                      <li className='d-flex align-items-center justify-content-between'>
                        <span>{t('AMOUNT_TO_BE_SENT')}</span>
                        <span>
                          {t('O_LEK')} {amount}
                        </span>
                      </li>
                      <li className='d-flex align-items-center justify-content-between'>
                        <span>
                          {t('CHARGES')} ({commission?.withdrawToBankCommission}
                          %)
                        </span>
                        <span>
                          {(
                            (amount * commission?.withdrawToBankCommission) /
                            100
                          ).toFixed(2)}
                        </span>
                      </li>
                    </ul>

                    <div className='totel_amount d-flex align-items-center justify-content-between'>
                      <span>{t('TO_PAYABLE')}</span>
                      <span>
                        {t('O_LEK')}{' '}
                        {(
                          amount +
                          (amount * commission?.withdrawToBankCommission) / 100
                        ).toFixed(2)}
                      </span>
                    </div>

                    <Button
                      className='btn btn_theme my-3 w-100'
                      onClick={finalSubmit}
                      type='submit'
                    >
                      {t('O_PROCEED')}
                    </Button>
                  </div>
                </Col>
              ) : (
                ''
              )}
            </Row>
          </div>
        </div>
        <div className='fullPage_space'>
          <Footer />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className='verify_modal track_modal'
      >
        <Modal.Header closeButton title={t('O_CLOSE')}>
          <Modal.Title> </Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center p-5'>
          <img src={url} alt='' className='mb-4 opacity-1' />
          <p className='text-center'>
            {resultMessage?.message ? resultMessage?.message : null}
          </p>
          <Button
            onClick={() => {
              handleClose()
            }}
            className='btn btn_theme mb-2'
          >
            {t('O_CLOSE')}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TransferToSelfBank
