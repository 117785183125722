import React, { useState, createContext, useEffect, useCallback } from 'react'
import Alert from 'react-bootstrap/Alert'

const ToastContext = createContext()

export default ToastContext
export const ToastContextProvider = ({ children }) => {
  const [toasts, setToasts] = useState([])

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(() => {
        setToasts(toast => toast.slice(1))
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [toasts])

  const success = function (msg) {
    let toast = { msg }
    toast.className = 'success'
    setToasts([toast])
  }

  const error = function (msg) {
    let toast = { msg }
    toast.className = 'danger'
    setToasts([toast])
  }

  const warning = function (msg) {
    let toast = { msg }
    toast.className = 'warning'
    setToasts([toast])
  }

  const info = function (msg) {
    let toast = { msg }
    toast.className = 'info'
    setToasts([toast])
  }

  const notification = {
    success,
    error,
    warning,
    info
  }

  return (
    <ToastContext.Provider value={notification}>
      {children}
      {toasts.map(toast => (
        <Alert
          style={{marginTop:"4rem", marginRight:"1rem", marginLeft:"1rem", borderRadius:"1rem"}}
          key={toast.className}
          variant={toast.className}
          dismissible={false}
        >
          {toast.msg}
        </Alert>
      ))}
    </ToastContext.Provider>
  )
}
