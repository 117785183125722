import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Form, Row, Col } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Footer from '../components/Footer'
import ErrorMessage from 'components/reusable/ErrorMessage'
import { apiPost } from 'utils/apiFetch'
import pathObj from 'utils/apiPath'
import OImage from 'components/reusable/OImage'
import useToastContext from 'hooks/useToastContext'
import { validateFile } from 'utils/reusableMethods'
import { useTranslation } from 'react-i18next'

function PersonalInformation () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const notification = useToastContext()
  const [frontImg, setFrontImg] = useState({ img: null, url: null })
  const [backImg, setBackImg] = useState({ img: null, url: null })
  const [receiptImg, setReceiptImg] = useState({ img: null, url: null })
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })

  const [fileMsgFront, setFileMsgFront] = useState({
    front: ''
  })
  const [fileMsgBack, setFileMsgBack] = useState({
    back: ''
  })
  const [fileMsgReceipt, setFileMsgReceipt] = useState({
    salaryReceipt: ''
  })

  const handleCheckError = () => {
    if (frontImg?.img == null) {
      setFileMsgFront({
        ...fileMsgFront,
        front: t('PLEASE_UPLOAD_FRONT_ID_IMAGE')
      })
    }
    if (backImg?.img == null) {
      setFileMsgBack({
        ...fileMsgBack,
        back: t('PLEASE_UPLOAD_BACK_ID_IMAGE')
      })
    }
    if (receiptImg?.img == null) {
      setFileMsgReceipt({
        ...fileMsgReceipt,
        salaryReceipt: t('PLEASE_UPLOAD_SALARY_RECEIPT_IMAGE')
      })
    }
  }

  useEffect(() => {
    if (watch('front')?.length > 0) {
      setFrontImg({
        url: URL.createObjectURL(watch('front')[0]),
        img: watch('front')[0]
      })
      const front_msg = validateFile(watch('front')[0])

      if (front_msg) {
        setFileMsgFront({
          ...fileMsgFront,
          front: front_msg
        })
      } else {
        setFileMsgFront({
          ...fileMsgFront,
          front: ''
        })
      }
    }
  }, [watch('front')])

  useEffect(() => {
    if (watch('back')?.length > 0) {
      setBackImg({
        url: URL.createObjectURL(watch('back')[0]),
        img: watch('back')[0]
      })
      const back_msg = validateFile(watch('back')[0])

      if (back_msg) {
        setFileMsgBack({
          ...fileMsgBack,
          back: back_msg
        })
      } else {
        setFileMsgBack({
          ...fileMsgBack,
          back: ''
        })
      }
    }
  }, [watch('back')])

  useEffect(() => {
    if (watch('salaryReceipt')?.length > 0) {
      setReceiptImg({
        url: URL.createObjectURL(watch('salaryReceipt')[0]),
        img: watch('salaryReceipt')[0]
      })
      const receipt_msg = validateFile(watch('salaryReceipt')[0])

      if (receipt_msg) {
        setFileMsgReceipt({
          ...fileMsgReceipt,
          salaryReceipt: receipt_msg
        })
      } else {
        setFileMsgReceipt({
          ...fileMsgReceipt,
          salaryReceipt: ''
        })
      }
    }
  }, [watch('salaryReceipt')])

  const onSubmit = async data => {
    try {
      if (
        fileMsgFront.front !== '' ||
        fileMsgBack.back !== '' ||
        fileMsgReceipt.salaryReceipt !== ''
      ) {
        return
      }
      if (
        data.front[0].type != 'image/png' &&
        data.front[0].type != 'image/jpg' &&
        data.front[0].type != 'image/jpeg'
      ) {
        notification.error('Please select only image in front ID.')
        return
      }
      if (
        data.back[0].type != 'image/png' &&
        data.back[0].type != 'image/jpg' &&
        data.back[0].type != 'image/jpeg'
      ) {
        notification.error('Please select only image in back ID.')
        return
      }
      if (
        data.salaryReceipt[0].type != 'image/png' &&
        data.salaryReceipt[0].type != 'image/jpg' &&
        data.salaryReceipt[0].type != 'image/jpeg'
      ) {
        notification.error('Please select only image in salary receipt.')
        return
      }
      const result = await apiPost(pathObj.checkMaxLoanAmount, {
        BankVerificationId: location.state,
        monthlySalary: data.salary
      })
      if (result?.data?.success === true) {
        navigate('/loanreqtype', {
          state: {
            id: location.state,
            monthlySalary: data.salary,
            loanAmount: result?.data?.results?.maxLoanAmount,
            front: data.front,
            back: data.back,
            salaryReceipt: data.salaryReceipt
          }
        })
      } else {
        notification.error(result?.data?.message)
      }
      console.log('data', data)
    } catch (error) {}
  }

  const preventMaxValue = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
    if (e.target.value.length > 10) {
      e.target.value = e.target.value.slice(0, 10)
    }
  }

  return (
    <>
      <div className='main_wrap'>
        <Breadcrumb className='m-0 py-3'>
          <Breadcrumb.Item href='#'>{t('LOGIN_HOME')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t('MICRO_CREDIT_REQUEST')}</Breadcrumb.Item>
        </Breadcrumb>

        <div className='bg-white main_panel'>
          <div className='page_heading mb-4'>
            <h2 className='mb-0'>{t('PERSONAL_INFORMATION')}</h2>
          </div>
          <Form
            className='personal_info_form'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col lg={5} md={6} sm={6}>
                <div className='mb-3'>
                  <h6>{t('UPLOAD_ID')}</h6>
                </div>
                <Row>
                  <Col md={6}>
                    <Form.Group className='mb-4'>
                      <div className='position-relative'>
                        <Form.Control
                          type='file'
                          accept='image/*'
                          className='position-absolute h-100 opacity-0'
                          {...register('front', {})}
                        />
                        <div className='input_file border bg-light d-flex align-items-center rounded-3  form-control'>
                          <span className='rounded-circle me-3'>
                            <img
                              src='./images/camera.svg'
                              className='me-2'
                              alt=''
                            />
                          </span>{' '}
                          {t('FRONT')}
                          {frontImg?.url && !fileMsgFront?.passport && (
                            <OImage
                              className='rounded-circle'
                              src={
                                frontImg?.url !== null
                                  ? frontImg?.url
                                  : URL.createObjectURL(watch('frontWatch')[0])
                              }
                              style={{
                                width: '3rem',
                                height: '3rem',
                                marginLeft: '15px'
                              }}
                              alt='card'
                            />
                          )}
                        </div>
                        <ErrorMessage
                          message={
                            errors?.front?.message || fileMsgFront?.front
                          }
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group className='mb-4'>
                      <div className='position-relative'>
                        <Form.Control
                          type='file'
                          accept='image/*'
                          className='position-absolute h-100 opacity-0'
                          {...register('back', {})}
                        />
                        <div className='input_file border bg-light d-flex align-items-center rounded-3  form-control'>
                          <span className='rounded-circle me-3'>
                            <img
                              src='./images/camera.svg'
                              className='me-2'
                              alt=''
                            />
                          </span>{' '}
                          {t('BACK')}
                          {backImg?.url && !fileMsgBack?.back && (
                            <OImage
                              className='rounded-circle'
                              src={
                                backImg?.url !== null
                                  ? backImg?.url
                                  : URL.createObjectURL(watch('backWatch')[0])
                              }
                              style={{
                                width: '3rem',
                                height: '3rem',
                                marginLeft: '15px'
                              }}
                              alt='card'
                            />
                          )}
                        </div>
                        <ErrorMessage
                          message={errors?.back?.message || fileMsgBack?.back}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col lg={3} md={5} sm={5} className='ms-lg-5 ms-sm-2'>
                <div className='mb-3'>
                  <h6>{t('SALARY_RECEIPT')}</h6>
                </div>
                <Form.Group className='mb-4 upload-third'>
                  <div className='position-relative'>
                    <Form.Control
                      type='file'
                      accept='image/*'
                      className='position-absolute h-100 opacity-0'
                      {...register('salaryReceipt', {})}
                    />
                    <div className='input_file border bg-light d-flex align-items-center rounded-3 form-control'>
                      <span className='rounded-circle me-3'>
                        <img
                          src='./images/camera.svg'
                          className='me-2'
                          alt=''
                        />
                      </span>{' '}
                      {t('RECEIPT')}
                      {receiptImg?.url && !fileMsgReceipt?.salaryReceipt && (
                        <OImage
                          className='rounded-circle'
                          src={
                            receiptImg?.url !== null
                              ? receiptImg?.url
                              : URL.createObjectURL(watch('receiptWatch')[0])
                          }
                          style={{
                            width: '3rem',
                            height: '3rem',
                            marginLeft: '15px'
                          }}
                          alt='card'
                        />
                      )}
                    </div>
                    <ErrorMessage
                      message={
                        errors?.salaryReceipt?.message ||
                        fileMsgReceipt?.salaryReceipt
                      }
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className='mb-3'>
                  <Form.Floating className='mb-3'>
                    <Form.Control
                      type='number'
                      inputMode='numeric'
                      onInput={e => preventMaxValue(e)}
                      placeholder='Monthly salary*'
                      {...register('salary', {
                        required: t('PLEASE_ENTER_MONTHLY_SALARY'),
                        minLength: {
                          value: 1,
                          message: t('MINIMUM_LENGTH_MUST_BE_1')
                        }
                      })}
                    />
                    <label htmlFor='floatingInputCustom1'>
                      {t('MONTHLY_SALARY')}
                      <span className='text-danger'>*</span>
                    </label>
                    <ErrorMessage message={errors?.salary?.message} />
                  </Form.Floating>
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group className='mb-3'>
                  <span class='custom_check'>
                    {t('CIBIL_LOAN_AMOUNT')}
                    <br />
                    {t('CIBIL_SCORE_LOAN_AMOUNT')} &nbsp;{' '}
                    <input
                      type='checkbox'
                      {...register('check', {
                        required: t('PLEASE_ACCEPT_THIS_CONTENT')
                      })}
                    />
                    <span class='check_indicator'>&nbsp;</span>
                  </span>
                  <ErrorMessage message={errors?.check?.message} />
                </Form.Group>
                <Button
                  className='btn btn_theme'
                  type='submit'
                  onClick={() => handleCheckError()}
                >
                  {t('PROCEED')}
                </Button>
              </Col>
              <Col></Col>
            </Row>
          </Form>
        </div>

        <Footer />
      </div>
    </>
  )
}

export default PersonalInformation
