import AuthContext from 'context/AuthContext'
import React, { useContext, useState } from 'react'
import { Container, Dropdown, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { sidebarData } from 'navs'
import Logo from '../assets/images/logo.svg'
import MenuImg from '../assets/images/menu.svg'
import Sidebar from './Sidebar'
import { useTranslation } from 'react-i18next'
import helper from '../utils/helpers'

function Header () {
  const { t, i18n } = useTranslation()
  const [show, setShow] = useState(false)
  let { logoutUser, user, language, setLanguage } = useContext(AuthContext)
  const handleLogout = () => {
    logoutUser()
  }

  const handleToggle = () => {
    setShow(!show)
    document.body.classList.toggle('toggleSidebar')
  }

  const handleClose = () => {
    setShow(false)
    document.body.classList.toggle('toggleSidebar')
  }

  const changeLanguageHandler = eventKey => {
    setLanguage(eventKey)
    if (eventKey == 2) {
      localStorage.setItem('language', 'al')
    } else {
      localStorage.setItem('language', 'en')
    }
    if (eventKey == 1) {
      i18n.changeLanguage('en')
    } else if (eventKey == 2) {
      i18n.changeLanguage('al')
    }
  }

  return (
    <div>
      <header className='py-2 bg-white position-relative'>
        <Container fluid className='px-2 px-sm-4'>
          <div className='d-flex flex-wrap align-items-center'>
            <div className='header_left d-flex align-items-center'>
              <Button
                className='bg-transparent border-0 me-2 me-sm-4'
                onClick={user && handleToggle}
              >
                {user && <img src={MenuImg} alt='' />}
              </Button>
              <Link to='/' className='logo'>
                <img src={Logo} alt='logo' />
              </Link>
            </div>
            <div className='header_right ms-auto d-flex flex-wrap align-items-center'>
              {!user && (
                <>
                  <Link to='/register' className='login_register_link'>
                    {t('O_REGISTER_TAB')}
                  </Link>
                  <Link to='/login' className='login_register_link asdsada'>
                    {t('O_LOGIN_TAB')}
                  </Link>
                </>
              )}
              <div className='language me-2' title={t('O_LANGUAGE')}>
                <Dropdown onSelect={changeLanguageHandler}>
                  <Dropdown.Toggle
                    className='bg-transparent border-0 text-dark'
                    id='dropdown-basic'
                    value='En'
                  >
                    <img
                      src={
                        language == 1
                          ? './images/usFlag.svg'
                          : './images/albaniaFlag.svg'
                      }
                      className='me-2 rounded-circle'
                      alt=''
                    />{' '}
                    <span>{language == 1 ? 'En' : 'Al'}</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item eventKey='1'>
                      <img src='./images/usFlag.svg' className='me-2' alt='' />{' '}
                      <span>En</span>
                    </Dropdown.Item>
                    <Dropdown.Item value='En' eventKey='2'>
                      <img
                        src='./images/albaniaFlag.svg'
                        className='me-2 rounded-circle'
                        alt=''
                      />{' '}
                      <span>Al</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {user && (
                <Link
                  to='/notifications'
                  className='position-relative notification me-md-3 me-2'
                  title={t('O_NOTIFICATION')}
                >
                  <img src='./images/notifiation.svg' alt='' />{' '}
                  <span className='position-absolute d-block rounded-circle bg-danger'></span>
                </Link>
              )}

              {user && (
                <div
                  className='header_profile me-md-3 me-2'
                  title={t('O_PROFILE')}
                >
                  <Dropdown>
                    <Dropdown.Toggle
                      className='bg-transparent border-0 text-dark'
                      id='dropdown-basic'
                    >
                      <img
                        src={user?.profile_pic}
                        className='me-2 w-25'
                        alt=''
                      />
                      {`${user?.first_name} ${user?.last_name}`}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() =>
                          helper.alertFunction(
                            `${t('ARE_YOU_SURE_YOU_WANT_TO_LOGOUT')}`,
                            '',
                            handleLogout,
                            '',
                            language
                          )
                        }
                      >
                        {t('HEADER_LOGOUT')}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}

              <a
                target='_blank'
                href='https://play.google.com/store/apps/details?id=com.app.alpay'
                className='btn-sm btn d-sm-flex d-none align-items-center'
              >
                <img src='./images/google-play.svg' alt='' className='me-2 ' />{' '}
                {t('SIDEBAR_DOWNLOAD_APP')}{' '}
              </a>
            </div>
          </div>
        </Container>
      </header>
      <Sidebar
        show={show}
        handleClose={handleClose}
        user={user}
        sidebarData={sidebarData}
        handleToggle={handleToggle}
      />
    </div>
  )
}

export default Header
