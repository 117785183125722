import ErrorMessage from 'components/reusable/ErrorMessage'
import AuthContext from 'context/AuthContext'
import useToastContext from 'hooks/useToastContext'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom'
import { integerInput } from 'utils/InputControl'
import { apiPost } from 'utils/apiFetch'
import pathObj from 'utils/apiPath'
import { validationRegex, validationRules } from 'utils/constants'
import { preventMax } from 'utils/reusableMethods'

function IndividualRegistration () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)

  const {
    register,
    handleSubmit,
    watch,
    reset: reset,
    getValues,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })

  const profilePicRef = useRef()
  const [icon1, setIcon1] = useState(true)
  const [icon2, setIcon2] = useState(true)
  const [profile_pic, setProfile_pic] = useState('')
  const [identityCardFront, setIdentityCardFront] = useState('')
  const [identityCardBack, setIdentityCardBack] = useState('')
  const [termsAndCondition, setTermsAndCondition] = useState(false)
  const [termsAndConditionError, setTermsAndConditionError] = useState(false)
  const [profilePicError, setProfilePicError] = useState('')
  const [identityCardFrontError, setIdentityCardFrontError] = useState('')
  const [identityCardBackError, setIdentityCardBackError] = useState('')
  const notification = useToastContext()
  const preventSpace = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }

  const handleProfilePicChange = e => {
    const image = e?.target?.files[0]
    const isValidImage = validateFiles(e)
    if (isValidImage) {
      setProfile_pic(image)
      setProfilePicError('')
    } else {
      setProfile_pic('')
    }
  }
  const identityCardBackChange = e => {
    const image = e?.target?.files[0]
    const isValidImage = validateFiles(e)
    if (isValidImage) {
      setIdentityCardBack(image)
      setIdentityCardBackError('')
    } else {
      setIdentityCardBack('')
    }
  }
  const validateFiles = e => {
    const getType = e.target.files[0].type.split('/')
    const fileSize = (e.target.files[0]?.size / (1024 * 1024)).toFixed(2)
    if (
      getType[1] !== undefined &&
      (getType[1] === 'jpeg' || getType[1] === 'png' || getType[1] === 'jpg')
    ) {
      if (fileSize > 3) {
        notification.error('Please select image below 3 MB')
        return false
      }
      return true
    } else {
      notification.error('Only jpeg,png,jpg formats are allowed')
    }
  }
  const identityCardFrontChange = e => {
    const image = e?.target?.files[0]
    const isValidImage = validateFiles(e)
    if (isValidImage) {
      setIdentityCardFront(image)
      setIdentityCardFrontError('')
    } else {
      setIdentityCardFront('')
    }
  }
  const checkValidation = () => {
    let isValid = true
    if (profile_pic === '') {
      setProfilePicError(t('PLEASE_UPLOAD_PROFILE_PICTURE'))
      isValid = false
    }
    if (identityCardFront === '') {
      setIdentityCardFrontError(t('PLEASE_UPLOAD_IDENTITY_CARD_FRONT'))
      isValid = false
    }
    if (identityCardBack === '') {
      setIdentityCardBackError(t('PLEASE_UPLOAD_IDENTITY_CARD_BACK'))
      isValid = false
    }
    return isValid
  }
  const codeValue = watch('email') ? watch('email') : ''
  const onSubmit = async data => {
    try {
      const isValid = checkValidation()
      if (!isValid) return
      if (!termsAndCondition) {
        setTermsAndConditionError(true)

        return
      }
      const formData = new FormData()
      formData.append('first_name', data?.first_name)
      formData.append('last_name', data?.last_name)
      formData.append('password', data.password)
      formData.append('confirm_password', data.password)
      formData.append('phone', data.phone)
      formData.append('email', data.email)
      formData.append('profile_pic', profile_pic)
      formData.append('NID', data.NID)
      formData.append('userType', 'individual')
      formData.append('identityCardFront', identityCardFront)
      formData.append('identityCardBack', identityCardBack)

      formData.append('referral', data.referral)
      formData.append('country_code', '355')
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      const res = await apiPost(pathObj.signupUser, formData, config)
      if (res.data.success === true) {
        notification.success(res.data.message)
        reset()
        navigate('/register/otp', {
          state: {
            email: data.email,
            phone: data.phone,
            country_code: '355'
          }
        })
      } else {
        window.scrollTo(0, 0)
        notification.error(res?.data?.message)
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (user !== null) {
      navigate('/dashboard')
    }
  }, [user])
  return (
    <div>
      <Form
        onSubmit={handleSubmit(onSubmit, () => {
          checkValidation()
        })}
        className='register-form-sec'
      >
        <div className='register_tabs'>
          <Row>
            <Col lg={4} md={12} className='mb-3 mb-lg-0'>
              <div className='upload_files'>
                <div className='upload_picture'>
                  <input
                    type='file'
                    ref={profilePicRef}
                    accept='image/png, image/jpg, image/jpeg'
                    onChange={handleProfilePicChange}
                  />
                  <div className='uploaded'>
                    <figure>
                      <img
                        src={
                          profile_pic !== ''
                            ? URL.createObjectURL(profile_pic)
                            : 'images/promo.jpg'
                        }
                        alt=''
                      />
                    </figure>
                    <span className='close_file'>
                      <img
                        src='images/upload.png'
                        alt=''
                        onClick={() => profilePicRef?.current?.click()}
                      />
                    </span>
                  </div>
                  <span>{t('UPLOAD_PICTURE')}</span>
                </div>
                {profilePicError && <ErrorMessage message={profilePicError} />}
              </div>
              <div className='Upload_identity_card'>
                <h4>{t('UPLOAD_IDENTITY_CARD')}</h4>
                <div className='upload_file_row'>
                  <div className='upload_card_field_wrap'>
                    {!identityCardFront && (
                      <div className='upload_card_field'>
                        <input
                          type='file'
                          accept='image/png, image/jpg, image/jpeg'
                          onChange={identityCardFrontChange}
                        />
                        <span className='upload_icon'>
                          <img src='images/upload.svg' alt='' />
                        </span>
                      </div>
                    )}
                    {typeof identityCardFront === 'object' && (
                      <div className='upload_card'>
                        <img
                          src={URL.createObjectURL(identityCardFront)}
                          alt=''
                        />
                        <span className='upload_card_crose'>
                          <img
                            src='images/upload_crose.png'
                            onClick={() => setIdentityCardFront('')}
                            alt=''
                          />
                        </span>
                      </div>
                    )}
                    <h6>{t('FRONT')}</h6>
                    {identityCardFrontError && (
                      <ErrorMessage message={identityCardFrontError} />
                    )}
                  </div>

                  <div className='upload_field_wrap'>
                    {!identityCardBack && (
                      <div className='upload_card_field'>
                        <input
                          type='file'
                          accept='image/png, image/jpg, image/jpeg'
                          onChange={identityCardBackChange}
                        />
                        <span className='upload_icon'>
                          <img src='images/upload.svg' alt='' />
                        </span>
                      </div>
                    )}
                    {typeof identityCardBack === 'object' && (
                      <div className='upload_card'>
                        <img
                          src={URL.createObjectURL(identityCardBack)}
                          alt=''
                        />
                        <span className='upload_card_crose'>
                          <img
                            src='images/upload_crose.png'
                            onClick={() => setIdentityCardBack('')}
                            alt=''
                          />
                        </span>
                      </div>
                    )}
                    <h6>{t('BACK')}</h6>
                    {identityCardBackError && (
                      <ErrorMessage message={identityCardBackError} />
                    )}
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={8} md={12}>
              <Row className='gx-sm-3 gx-2'>
                <Col xs={6}>
                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      <Form.Control
                        id='firstName'
                        autoFocus={true}
                        type='text'
                        maxLength={15}
                        onInput={preventSpace}
                        placeholder='name@example.com'
                        {...register('first_name', {
                          required: t('REGISTER_FIRST_NAME'),
                          minLength: {
                            value: 2,
                            message: t('REGISTER_MIN_LENGTH')
                          },
                          validate: value => {
                            return !!value.trim()
                              ? true
                              : t('REGISTER_WHITE_SPACE')
                          }
                        })}
                      />

                      <label htmlFor='firstName'>
                        {t('O_FIRST_NAME')}
                        <span className='text-danger'>*</span>
                      </label>
                      <ErrorMessage message={errors?.first_name?.message} />
                    </Form.Floating>
                  </Form.Group>
                </Col>

                <Col xs={6}>
                  {' '}
                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      <Form.Control
                        id='lastName'
                        type='text'
                        placeholder='name@example.com'
                        maxLength={15}
                        onInput={preventSpace}
                        {...register('last_name', {
                          required: t('REGISTER_LAST_NAME'),
                          minLength: {
                            value: 2,
                            message: t('REGISTER_MIN_LENGTH')
                          },
                          validate: value => {
                            return !!value.trim()
                              ? true
                              : t('REGISTER_WHITE_SPACE')
                          }
                        })}
                      />
                      <label htmlFor='lastName'>
                        {t('O_LAST_NAME')}
                        <span className='text-danger'>*</span>
                      </label>
                      <ErrorMessage message={errors?.last_name?.message} />
                    </Form.Floating>
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      <Form.Control
                        id='email'
                        type='text'
                        maxLength={50}
                        onInput={preventSpace}
                        placeholder='name@example.com'
                        value={codeValue.toLowerCase()}
                        {...register('email', {
                          required: t('REGISTER_EMAIL'),
                          pattern: {
                            value: validationRegex.email,
                            message: t('REGISTER_EMAIL_EX')
                          }
                        })}
                      />
                      <label htmlFor='email'>
                        {t('O_EMAIL')}
                        <span className='text-danger'>*</span>
                      </label>
                      <ErrorMessage message={errors?.email?.message} />
                    </Form.Floating>
                  </Form.Group>
                </Col>

                <Col md={2} xs={4}>
                  {' '}
                  <div className='position-relative country-label'>
                    <span>Country code</span>
                    <Form.Select
                      aria-label='Default select example'
                      className='form-select-special'
                      {...register('country_code', {})}
                      disabled
                    >
                      <option value='355'>+355</option>
                    </Form.Select>
                  </div>
                  <ErrorMessage message={errors?.country_code?.message} />
                </Col>
                <Col md={4} xs={8}>
                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      <Form.Control
                        id='mobile'
                        type='number'
                        inputMode='numeric'
                        onKeyPress={e => integerInput(e)}
                        onInput={e => preventMax(e, 9)}
                        placeholder='Mobile number'
                        {...register('phone', {
                          required: t('REGISTER_MOBILE'),
                          min: {
                            value: 0,
                            message: t('REGISTER_MIN_VALUE')
                          },
                          minLength: {
                            value: 9,
                            message: t('REGISTER_MIN_LENGTH_MOBILE')
                          }
                        })}
                      />
                      <label htmlFor='mobile'>
                        {t('O_MOBILE_NUMBER')}
                        <span className='text-danger'>*</span>
                      </label>

                      <ErrorMessage message={errors?.phone?.message} />
                    </Form.Floating>
                  </Form.Group>
                </Col>

                <Col xs={6}>
                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      <Form.Control
                        id='NID'
                        type='text'
                        onInput={preventSpace}
                        placeholder={t('ENTER_NID')}
                        {...register('NID', {
                          required: t('REGISTER_NID_NO')
                        })}
                      />
                      <label htmlFor='NID'>
                        {t('ENTER_NID')}
                        <span className='text-danger'>*</span>
                      </label>
                      <ErrorMessage message={errors?.NID?.message} />
                    </Form.Floating>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      <Form.Control
                        id='referral'
                        type='text'
                        maxLength={30}
                        onInput={preventSpace}
                        placeholder='name@example.com'
                        {...register('referral', {
                          pattern: {
                            value: validationRules.removeWhitespace,
                            message: 'Invalid referral code.'
                          }
                        })}
                      />
                      <label htmlFor='referral'>
                        {t('REGISTER_REFERRAL_CODE')}
                      </label>
                      <ErrorMessage message={errors?.referral?.message} />
                    </Form.Floating>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      <Form.Control
                        id='password'
                        type={icon1 ? 'password' : 'text'}
                        maxLength={15}
                        onInput={preventSpace}
                        placeholder='name@example.com'
                        {...register('password', {
                          required: t('REGISTER_PASSWORD'),
                          pattern: {
                            value: validationRules.password,
                            message: validationRules.passwordMessage
                          }
                        })}
                      />
                      <label htmlFor='password'>
                        {t('O_PASSWORD')}
                        <span className='text-danger'>*</span>
                      </label>
                      {icon1 ? (
                        <span
                          className='password_view cursor-pointer absolute top-[18px] right-[20px]'
                          style={{
                            position: 'absolute',
                            top: '18px',
                            right: '5px',
                            cursor: 'pointer'
                          }}
                          onClick={() => setIcon1(!icon1)}
                        >
                          <AiFillEyeInvisible />
                        </span>
                      ) : (
                        <span
                          className='password_view cursor-pointer absolute top-[18px] right-[20px]'
                          onClick={() => setIcon1(!icon1)}
                          style={{
                            position: 'absolute',
                            top: '18px',
                            right: '5px',
                            cursor: 'pointer'
                          }}
                        >
                          <AiFillEye />
                        </span>
                      )}
                      <ErrorMessage message={errors?.password?.message} />
                    </Form.Floating>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      <Form.Control
                        id='confirm_password'
                        type={icon2 ? 'password' : 'text'}
                        maxLength={15}
                        onInput={preventSpace}
                        placeholder='name@example.com'
                        {...register('confirm_password', {
                          required: t('REGISTER_CONFIRM_PASSWORD'),
                          pattern: {
                            value: validationRules.password,
                            message: validationRules.confirmPasswordMessage
                          },
                          validate: value => {
                            const { password } = getValues()
                            return (
                              password === value ||
                              t('REGISTER_PASS_CONFIRM_PASS')
                            )
                          }
                        })}
                      />
                      <label htmlFor='confirm_password'>
                        {t('OTP_CONFIRM_PASSWORD')}
                        <span className='text-danger'>*</span>
                      </label>
                      {icon2 ? (
                        <span
                          className='password_view cursor-pointer absolute top-[18px] right-[20px]'
                          style={{
                            position: 'absolute',
                            top: '18px',
                            right: '5px',
                            cursor: 'pointer'
                          }}
                          onClick={() => setIcon2(!icon2)}
                        >
                          <AiFillEyeInvisible />
                        </span>
                      ) : (
                        <span
                          className='password_view cursor-pointer absolute top-[18px] right-[20px]'
                          onClick={() => setIcon2(!icon2)}
                          style={{
                            position: 'absolute',
                            top: '18px',
                            right: '5px',
                            cursor: 'pointer'
                          }}
                        >
                          <AiFillEye />
                        </span>
                      )}
                      <ErrorMessage
                        message={errors?.confirm_password?.message}
                      />
                    </Form.Floating>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className='registration_form'>
          <div className='tearm_of_use'>
            <input
              type='checkbox'
              checked={termsAndCondition}
              onChange={e => {
                setTermsAndCondition(e.target.checked)
                if (e.target.checked) {
                  setTermsAndConditionError(false)
                }
              }}
            />
            {t('BY_SIGNING_UP_YOU_ARE_AGREEING_TO_OUR')}{' '}
            <Link to='/terms-and-conditions'>{t('TERMS_OF_USE')}</Link>{' '}
            {t('REGISTER_AND')}{' '}
            <Link to='/privacy-policy'>{t('REGISTER_PRIVACY_POLICY')}</Link>
            {termsAndConditionError && (
              <div>
                <ErrorMessage
                  message={t('YOU_MUST_ACCEPT_OUR_TERMS_AND_CONDITIONS')}
                />
              </div>
            )}
          </div>
          <Button type='submit'>{t('O_REGISTER_TAB')}</Button>
        </div>
      </Form>
    </div>
  )
}

export default IndividualRegistration
