import React, { useEffect, useState } from 'react'

import {
  FloatingLabel,
  Modal,
  Accordion,
  Form,
  Breadcrumb,
  Button
} from 'react-bootstrap'
import Footer from '../components/Footer'
import pathObj from 'utils/apiPath'
import { apiGet } from 'utils/apiFetch'
import helpers from 'utils/helpers'
import TransferToMobile from './transfer_money/TransferToMobile'
import TransferToBank from './transfer_money/TransferToBank'
import WithdrawToCashOut from './transfer_money/withdrawToCashout'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function TransferMoney () {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const [balance, setBalance] = useState(0)
  const [dropDown, setDropDown] = useState(0)
  const location = useLocation()
  const { activeKey } = location.state || 0
  const navigate = useNavigate()

  const getBalance = async () => {
    try {
      const path = pathObj.getBalance
      const result = await apiGet(path)
      setBalance(result.data.results?.wallet_amount || 0)
      if (Number(result.data.results?.wallet_amount) === 0) {
        navigate('/add-money')
      }
    } catch (error) {
      console.log('getBalance==>', error)
    }
  }

  useEffect(() => {
    getBalance()
  }, [])
  const resetField = item => {
    setDropDown(item)
  }
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <>
      <div className='main_wrap'>
        <Breadcrumb className='m-0 py-3'>
          <Breadcrumb.Item href='/dashboard'>{t('O_HOME')}</Breadcrumb.Item>
          <Breadcrumb.Item active>
            {activeKey == 2
              ? t('DASHBOARD_WITHDRAW_MONEY')
              : t('DASHBOARD_TRANSFER_MONEY')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className='bg-white main_panel overflow-hidden home_main py-4 px-0'>
          <div className='page_heading pb-4 px-4'>
            <h2 className='mb-0'>
              {activeKey == 2
                ? t('DASHBOARD_WITHDRAW_MONEY')
                : t('DASHBOARD_TRANSFER_MONEY')}
            </h2>
          </div>
          <div className='bg-light py-3 px-sm-4 px-2 d-flex align-items-center'>
            <div className='avail d-flex align-items-center'>
              <h5 className='text-white mb-0'>
                {t('O_AVL_BLN')}
                <span className='d-block'>
                  {t('O_LEK')} {helpers.currencyFormat(balance)}
                </span>
              </h5>
            </div>
          </div>
          <div className='py-3 px-4 add_card'>
            <div className='page_heading mb-3'>
              <h2 className='mb-0'>{t('SELECT_PAYMENT_METHOD')} </h2>
            </div>
            <Accordion defaultActiveKey={activeKey} className='border-0'>
              <Accordion.Item
                eventKey='0'
                className='border-start-0 border-end-0'
                onClick={() => resetField(0)}
              >
                <Accordion.Header className='border-0 bg-transparent'>
                  {t('SEND_MONEY_MOBILE')}
                </Accordion.Header>
                <Accordion.Body className='px-0'>
                  <TransferToMobile
                    getBalance={getBalance}
                    dropDown={dropDown}
                  />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item
                eventKey='1'
                className='border-start-0 border-end-0'
                onClick={() => resetField(1)}
              >
                <Accordion.Header className='border-0 bg-transparent'>
                  {t('WITHDRAW_TO_BANK')}
                </Accordion.Header>
                <Accordion.Body className='px-0'>
                  <TransferToBank
                    getBalance={getBalance}
                    handleShow={handleShow}
                    dropDown={dropDown}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item
                eventKey='2'
                className='border-start-0 border-end-0'
                onClick={() => resetField(2)}
              >
                <Accordion.Header className='border-0 bg-transparent'>
                  {t('WITHDRAW_TO_CASH_OUT')}
                </Accordion.Header>
                <Accordion.Body className='px-0'>
                  <WithdrawToCashOut
                    getBalance={getBalance}
                    handleShow={handleShow}
                    dropDown={dropDown}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>

        <Footer />
      </div>
      <Modal show={show} onHide={handleClose} centered className='swift_modal'>
        <Modal.Header closeButton>
          <Modal.Title>{t('GET_SWIFT_CODE')} </Modal.Title>
        </Modal.Header>
        <Form className='pb-4'>
          <Modal.Body>
            <Form.Group className='mb-3'>
              <FloatingLabel
                controlId='floatingSelectGrid'
                label={t('SELECT_BANK')}
              >
                <Form.Select aria-label='Floating label select example'>
                  <option>{t('BANK')}</option>
                  <option value='1'>One</option>
                  <option value='2'>Two</option>
                  <option value='3'>Three</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group className='mb-0'>
              <FloatingLabel
                controlId='floatingSelectGrid'
                label={t('SELECT_BRANCH')}
              >
                <Form.Select aria-label='Floating label select example'>
                  <option>{t('BRANCH')}</option>
                  <option value='1'>One</option>
                  <option value='2'>Two</option>
                  <option value='3'>Three</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className='text-center justify-content-center border-0 pt-0'>
            <Button className='btn btn_theme'>{t('ADD_MONEY_DONE')}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default TransferMoney
