import React from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

function Footer () {
  const { t } = useTranslation()
  return (
    <div className='d-flex flex-wrap justify-content-lg-between justify-content-center footer py-3 py-sm-4'>
      <p className='mb-md-0  text-white '>
        {t('FOOTER_RESERVED')} {dayjs().year()}{' '}
      </p>
      <ul className='list-unstyled d-flex flex-wrap m-0'>
        <li>
          <Link to='/privacy-policy'>{t('FOOTER_PRIVACY_POLICY')}</Link>
        </li>
        <li>
          <Link to='/terms-and-conditions'>{t('FOOTER_TERMS')} </Link>
        </li>
        <li>
          <Link to='/about-us'>{t('FOOTER_ABOUT_US')} </Link>
        </li>
        <li>
          <Link to='/contact-us'>{t('FOOTER_CONTACT_US')} </Link>
        </li>
        <li>
          <Link to='/faqs'>{t('FOOTER_FAQS')}</Link>
        </li>
      </ul>
    </div>
  )
}

export default Footer
