import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

export default function HomeFooter () {
  const { t } = useTranslation()
  return (
    <>
      <footer className='footer-section'>
        <div className='footer-content'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 col-12'>
                <div className='footer-about'>
                  <a href={'/'}>
                    <img src='./images/footer-logo.png' alt='' />
                  </a>
                  <p>{t('HOME_FOOTER_PARA')}</p>
                </div>
              </div>

              <div className='col-md-3 col-6'>
                <div className='short-link'>
                  <h5>{t('HOME_FOOTER_SORT_LINK')}</h5>
                  <ul className='list-unstyled'>
                    <li>
                      <a
                        href='https://play.google.com/store/apps/details?id=com.app.alpay'
                        target='_blank'
                        rel='noreferrer'
                      >
                        {t('HOME_FOOTER_DOWNLOAD_APP')}
                      </a>
                    </li>
                    <li>
                      <a href={'/faqs'}>{t('FOOTER_FAQS')}</a>
                    </li>
                    <li>
                      <a href={'/about-us'}>{t('FOOTER_ABOUT_US')}</a>
                    </li>
                    <li>
                      <a href={'/contact-us'}>{t('FOOTER_CONTACT_US')}</a>
                    </li>
                    <li>
                      <a href={'/privacy-policy'}>
                        {t('FOOTER_PRIVACY_POLICY')}
                      </a>
                    </li>
                    <li>
                      <a href={'/terms-and-conditions'}>{t('FOOTER_TERMS')}</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className='col-md-3 col-6'>
                <div className='sociallink'>
                  <h5>{t('HOME_FOOTER_CONCAT_WITH_US')}</h5>
                  <div className='footer-sociallink'>
                    <a href='https://www.facebook.com/' target='_blank'>
                      <img src='./images/fb.png' alt='' />
                    </a>
                    <a href='https://twitter.com/login?lang=en' target='_blank'>
                      <img src='./images/twitter.png' alt='' />
                    </a>
                    <a href='https://in.pinterest.com/' target='_blank'>
                      <img src='./images/pinterest.png' alt='' />
                    </a>
                    <a href='https://www.linkedin.com/feed/' target='_blank'>
                      <img src='./images/linkedin.png' alt='' />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='copyright'>
          {t('HOME_FOOTER_COPYRIGHT')}© Copyright {dayjs().year()} -{' '}
          {t('HOME_FOOTER_ALPAY')}.
        </div>
      </footer>
    </>
  )
}
