import React from 'react'
import { Form, Breadcrumb, Button, Row, Col } from 'react-bootstrap'
import Footer from '../components/Footer'
import pathObj from 'utils/apiPath'
import { apiPost } from 'utils/apiFetch'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import useToastContext from 'hooks/useToastContext'
import { integerInput } from 'utils/InputControl'
import { preventMax } from 'utils/reusableMethods'
import ErrorMessage from 'components/reusable/ErrorMessage'
import { useNavigate } from 'react-router-dom'

function ThirdPartyInvoices () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const thirdPartySendOTPForInvoicePayment = async data => {
    try {
      const path = pathObj.thirdPartySendOTPForInvoicePayment
      const result = await apiPost(path, { ...data })
      if (result?.data?.success) {
        notification.success(result?.data?.message)
        navigate('/third-party-invoice/otp', { state: { ...data } })
      } else {
        notification.error(result?.data?.message)
      }
    } catch (error) {}
  }

  const notification = useToastContext()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })

  const preventSpace = e => {
    if (['Enter'].includes(e.key)) {
      e.preventDefault()
    }
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }
  return (
    <>
      <div className='main_wrap'>
        <Breadcrumb className='m-0 py-3'>
          <Breadcrumb.Item href='/dashboard'>{t('O_HOME')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t('THIRD_PARTY_INVOICE')}</Breadcrumb.Item>
        </Breadcrumb>

        <div className='bg-white main_panel overflow-hidden home_main py-4 px-0'>
          <div className='page_heading pb-4 px-4'>
            <h2 className='mb-0'>{t('THIRD_PARTY_INVOICE')}</h2>
          </div>
          <div className='py-3 px-4 add_card'>
            <Form
              onSubmit={handleSubmit(thirdPartySendOTPForInvoicePayment)}
              className='mobile-form-sec'
            >
              <Row>
                <Col md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Floating>
                      <Form.Control
                        id='floatingInputCustom'
                        type='text'
                        name='niptNo'
                        placeholder='name@example.com'
                        autofocus
                        {...register('niptNo', {
                          required: t('REGISTER_NIPT_NO')
                        })}
                      />
                      <label htmlFor='floatingInputCustom'>
                        {t('ENTER_NIPT_NO')}
                        <span className='text-danger'>*</span>
                      </label>
                      <ErrorMessage message={errors?.niptNo?.message} />
                    </Form.Floating>
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Floating>
                      <Form.Control
                        id='floatingInputCustom'
                        type='number'
                        name='amount'
                        placeholder='name@example.com'
                        onInput={e => preventMax(e, 9)}
                        onKeyPress={e => integerInput(e)}
                        onKeyDown={e => {
                          if (['-', '+', 'e', 'Enter'].includes(e.key)) {
                            e.preventDefault()
                          }
                        }}
                        {...register('amount', {
                          required: t('AMOUNT_FIELD'),
                          min: {
                            value: 10,
                            message: t('MIN_AMOUNT')
                          }
                        })}
                        aria-invalid={errors.amount ? 'true' : 'false'}
                      />
                      <label htmlFor='floatingInputCustom'>
                        {t('O_AMOUNT')}
                        <span className='text-danger'>*</span>
                      </label>
                      <ErrorMessage message={errors?.amount?.message} />
                    </Form.Floating>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Floating>
                      <Form.Control
                        id='floatingInputCustom'
                        type='text'
                        name='invoiceNivf'
                        onKeyDown={preventSpace}
                        placeholder='name@example.com'
                        maxLength={500}
                        {...register('invoiceNivf', {
                          required: t('REQUIRED_NIVF')
                        })}
                      />
                      <label htmlFor='floatingInputCustom'>
                        {t('INVOICE_NIVF')}
                        <span className='text-danger'>*</span>
                      </label>
                      <ErrorMessage message={errors?.invoiceNivf?.message} />
                    </Form.Floating>
                  </Form.Group>
                </Col>
              </Row>

              <div className='text-end'>
                <Button
                  className='btn btn_theme w-auto me-3'
                  type='button'
                  onClick={() => navigate('/dashboard')}
                >
                  {t('O_CANCEL')}
                </Button>
                <Button className='btn btn_theme w-auto' type='submit'>
                  {t('PAY_INVOICE')}
                </Button>
              </div>
            </Form>
          </div>
        </div>

        <Footer />
      </div>
    </>
  )
}

export default ThirdPartyInvoices
