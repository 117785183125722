import React from "react";
import {
  Breadcrumb,
  Button,
  FloatingLabel,
  Form,
  Row,
  Col,
  Tabs,
  Tab,
  Table
} from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'

function Rechargemobile() {
  const { t } = useTranslation()
  return (
    <>
      <Header />

      <div className="main_wrap">
        <Breadcrumb className="m-0 py-3">
          <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Recharge Mobile</Breadcrumb.Item>
        </Breadcrumb>

        <div className="bg-light-gray main_panel overflow-hidden p-0">
          <Row>
            <Col md={4}>
              <div className="left_form_wrap">
              <div className="page_heading mb-4">
                <h2 className="mb-0">Recharge Mobile</h2>
              </div>
              <Form className="">
                <Row>
                  <div className="mb-3 mb-md-4 d-flex">
                  
                    {" "}
                    <Form.Check type="radio" label="Prepaid" id="radio1" className="me-5"/>
                
                 
                    {" "}
                    <Form.Check type="radio" label="Postpaid" id="radio2" />
                 
                
                  </div>
                 
                  <Col md={12} sm={6}>
                <Form.Group className="mb-3">
                  <Form.Floating className="mb-3">
                    <Form.Control
                      id="floatingInputCustom1"
                      type="text"
                      placeholder="name@example.com"
                    />
                    <label htmlFor="floatingInputCustom">
                      Mobile Number
                    </label>
                  </Form.Floating>
                </Form.Group>
                </Col>
                <Col md={12} sm={6}>
                <Form.Group className="mb-3">
                  <FloatingLabel
                    controlId="floatingSelectGrid"
                    label="Operator"
                  >
                    <Form.Select aria-label="Floating label select example">
                      <option>JIO</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group> </Col>
                <Col md={12} sm={6}>
                <Form.Group className="mb-3">
                  <Form.Floating className="mb-3">
                    <Form.Control
                      id="floatingInputCustom1"
                      type="text"
                      placeholder="name@example.com"
                    />
                    <label htmlFor="floatingInputCustom">
                      Amount 
                    </label>
                  </Form.Floating>
                </Form.Group>
                </Col>
                <Col md={12} sm={6}>
                <Form.Group className="mb-3">
                  <Form.Floating className="mb-3">
                    <Form.Control
                      id="floatingInputCustom1"
                      type="text"
                      placeholder="name@example.com"
                    />
                    <label htmlFor="floatingInputCustom">
                       Have a promocode?  
                    </label>
                  </Form.Floating>
                </Form.Group>
                </Col>
                <Col md={12}><Button  className="btn btn_theme py-3 w-100">Proceed to recharge </Button></Col>
                </Row>
              </Form>
              </div>
            </Col>


            <Col md={8}>
            <div className="right_inner_wrap">
            <div className="plans_data tabs_theme">
            <Tabs
                  defaultActiveKey="browse_plan"
                  id="uncontrolled-tab-example"
                  className="mb-sm-3 mb-0"
                >
                  <Tab eventKey="browse_plan" title="Browse Plan">
                     <div className="theme_tabs_content">
                        <div className="page_heading my-4">
                           <h2 className="mb-0">Browse Plans of ABC - Albania </h2>
                        </div> 
                        <Tabs
                          defaultActiveKey="popular"
                          id="uncontrolled-tab-example"
                          className="mb-3"
                        >
                          <Tab eventKey="popular" title="Popular">
                            <Table striped  hover className="table_theme">
                              <thead>
                                <tr>
                                  <th>Circle</th>
                                  <th>Plan type</th>
                                  <th>Data</th>
                                  <th>Validity</th>
                                  <th>Description</th>
                                  <th>Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                <td>Albania </td>
                                  <td>Rechage </td>
                                  <td>2 GB/Day</td>
                                  <td>28 days</td>
                                  <td><span>Voice: unlimited Calls</span> | <br></br><span>SMS:100 SMS/day</span></td>
                                  <td><Link to="/electricitybill" className="btn btn_theme">{t('O_LEK')}:299</Link></td>
                                </tr>
                                <tr>
                                <td>Albania </td>
                                  <td>Rechage </td>
                                  <td>2 GB/Day</td>
                                  <td>28 days</td>
                                  <td><span>Voice: unlimited Calls</span> | <br></br><span>SMS:100 SMS/day</span></td>
                                  <td><Link to="/electricitybill" className="btn btn_theme">{t('O_LEK')}:299</Link></td>
                                </tr>
                                <tr>
                                <td>Albania </td>
                                  <td>Rechage </td>
                                  <td>2 GB/Day</td>
                                  <td>28 days</td>
                                  <td><span>Voice: unlimited Calls</span> | <br></br><span>SMS:100 SMS/day</span></td>
                                  <td><Link to="/electricitybill" className="btn btn_theme">{t('O_LEK')}:299</Link></td>
                                </tr>

                                <tr>
                                <td>Albania </td>
                                  <td>Rechage </td>
                                  <td>2 GB/Day</td>
                                  <td>28 days</td>
                                  <td><span>Voice: unlimited Calls</span> | <br></br><span>SMS:100 SMS/day</span></td>
                                  <td><Link to="/electricitybill" className="btn btn_theme">{t('O_LEK')}:299</Link></td>
                                </tr>


                                <tr>
                                <td>Albania </td>
                                  <td>Rechage </td>
                                  <td>2 GB/Day</td>
                                  <td>28 days</td>
                                  <td><span>Voice: unlimited Calls</span> | <br></br><span>SMS:100 SMS/day</span></td>
                                  <td><Link to="/electricitybill" className="btn btn_theme">{t('O_LEK')}:299</Link></td>
                                </tr>
                              </tbody>
                            </Table>
                          </Tab>


                          <Tab eventKey="dataadd" title="Data Add on">
                            Data Add on
                          </Tab>

                          <Tab eventKey="cricket_pack" title="Cricket Packs">
                          Cricket Packs
                          </Tab>

                          <Tab eventKey="smart_phone" title="Smart Phone">
                          Smart Phone
                          </Tab>

                          <Tab eventKey="topup" title="Top Up">
                          Top Up
                          </Tab>

                          <Tab eventKey="isd" title="ISD">
                          ISD
                          </Tab>


                          <Tab eventKey="internationalroaming" title="International Roaming">
                          International Roaming
                          </Tab>

                       </Tabs>
                     </div>  
                  </Tab>
                  <Tab eventKey="recents" title="Recents">
                  Recents
                  </Tab>
                  <Tab eventKey="promo_codes" title="Promo Codes">
                  Promo Codes
                  </Tab>
                </Tabs>  
                </div>
                </div>
            </Col>






          </Row>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Rechargemobile;
