import React, { useState, useEffect } from 'react'
import { Col, Row, Button, Form, Modal, Spinner } from 'react-bootstrap'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import pathObj from '../utils/apiPath'
import { apiGet, apiPost } from '../utils/apiFetch'
import { useForm } from 'react-hook-form'
import { validationRegex } from 'utils/constants'
import ErrorMessage from 'components/reusable/ErrorMessage'
import useToastContext from 'hooks/useToastContext'
import { useTranslation } from 'react-i18next'
import { upperCase } from 'lodash'
import helpers from '../utils/helpers'

function SelectGiftCard () {
  const { t } = useTranslation()
  const [balance, setBalance] = useState(0)
  const [show, setShow] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [previewData, setPreviewData] = useState()
  const [resultMessage, setResultMessage] = useState()
  const [isLodding, setIsLodding] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const notification = useToastContext()

  const {
    register,
    setValue,
    reset,
    formState: { errors },
    watch,
    clearErrors,
    handleSubmit
  } = useForm()
  const onSubmit = data => giftSend(data)

  const handleClose = () => {
    setShowPreview(false)
    setIsLodding(false)
  }
  const handleCloseModal = () => {
    setShow(false)
  }

  const url = resultMessage?.success
    ? './images/check.png'
    : './images/wrong.png'

  const amount = async () => {
    const path = pathObj.getBalance
    const result = await apiGet(path)
    setBalance(result?.data?.results?.wallet_amount || 0)
  }
  const giftSend = async data => {
    try {
      setShowPreview(true)
      const payload = {
        title: data.title,
        amount: parseFloat(data.amount),
        receiver_email: data.receiver_email,
        sender_name: data.giftedFrom ? data.giftedFrom : null,
        message: data.message ? data.message : null
      }
      setPreviewData({ ...payload })
    } catch (error) {
      console.log('error', error)
    }
  }
  const sendGift = async () => {
    setIsLodding(true)
    try {
      const path = pathObj.giftCard
      const result = await apiPost(path, { ...previewData })
      if (result.data.success) {
        setShowPreview(false)

        setTimeout(() => {
          setShow(true)
        }, [100])

        reset()
      } else {
        setShowPreview(false)

        setShow(false)
        notification.error(result?.data?.message)
      }
      setResultMessage(result.data)
    } catch (error) {
      console.log('error:', error)
    } finally {
      setShowPreview(false)
    }
    setIsLodding(false)
  }
  useEffect(() => {
    amount()
  }, [])

  const MyButton = () => {
    return (
      <>
        {isLodding ? (
          <Button className='px-5 p-3 fs-6 text-white bg-muted m-10 px-3 mt-4'>
            <Spinner animation='border' size='sm' />
          </Button>
        ) : (
          <Button
            className={helpers?.ternaryCondition(
              isLodding,
              'px-5 p-3 fs-6 text-white bg-muted m-10 px-3 mt-4',
              'px-5 p-3 fs-6 text-white copy_btn link m-10 px-3 mt-4'
            )}
            onClick={!isLodding && sendGift}
            title={t('PAY_NOW')}
          >
            {t('PAY_NOW')}
          </Button>
        )}
      </>
    )
  }

  let src = ''
  if (previewData?.title === 'Anniversary') {
    src = './images/anni_selecte.png'
  } else if (previewData?.title === 'Birthday') {
    src = './images/bday_select.png'
  } else if (previewData?.title === 'Christmas') {
    src = './images/christmas_select.png'
  } else {
    src = './images/congrats_select.png'
  }

  function handleInputFocus (event) {
    if (event.target.name === 'amount') {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
    console.log(event.target.name) // or event.target.id
  }
  useEffect(() => {
    clearErrors('amount')
  }, [watch('amount')])

  const preventSpace = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }
  const handleChangeAmount = e => {
    var value = e.target.value
    const pattern = /^\d{0,5}(\.\d{0,2})?$/
    if (e.target.value === '') {
      e.target.value = e.target.value.slice(e.target.value?.length - 1, -1)
    }
    const isValid = pattern.test(value)
    if (!isValid) {
      value = value.slice(0, -1)
    }
    setValue('amount', value)
  }
  var pressedKeys = []

  return (
    <>
      {/* <Header /> */}

      <div className='main_wrap px-0'>
        <div className='m-0 py-4 sidebar_pageHeading fullPage_space'>
          <h5 className='mb-0 text-white'>
            <img src='./images/sidebar_icon9.svg' alt='' className='me-2' />
            {t('NAV_GIFT_CARD')}
          </h5>
        </div>
        <div className='bg-light main_panel rounded-0 p-sm-3 p-0 p-md-4 sendgift'>
          <div className='bg-white p-3 p-md-4 tabs_theme'>
            <div className='card_balance d-flex flex-wrap align-items-center px-3'>
              <h5 className='text-white mt-2'>
                {t('GIFT_CARD_BALANCE')}:
                <span className=''>
                  {' '}
                  {t('O_LEK')} {balance}
                </span>
              </h5>
              <Button
                type='btn'
                className='id_btn text-white bg-dark fw-bold px-3 ms-md-auto ms-0 my-md-0 my-2 border-0'
              >
                ID - XYV458722221
              </Button>
            </div>
            <div
              id='uncontrolled-tab-example'
              className='mb-3 border-top mt-2 pt-4'
            >
              <div>
                <div className='d-sm-flex  align-items-center'>
                  <h4 className='mb-sm-0 mb-3'>{t('GIFT_CARD_SEND')}</h4>
                  <Link
                    to='/AddGiftCard'
                    className='btn btn_theme ms-auto'
                    title={t('GIFT_CARD_ADD')}
                  >
                    {t('GIFT_CARD_ADD')}
                  </Link>
                  <Link
                    to='/sendGiftCard'
                    className='btn btn_theme ms-sm-3 ms-1'
                    title={t('GIFT_CARD_SEND')}
                  >
                    {t('GIFT_CARD_SEND')}
                  </Link>
                </div>
                <Form
                  className='mt-3 gift_form'
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <h4 className='mb-3 fw-normal'>
                    {t('GIFT_CARD_SELECT')}
                    <span className='text-danger'>*</span>
                  </h4>
                  <Row className='gy-3'>
                    <Col xl={4} lg={6}>
                      <div className='overflow-scroll'>
                        <div className='d-flex justify-content-md-around select-gift-scroller'>
                          <div className='event_select fs-6 text-center'>
                            <span className='custom_radio'>
                              <img
                                src='./images/bday_select.png'
                                className=''
                                alt=''
                              />
                              &nbsp;
                              <input
                                type='radio'
                                name='title'
                                value='Birthday'
                                {...register('title', {
                                  required: 'Please enter gift card.'
                                })}
                              />
                              <span className='radio_indicator' />
                            </span>
                            <h6 className='d-block fs-6 mb-0'>
                              {t('GIFT_CARD_BIRTHDAY')}
                            </h6>
                          </div>
                          <div className='event_select fs-6 text-center'>
                            <span className='custom_radio'>
                              <img
                                src='./images/anni_selecte.png'
                                className=''
                                alt=''
                              />
                              &nbsp;{' '}
                              <input
                                type='radio'
                                name='title'
                                value='Anniversary'
                                {...register('title', {
                                  required: t('SELECT_GIFT_CARD')
                                })}
                              />
                              <span className='radio_indicator' />
                            </span>
                            <h6 className='text-center mb-0 fs-6'>
                              {t('GIFT_CARD_ANNIVERSARY')}
                            </h6>
                          </div>
                          <div className='event_select fs-6'>
                            <span className='custom_radio'>
                              <img
                                src='./images/christmas_select.png'
                                className=''
                                alt=''
                              />
                              &nbsp;
                              <input
                                type='radio'
                                name='title'
                                value='Christmas'
                                {...register('title', {
                                  required: t('SELECT_GIFT_CARD')
                                })}
                              />
                              <span className='radio_indicator' />
                            </span>
                            <h6 className='mb-0 fs-6'>
                              {t('GIFT_CARD_CHRISTMAS')}
                            </h6>
                          </div>
                          <div className='event_select fs-6 text-center'>
                            <span className='custom_radio'>
                              <img
                                src='./images/congrats_select.png'
                                className=''
                                alt=''
                              />

                              <input
                                type='radio'
                                name='title'
                                value='Congratulations'
                                {...register('title', {
                                  required: t('SELECT_GIFT_CARD')
                                })}
                              />
                              <span className='radio_indicator' />
                            </span>
                            <h6 className='mb-0 fs-6'>
                              {t('O_CONGRATULATION')}
                            </h6>{' '}
                            &nbsp;{' '}
                          </div>
                        </div>
                      </div>

                      <ErrorMessage message={errors.title?.message} />
                    </Col>
                    <Col xl={4} lg={6}>
                      <Form.Group>
                        <div className='d-flex align-items-center flex-wrap mb-2'>
                          <Form.Floating className='w-100 mb-2'>
                            <Form.Control
                              type='number'
                              placeholder='name@example.com'
                              name='amount'
                              step='any'
                              {...register('amount', {
                                required: t('AMOUNT_EMPTY'),
                                onChange: e => handleChangeAmount(e)
                              })}
                              onKeyDown={e => {
                                pressedKeys.push(e.key)
                                var lastKey =
                                  pressedKeys[pressedKeys.length - 2]
                                if (lastKey == '.') {
                                  if (['-', '.', '+', 'e'].includes(e.key)) {
                                    e.preventDefault()
                                  }
                                } else if (['-', '+', 'e'].includes(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                              aria-invalid={errors.amount ? 'true' : 'false'}
                            />
                            <label htmlFor='floatingInputCustom'>
                              {t('O_ENTER_AMOUNT')}
                              <span className='text-danger'>*</span>
                            </label>
                          </Form.Floating>
                        </div>
                      </Form.Group>
                      <div
                        className={`d-flex align-items-center flex-wrap wallet_amount border p-2 rounded mw-100 ${
                          isActive ? 'activeColor' : ''
                        }`}
                        onFocus={handleInputFocus}
                      >
                        <span className='custom_radio mb-1'>
                          {' '}
                          &nbsp;{' '}
                          <input
                            type='radio'
                            name='amount'
                            value='50'
                            onClick={() => setValue('amount', 50)}
                          />
                          <span className='radio_indicator mb-1'>&nbsp;</span>{' '}
                          <span className='radio_text mb-1'>
                            {t('O_LEK')} 50
                          </span>
                        </span>
                        <span className='custom_radio mb-1'>
                          {' '}
                          &nbsp;{' '}
                          <input
                            type='radio'
                            name='amount'
                            value='100'
                            onClick={() => setValue('amount', 100)}
                          />
                          <span className='radio_indicator'>&nbsp;</span>{' '}
                          <span className='radio_text'>{t('O_LEK')} 100</span>
                        </span>
                        <span className='custom_radio mb-1'>
                          {' '}
                          &nbsp;{' '}
                          <input
                            type='radio'
                            name='amount'
                            value='150'
                            onClick={() => setValue('amount', 150)}
                          />
                          <span className='radio_indicator'>&nbsp;</span>{' '}
                          <span className='radio_text'>{t('O_LEK')} 150</span>
                        </span>
                        <span className='custom_radio mb-1'>
                          {' '}
                          &nbsp;{' '}
                          <input
                            type='radio'
                            name='amount'
                            value='200'
                            onClick={() => setValue('amount', 200)}
                          />
                          <span className='radio_indicator'>&nbsp;</span>{' '}
                          <span className='radio_text'>{t('O_LEK')} 200</span>
                        </span>
                      </div>
                      <ErrorMessage message={errors.amount?.message} />
                    </Col>
                    <Col xl={4} lg={4} md={6}>
                      <Form.Group onFocus={handleInputFocus}>
                        <div className='d-flex align-items-center flex-wrap mb-2'>
                          <Form.Floating className='w-100 mb-2'>
                            <Form.Control
                              id='receiver_email'
                              type='text'
                              placeholder=' '
                              name='receiver_email'
                              maxLength={50}
                              onInput={preventSpace}
                              {...register('receiver_email', {
                                required: t('REGISTER_EMAIL'),
                                pattern: {
                                  value: validationRegex.email,
                                  message: t('REGISTER_EMAIL_EX')
                                }
                              })}
                              aria-invalid={
                                errors.receiver_email ? 'true' : 'false'
                              }
                            />
                            <label htmlFor='floatingInputCustom1'>
                              {t('GIFT_CARD_GIFTED')}
                              <span className='text-danger'>*</span>
                            </label>

                            <ErrorMessage
                              message={errors.receiver_email?.message}
                            />
                          </Form.Floating>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                      <Form.Group className='mb-3'>
                        <Form.Floating className='w-100 mb-2'>
                          <Form.Control
                            id='giftedFrom'
                            type='text'
                            placeholder=' '
                            name='giftedFrom'
                            onInput={preventSpace}
                            maxLength={100}
                            {...register('giftedFrom', {
                              required: t('SENDER_NAME_EMPTY')
                            })}
                            aria-invalid={errors.giftedFrom ? 'true' : 'false'}
                          />
                          <label htmlFor='floatingInputCustom1'>
                            {t('GIFT_CARD_FROM')}
                            <span className='text-danger'>*</span>
                          </label>
                        </Form.Floating>
                        <ErrorMessage message={errors.giftedFrom?.message} />
                      </Form.Group>
                    </Col>

                    <Col lg={4} md={6}>
                      <Form.Group className='mb-2'>
                        <Form.Floating className='w-100 mb-2'>
                          <Form.Control
                            id='floatingInputCustom'
                            as='textarea'
                            placeholder='name@example.com'
                            name='message'
                            onInput={preventSpace}
                            maxLength={300}
                            {...register('message')}
                          />
                          <label htmlFor='floatingInputCustom1'>
                            {t('GIFT_CARD_MESSAGE')}
                          </label>
                        </Form.Floating>
                      </Form.Group>
                    </Col>
                    <div className='d-flex align-items-center'>
                      <Button
                        type='submit'
                        className=' px-5 p-3 fs-6 copy_btn link   bg-white'
                        value='Gift Now'
                        title={t('GIFT_CARD_PREVIEW')}
                      >
                        {t('GIFT_CARD_PREVIEW')}
                      </Button>
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div className='fullPage_space'>
          <Footer />
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleCloseModal}
        centered
        className='verify_modal track_modal'
      >
        <Modal.Header closeButton title={t('O_CLOSE')}>
          <Modal.Title> </Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center p-5'>
          {resultMessage?.success === true ? (
            <img src={url} alt='' className='mb-4 opacity-1' />
          ) : (
            <></>
          )}

          <p className='text-center'>
            {resultMessage?.message ? resultMessage?.message : null}
          </p>
          <Link
            to='/sendGiftCard'
            className='btn btn_theme mb-2'
            title='View history'
          >
            {t('VIEW_HISTORY')}
          </Link>
        </Modal.Body>
      </Modal>

      <Modal
        show={showPreview}
        onHide={handleClose}
        centered
        className='verify_modal track_modal'
      >
        <Modal.Header closeButton title={t('O_CLOSE')}>
          <Modal.Title> {t('PREVIEW_OF_GIFT_CARD')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center p-3'>
          <div className='bday_card border p-2 rounded-3'>
            <div className='d-flex justify-content-between text-sm-start text-center track_modal-content align-items-center'>
              <div className='d-sm-flex my-sm-0 my-3 align-items-center'>
                <img src={src} alt='' className='me-2' />
                <h5 className='fs-5 mb-sm-0 mb-3'>
                  {t('GIFT_CARD')}
                  <p className='d-block text-grey text-sm-start fs-6'>
                    {t(upperCase(previewData?.title))}

                    {console.log('previewData', previewData)}
                  </p>
                </h5>
              </div>
              <h6 className='fs-5 mb-sm-0 mb-3'>
                {t('AMOUNT')}
                <p className='d-block text-grey text-sm-start fs-6'>
                  ALL {previewData?.amount?.toFixed(2)}
                </p>
              </h6>
            </div>
            {previewData?.sender_name && (
              <div className='px-sm-5 px-2 pt-3 '>
                <h5 className='mx-sm-3 mx-1 fs-5 text-sm-start text-center'>
                  {t('FROM')}
                </h5>
                <p
                  className='mx-sm-3 mx-1 fs-6 text-grey text-sm-start text-center'
                  style={{ wordWrap: 'break-word' }}
                >
                  {previewData?.sender_name}
                </p>
              </div>
            )}
            <div className='px-sm-5 px-2 pt-3 '>
              <h5 className='mx-sm-3 mx-1 fs-5 text-sm-start text-center'>
                {t('TO')}
              </h5>
              <p
                className='mx-sm-3 mx-1 fs-6 text-grey text-sm-start text-center'
                style={{ wordWrap: 'break-word' }}
              >
                {previewData?.receiver_email}
              </p>
            </div>
            {previewData?.message && (
              <div className='px-sm-5 px-2 pt-3 '>
                <h5 className='mx-sm-3 mx-1 fs-5 text-sm-start text-center'>
                  {t('MESSAGE')}
                </h5>
                <p
                  className='mx-sm-3 mx-1 fs-6 text-grey text-sm-start text-center'
                  style={{ wordWrap: 'break-word' }}
                >
                  {previewData?.message}
                </p>
              </div>
            )}
          </div>
          <MyButton />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SelectGiftCard
