import React, { useState, useEffect } from 'react'
import { Tab, Tabs, Button } from 'react-bootstrap'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import { apiGet } from '../utils/apiFetch'
import pathObj from '../utils/apiPath'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import Pagination from 'components/reusable/Pagination'
import { upperCase } from 'lodash'

function SendGiftCard () {
  const { t } = useTranslation()
  const [sentGiftCard, setSentGiftCard] = useState({})
  const [page, setPage] = useState(1)
  const [balance, setBalance] = useState(0)

  const sentGift = async () => {
    try {
      const path = `${pathObj.sentGiftCards}?page=${page}`
      const result = await apiGet(path)
      setSentGiftCard(result.data)
    } catch (error) {
      console.log('getBalance==>', error)
    }
  }

  const amount = async () => {
    const path = pathObj.getBalance
    const result = await apiGet(path)
    setBalance(result?.data?.results?.wallet_amount || 0)
  }

  useEffect(() => {
    sentGift()
  }, [page])

  useEffect(() => {
    amount()
  }, [])

  const pageCount = Math.ceil(sentGiftCard?.results?.totalPages)
  const docsLength = sentGiftCard?.results?.docs.length
  const handlePageClick = async event => {
    setPage(event + 1)
  }

  const SentGiftCardComponant = () => {
    return sentGiftCard?.results?.docs?.map((val, ind) => {
      return (
        <div className='bday_card border p-2 mt-3 rounded-3 mb-3' key={ind}>
          <div className='d-sm-flex justify-content-between text-sm-start text-center'>
            <div className='d-sm-flex my-sm-0 my-3 dps_fix'>
              <img src='./images/bday_Cake.png' alt='' className='me-2' />
              <span>
                <h6 className='fs-5 mb-sm-0 mb-3 fw-normal'>
                  {t(upperCase(val?.title))}:
                </h6>
                <span className='d-block text-grey'>
                  {' '}
                  {t('GIFT_TO')}{' '}
                  {val?.receiver_email ? ` ${val?.receiver_email}` : null}{' '}
                </span>
              </span>
            </div>
            <div className='px-sm-5 px-2 pt-0  dps_fix'>
              <h6 className='fs-5 mb-sm-0 mb-3 fw-normal'>{t('FROM')}</h6>
              <span
                className='d-block text-grey'
                style={{ wordWrap: 'break-word' }}
              >
                {val?.sender_name}
              </span>
            </div>

            <div className='px-sm-5 px-2 pt-0 dps_fix'>
              <h6 className='fs-5 mb-sm-0 mb-3 fw-normal'>
                {t('INVOICE_CREATED_DATE')}:
              </h6>
              <span className='d-block text-grey'>
                {dayjs(val?.createdAt).format('DD-MM-YYYY hh:mm A')}
              </span>
            </div>
            <div className='px-sm-5 px-2 pt-0 '>
              <h6 className='fs-5 mb-sm-0 mb-3 fw-normal me-4'>
                {t('AMOUNT')}
              </h6>
              <span className='d-block text-grey'>
                {t('O_LEK')} {val?.amount}
              </span>
            </div>
          </div>
          <div className='px-sm-5 px-2 pt-0 '>
            <h6 className='mx-sm-3 mx-1 fs-5 text-sm-start text-center'>
              {t('MESSAGE')}
            </h6>
            <p
              className='mx-sm-3 mx-1 fs-6 text-grey text-sm-start text-center'
              style={{ wordWrap: 'break-word' }}
            >
              {val?.message}{' '}
            </p>
          </div>
        </div>
      )
    })
  }

  return (
    <>
      {/* <Header /> */}

      <div className='main_wrap px-0'>
        <div className='m-0 py-4 sidebar_pageHeading fullPage_space'>
          <h5 className='mb-0 text-white'>
            <img src='./images/sidebar_icon3.svg' alt='' className='me-2' />
            {t('GIFT_CARDS')}
          </h5>
        </div>
        <div className='bg-light main_panel rounded-0 p-3 p-md-4 sendgift'>
          <div className='bg-white p-3 p-md-4 tabs_theme'>
            <div className='card_balance d-flex flex-wrap align-items-center px-3'>
              <h5 className='text-white mt-2'>
                {t('GIFT_AND_CREDIT_BALANCE')}
                <span className=''>
                  {' '}
                  {t('O_LEK')} {balance}
                </span>
              </h5>
              <Button
                type='btn'
                className='id_btn text-white bg-dark fw-bold px-3 ms-md-auto ms-0 my-md-0 my-2 border-0'
              >
                ID - XYV458722221
              </Button>
            </div>
            <Tabs
              defaultActiveKey='send_gift'
              id='uncontrolled-tab-example'
              className='mb-3 border-top mt-2 pt-4'
            >
              <Tab eventKey='send_gift' title={t('SENT_GIFT_CARDS')}>
                <div className='d-sm-flex flex-wrap align-items-center'>
                  <h4 className='mb-sm-0 mb-3'>{t('SENT_GIFT_CARDS')}</h4>
                  <Link
                    to='/AddGiftCard'
                    className='btn btn_theme ms-auto'
                    title={t('GIFT_CARD_ADD')}
                  >
                    {t('GIFT_CARD_ADD')}
                  </Link>

                  <Link
                    to='/sendGiftCard'
                    className='btn btn_theme ms-3'
                    title={t('GIFT_CARD_SEND')}
                  >
                    {t('GIFT_CARD_SEND')}
                  </Link>
                </div>

                <SentGiftCardComponant />

                <Pagination
                  pageCount={pageCount}
                  docsLength={docsLength}
                  page={page}
                  handlePageClick={handlePageClick}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
        <div className='fullPage_space'>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default SendGiftCard
